import {
  IReport,
  ITripAmount,
  ICreateTripReport,
  ITripStat,
  TripsStatsPeriod,
  ICreateReport
} from '@/types/reports.type'
import { format } from 'date-fns'
import { api } from './axios.interceptor'

class ReportsSerivce {
  async getAll() {
    const response = await api.get<IReport[]>('/reports')
    return response
  }

  async getDownloadUrl(id: number) {
    const response = await api.get<string>(`/reports/${id}/download`)
    return response
  }

  async getPreviewUrl(id: number) {
    const response = await api.get<string>(`/reports/${id}/preview`)
    return response
  }

  async getTripsAmountByDays(days: number) {
    const response = await api.get<ITripAmount[]>(
      `/reports/tripsAmountByDays/${days}`
    )
    return response
  }

  async createRidersReport(data: ICreateReport){
    const response = await api.post<IReport>(`/reports/riders`, data)
    return response
  }

  async createTripsReport(data: ICreateTripReport){
    const response = await api.post<IReport>(`/reports/trips`, data)
    return response
  }

  async createDriversReport(data: ICreateReport){
    const response = await api.post<IReport>(`/reports/drivers`, data)
    return response
  }

  async getTripsStats(timestamp: Date, amount: TripsStatsPeriod) {
    const response = await api.get<ITripStat[]>(
      `/reports/stats/trips/${amount}/${format(timestamp, "yyyy-MM-dd'T'HH:mm:ss'Z'")}`
    )
    return response
  }
}

const reportsService = new ReportsSerivce()

export default reportsService
