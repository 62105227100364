import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card'
import { formatName } from '@/lib/utils'
import { IRider } from '@/types/riders.type'
import { FC } from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { Link } from 'react-router-dom'

interface IRiderPreviewProps {
  value: IRider
}

const RiderPreview: FC<IRiderPreviewProps> = (props: IRiderPreviewProps) => {
  return (
    <HoverCard>
      <HoverCardTrigger className='w-max' asChild>
        <span>{formatName(props.value, false)}</span>
      </HoverCardTrigger>
      <HoverCardContent className='flex flex-col gap-4 min-w-max'>
        <div className='flex flex-row place-items-center gap-4'>
          <Avatar>
            <AvatarFallback>
              {props.value.firstName[0].toUpperCase()}
              {props.value.lastName[0].toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <Link to={`/riders/${props.value.id}`}>
            {formatName(props.value)}
          </Link>
          {props.value.gender && (
            <Badge className='h-max'>{String(props.value.gender)}</Badge>
          )}
        </div>
        {props.value.email && (
          <span className='text-sm text-muted-foreground'>
            Email: {props.value.email}
          </span>
        )}
        {props.value.language && (
          <span className='text-sm text-muted-foreground'>
            Language: {props.value.language}
          </span>
        )}
        {props.value.phone && (
          <span className='text-sm text-muted-foreground'>
            Phone:{' '}
            {formatPhoneNumberIntl(props.value.phone) || props.value.phone}
          </span>
        )}
        {props.value.notes && (
          <span className='text-sm text-muted-foreground'>
            Notes: {props.value.notes}
          </span>
        )}
      </HoverCardContent>
    </HoverCard>
  )
}

export default RiderPreview
