import { useToast } from '@/components/ui/use-toast'
import { formatName } from '@/lib/utils'
import tripsService from '@/services/trips.service'
import { IAPIError } from '@/types/common.type'
import { ITrip } from '@/types/trips.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUnassignTrip = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async (trip: ITrip) => {
      if (!trip.id) throw new Error('Trip not found.')
      if (!trip.shift) throw new Error('Trip is already unassigned.')

      return await tripsService.unassign(trip.id)
    },
    onSuccess: async ({ data }) => {
      toast({
        title: 'Unassigned!',
        description: `Trip #${data.id} (${data.schedules.map(schedule => formatName(schedule.rider)).join(', ')}) was successfully unassigned.`
      })

      await queryClient.invalidateQueries({ queryKey: ['trips'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
