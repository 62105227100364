import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent
} from '@/components/ui/chart'
import { Skeleton } from '@/components/ui/skeleton'
import { useSchedules } from '@/hooks/queries/useSchedules'
import { ScheduleStatus } from '@/types/trips.type'
import { endOfToday, format, startOfToday } from 'date-fns'
import { countBy } from 'lodash'
import { FC, HTMLAttributes, useMemo } from 'react'
import { TbCalendar, TbFileImport } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { Label, Pie, PieChart } from 'recharts'

const statusToColor: Record<ScheduleStatus, string> = {
  [ScheduleStatus.Unassigned]: '#737373',
  [ScheduleStatus.Assigned]: '#0d9488',
  [ScheduleStatus.Canceled]: '#ef4444',
  [ScheduleStatus.InRoute]: '#f97316',
  [ScheduleStatus.ArrivedAtPU]: '#3b82f6',
  [ScheduleStatus.NoShow]: '#27272a',
  [ScheduleStatus.InTransit]: '#eab308',
  [ScheduleStatus.ArrivedAtDO]: '#8b5cf6',
  [ScheduleStatus.Completed]: '#16a34a'
}

const TodayTrips: FC<HTMLAttributes<HTMLDivElement>> = (
  props: HTMLAttributes<HTMLDivElement>
) => {
  const navigate = useNavigate()

  const todaySchedules = useSchedules(startOfToday(), endOfToday())

  const data = useMemo(() => {
    if (todaySchedules.data) {
      const counted = countBy(todaySchedules.data, schedule => schedule.status)

      return Object.entries(counted).map(([key, value]) => ({
        status: key,
        amount: value,
        fill: statusToColor[key as ScheduleStatus]
      }))
    }
  }, [todaySchedules.data])

  return (
    <Card {...props}>
      <CardHeader>
        <CardTitle>Today Trips</CardTitle>
        <CardDescription>
          Trips statistics for {format(new Date(), 'MM.dd (EEEE)')}.
        </CardDescription>
      </CardHeader>
      <CardContent className='min-h-80 h-80'>
        {todaySchedules.isLoading || !todaySchedules.data ? (
          <div className='flex flex-col place-items-center gap-4'>
            <div className='flex flex-col justify-center place-items-center gap-2'>
              <Skeleton className='h-4 w-4' />
              <Skeleton className='h-40 w-40 rounded-full' />
              <Skeleton className='h-4 w-4' />
            </div>
            <div className='flex flex-row flex-wrap gap-2 justify-center'>
              <Skeleton className='h-4 w-20' />
              <Skeleton className='h-4 w-24' />
              <Skeleton className='h-4 w-16' />
              <Skeleton className='h-4 w-24' />
            </div>
          </div>
        ) : todaySchedules.data.length ? (
          <ChartContainer
            className='mx-auto aspect-square'
            config={{
              amount: {
                label: 'Amount'
              },
              Unassigned: {
                label: 'Unassigned'
              },
              Assigned: {
                label: 'Assigned'
              },
              Canceled: {
                label: 'Canceled'
              },
              InRoute: {
                label: 'In Route'
              },
              ArrivedAtPU: {
                label: 'Arrived (PU)'
              },
              NoShow: {
                label: 'No Show'
              },
              InTransit: {
                label: 'In Transit'
              },
              ArrivedAtDO: {
                label: 'Arrived (DO)'
              },
              Completed: {
                label: 'Completed'
              }
            }}
          >
            <PieChart className='min-h-72'>
              <Pie
                data={data}
                dataKey='amount'
                label={({ payload, ...props }) => (
                  <text {...props} fill='hsla(var(--foreground))'>
                    {JSON.stringify(payload.amount)}
                  </text>
                )}
                labelLine={false}
                nameKey='amount'
                innerRadius={60}
                strokeWidth={5}
              >
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor='middle'
                          dominantBaseline='middle'
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className='fill-foreground text-3xl font-bold'
                          >
                            {todaySchedules.data.length}
                          </tspan>
                          <tspan
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 24}
                            className='fill-muted-foreground'
                          >
                            Trips
                          </tspan>
                        </text>
                      )
                    }
                  }}
                />
              </Pie>
              <ChartTooltip
                content={<ChartTooltipContent nameKey='status' hideLabel />}
              />
              <ChartLegend
                className='translate-y-2 flex-wrap gap-2'
                content={<ChartLegendContent nameKey='status' />}
              />
            </PieChart>
          </ChartContainer>
        ) : (
          <div className='h-full flex flex-col gap-2 place-items-center justify-center text-center'>
            <TbCalendar className='h-8 w-8' />
            <h3 className='font-semibold text-lg'>No trips found</h3>
            <span className='text-sm text-muted-foreground'>
              There are no trips for today.
              <br />
              Click button below to import trips.
            </span>
            <Button className='h-8' onClick={() => navigate('/trips?import')}>
              <TbFileImport className='mr-1' />
              Import
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
export default TodayTrips
