import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import LocationInput from '@/components/ui/inputs/LocationInput'
import Spinner from '@/components/ui/Spinner'
import { Switch } from '@/components/ui/switch'
import { useTrip } from '@/hooks/queries/useTrip'
import { Mobility } from '@/types/common.type'
import {
  ISchedule,
  ISchemaSchedule,
  ISchemaTrip,
  ITrip,
  scheduleSchema,
  ScheduleStatus,
  tripSchema
} from '@/types/trips.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TbChevronRight, TbPencil } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { useCreateOrUpdateTrip } from './hooks/useCreateOrUpdateTrip'
import ScheduleForm from './ScheduleForm'

const TripForm: FC = () => {
  const tripId = Number(useParams().tripId)
  const trip = useTrip(tripId)

  const form = useForm<ISchemaTrip>({
    resolver: yupResolver(tripSchema),
    defaultValues: {
      schedules: [
        {
          status: ScheduleStatus.Unassigned,
          mobility: Mobility.Ambulatory,
          willCall: false
        }
      ]
    }
  })

  const [isRoundTrip, setIsRoundTrip] = useState<boolean>(false)
  const roundTripForm = useForm<ISchemaSchedule>({
    resolver: yupResolver(scheduleSchema),
    defaultValues: {
      willCall: true
    }
  })

  useEffect(() => form.reset(trip.data), [trip.data])

  const { mutate: submit } = useCreateOrUpdateTrip()

  const handleSubmit = (data: ISchemaTrip) =>
    submit({
      data: data as ITrip,
      roundTrip: isRoundTrip
        ? {
            schedules: [
              {
                ...(data.schedules[0] as ISchedule),
                ...roundTripForm.getValues()
              }
            ]
          }
        : undefined
    })

  return (
    <div className='flex flex-row gap-8'>
      <Form {...form}>
        {trip.isLoading ? (
          <Spinner />
        ) : (
          <form
            className='flex flex-col gap-4'
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <div className='flex flex-row gap-8'>
              {form.watch('schedules').map((_, index) => (
                <ScheduleForm
                  hideTitle={form.getValues().schedules.length <= 1}
                  created={isNaN(tripId)}
                  onDelete={() => {
                    form.setValue(
                      `schedules`,
                      form.getValues('schedules').filter((_, i) => i === index)
                    )
                  }}
                  trip={trip}
                  index={index}
                  form={form}
                  key={index}
                />
              ))}
            </div>
            <Button className='w-64 h-8' type='submit'>
              <TbPencil className='mr-1' />
              Save changes
            </Button>
          </form>
        )}
      </Form>
      <Form {...roundTripForm}>
        {isNaN(tripId) && (
          <Card className='h-max'>
            <CardHeader>
              <div className='flex flex-row gap-2 place-items-center'>
                <Switch
                  id='roundTrip'
                  onCheckedChange={value => {
                    setIsRoundTrip(value)

                    /* 
                      Set default "from" and "to" fields to form's fields. But if one of them
                      already set (e.g. user has changed these values) - don't set them again.
                    */

                    if (!roundTripForm.getValues('from'))
                      roundTripForm.setValue(
                        'from',
                        form.getValues('schedules.0.to')
                      )

                    if (!roundTripForm.getValues('to'))
                      roundTripForm.setValue(
                        'to',
                        form.getValues('schedules.0.from')
                      )
                  }}
                  checked={isRoundTrip}
                />
                <label htmlFor='roundTrip' className='text-sm'>
                  Round Trip
                </label>
              </div>
            </CardHeader>
            {isRoundTrip && (
              <CardContent>
                <div className='flex flex-col gap-4 w-full h-full'>
                  <div className='flex flex-row gap-4 place-items-center'>
                    <FormField
                      control={roundTripForm.control}
                      name='from'
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>From *</FormLabel>
                          <FormControl>
                            <LocationInput
                              fieldClassName='h-8'
                              required
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <TbChevronRight className='self-end mb-2' />
                    <FormField
                      control={roundTripForm.control}
                      name='to'
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>To *</FormLabel>
                          <FormControl>
                            <LocationInput
                              fieldClassName='h-8'
                              required
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={roundTripForm.control}
                    name='willCall'
                    render={({ field }) => (
                      <FormItem>
                        <div className='flex flex-row gap-2 place-items-center'>
                          <FormControl>
                            <Checkbox
                              required
                              checked={field.value}
                              onCheckedChange={value => {
                                if (value) {
                                  roundTripForm.setValue(
                                    'pickupTime',
                                    new Date()
                                  )
                                  roundTripForm.setValue(
                                    'dropoffTime',
                                    new Date()
                                  )
                                } else {
                                  roundTripForm.setValue(
                                    'pickupTime',
                                    new Date()
                                  )
                                  roundTripForm.setValue(
                                    'dropoffTime',
                                    new Date()
                                  )
                                }

                                field.onChange(value)
                              }}
                            />
                          </FormControl>
                          <FormLabel>Will Call?</FormLabel>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {!roundTripForm.watch('willCall') && (
                    <div className='flex flex-row gap-4 place-items-center'>
                      <FormField
                        control={roundTripForm.control}
                        name='pickupTime'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Pickup Time *</FormLabel>
                            <FormControl>
                              <DateInput
                                type='dateAndTime'
                                required
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <TbChevronRight className='self-end mb-2' />
                      <FormField
                        control={roundTripForm.control}
                        name='dropoffTime'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Appointment Time *</FormLabel>
                            <FormControl>
                              <DateInput
                                type='dateAndTime'
                                required
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  )}
                </div>
              </CardContent>
            )}
          </Card>
        )}
      </Form>
    </div>
  )
}

export default TripForm
