import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { IShift } from '@/types/shifts.type'
import { ITrip } from '@/types/trips.type'
import { UseQueryResult } from '@tanstack/react-query'
import { FC } from 'react'
import { TbAlertTriangleFilled } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import ShiftPreview from './ShiftPreview'

interface IShiftsListProps {
  shifts: UseQueryResult<IShift[]>
  trips: UseQueryResult<ITrip[]>
}

const ShiftsList: FC<IShiftsListProps> = (props: IShiftsListProps) => {
  const navigate = useNavigate()

  return (
    <Card className='w-full overflow-y-auto'>
      <CardHeader className='pb-2'>
        <CardTitle>Shifts</CardTitle>
        <CardDescription>List of today shifts</CardDescription>
      </CardHeader>
      <CardContent className='flex gap-4 flex-wrap'>
        {props.shifts.isLoading && (
          <>
            <Skeleton className='w-28 h-4' />
            <Skeleton className='w-16 h-4' />
            <Skeleton className='w-20 h-4' />
            <Skeleton className='w-16 h-4' />
          </>
        )}
        {props.shifts.data?.length ? (
          props.shifts.data.map(shift => (
            <ShiftPreview key={`shift_${shift.id}`} value={shift} />
          ))
        ) : props.trips.data?.length ? (
          <div className='flex flex-col gap-4'>
            <div className='flex flex-row place-items-center gap-2'>
              <TbAlertTriangleFilled className='min-h-6 min-w-6 text-yellow-600' />
              <span className='text-muted-foreground text-sm'>
                You have {props.trips.data.length} trips for today, but there
                are no shifts created yet.
              </span>
            </div>
          </div>
        ) : (
          <span className='text-muted-foreground text-sm'>
            You have no shifts today.
          </span>
        )}
      </CardContent>
      {props.shifts.data?.length === 0 && (
        <CardFooter>
          <Button
            className='h-8 w-full'
            onClick={() => navigate('/shifts/create')}
          >
            Create Shift
          </Button>
        </CardFooter>
      )}
    </Card>
  )
}
export default ShiftsList

