import { yupTransformer } from '@/lib/yup'
import { array, boolean, number, object, ObjectSchema, string } from 'yup'
import { addressSchema, IAddress } from './address.type'

export interface IBrokerCounty {
  ambulatoryPricing: number
  wheelchairPricing: number
  stretcherPricing: number
  ambulatoryMilageFee: number
  wheelchairMilageFee: number
  stretcherMilageFee: number
  ambulatoryFreeMiles: number
  wheelchairFreeMiles: number
  stretcherFreeMiles: number
  state: string
  county: string
}

export interface IBroker {
  id: number
  name: string
  billingAddress?: IAddress
  billingPhone?: string
  billingEmail?: string
  deletable: boolean
  pickupEarly?: number
  pickupLate?: number
  apptEarly?: number
  apptLate?: number
  requireRiderSignature: boolean
  requireDriverSignature: boolean
  overrideCompanyTimes: boolean
  counties?: IBrokerCounty[]
}

export interface ISchemaBroker extends Omit<IBroker, 'id' | 'deletable'> {}

export const brokerCountySchema: ObjectSchema<IBrokerCounty> = object({
  ambulatoryPricing: number()
    .min(0, 'Minimum is 0.')
    .typeError('Pricing is required.')
    .required('Pricing is required.'),
  wheelchairPricing: number()
    .min(0, 'Minimum is 0.')
    .typeError('Pricing is required.')
    .required('Pricing is required.'),
  stretcherPricing: number()
    .min(0, 'Minimum is 0.')
    .typeError('Pricing is required.')
    .required('Pricing is required.'),
  ambulatoryMilageFee: number()
    .min(0, 'Minimum is 0.')
    .typeError('Milage Fee is required.')
    .required('Milage Fee is required.'),
  wheelchairMilageFee: number()
    .min(0, 'Minimum is 0.')
    .typeError('Milage Fee is required.')
    .required('Milage Fee is required.'),
  stretcherMilageFee: number()
    .min(0, 'Minimum is 0.')
    .typeError('Milage Fee is required.')
    .required('Milage Fee is required.'),
  ambulatoryFreeMiles: number()
    .min(0, 'Minimum is 0.')
    .typeError('Free Miles is required.')
    .required('Free Miles is required.'),
  wheelchairFreeMiles: number()
    .min(0, 'Minimum is 0.')
    .typeError('Free Miles is required.')
    .required('Free Miles is required.'),
  stretcherFreeMiles: number()
    .min(0, 'Minimum is 0.')
    .typeError('Free Miles is required.')
    .required('Free Miles is required.'),
  state: string().required('State is required.'),
  county: string().required('County is required.')
}).transform(yupTransformer)

export const brokerSchema: ObjectSchema<ISchemaBroker> = object({
  name: string()
    .matches(
      /^[a-zA-Z0-9\s\-]+$/,
      'Name could contain only letters and numbers.'
    )
    .required('Name is required.'),
  billingAddress: addressSchema.optional(),
  billingPhone: string().optional(),
  billingEmail: string().email().optional(),
  pickupEarly: number()
    .typeError('Pickup Early is required.')
    .min(0, 'Minimum is 0 minutes.')
    .test(
      'override-requirement',
      'Pickup Early is required',
      (value, context) =>
        context.parent.overrideCompanyTimes ? value !== undefined : true
    ),
  pickupLate: number()
    .typeError('Pickup Late is required.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .test(
      'override-requirement',
      'Pickup Late is required',
      (value, context) =>
        context.parent.overrideCompanyTimes ? value !== undefined : true
    ),
  apptEarly: number()
    .typeError('Appointment Early is required.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .test(
      'override-requirement',
      'Appointment Early is required',
      (value, context) =>
        context.parent.overrideCompanyTimes ? value !== undefined : true
    ),
  apptLate: number()
    .typeError('Appointment Late is required.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .test(
      'override-requirement',
      'Appointment Late is required',
      (value, context) =>
        context.parent.overrideCompanyTimes ? value !== undefined : true
    ),
  requireRiderSignature: boolean().required(
    'Require Rider Signature is required.'
  ),
  requireDriverSignature: boolean().required(
    'Require Driver Signature is required.'
  ),
  overrideCompanyTimes: boolean().required(
    'Override Company Times is required.'
  ),
  counties: array()
    .of(brokerCountySchema)
    .min(0, 'At least one County is required.')
}).transform(yupTransformer)
