import { IImport } from '@/types/import.type'
import { createContext } from 'react'
import { UseFormReturn } from 'react-hook-form'

interface IImportContext {
  form: UseFormReturn<IImport, any, undefined>
  headers: string[]
  isAvailableForImport: boolean
}

const ImportContext = createContext<IImportContext>({} as IImportContext)

export default ImportContext
