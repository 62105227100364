import { Skeleton } from '@/components/ui/skeleton'
import { FC, useContext } from 'react'
import { TbChevronRight } from 'react-icons/tb'
import DispatchPageContext from './DispatchPageContext'
import DispatchPageAssignedScheduleTable from './tables/AssignedTripsTable'
import AssignedTripsEmpty from './tables/AssignedTripsEmpty'
import DispatchPageShiftsTable from './tables/ShiftsTable'
import DispatchPageUnassignedScheduleTable from './tables/UnassignedTripsTable'

const ColumnView: FC = () => {
  const context = useContext(DispatchPageContext)

  return (
    <div className='flex flex-col gap-4 h-full'>
      <div className='flex flex-row gap-4 w-full min-h-[33%] h-[33%] max-h-[33%]'>
        {context.shifts ? (
          <DispatchPageShiftsTable
            data={context.shifts}
            className='min-w-[30%] max-w-[30%]'
            onSelectionChange={value => context.setSelectedShift(value)}
          />
        ) : (
          <div className='flex flex-col gap-4 min-w-[30%] h-full border rounded-md p-2'>
            <Skeleton className='w-full h-16' />
            <Skeleton className='w-full h-16' />
            <Skeleton className='w-full h-16' />
          </div>
        )}
        <TbChevronRight className='self-center min-w-4' />
        {context.trips ? (
          context.selectedShift ? (
            <DispatchPageAssignedScheduleTable
              data={context.trips.filter(
                trip => trip.shiftId === context.selectedShift?.id
              )}
              className='w-[70%] overflow-x-auto'
            />
          ) : (
            <AssignedTripsEmpty />
          )
        ) : (
          <div className='flex flex-col w-full gap-4 border rounded-md p-2'>
            <Skeleton className='w-full h-24' />
            <Skeleton className='w-full h-24' />
            <Skeleton className='w-full h-24' />
          </div>
        )}
      </div>
      {context.trips ? (
        <DispatchPageUnassignedScheduleTable
          className='w-full min-h-[65%] h-[65%] max-h-[65%] pb-12'
          data={context.trips.filter(schedule => !schedule.shift)}
        />
      ) : (
        <div className='flex flex-col h-full gap-4 border rounded-md p-2'>
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
        </div>
      )}
    </div>
  )
}
export default ColumnView
