import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import EditableAvatar from '@/components/ui/EditableAvatar'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { ISchemaUser, IUser, userSchema } from '@/types/users.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil } from 'react-icons/tb'
import { useUpdateMe } from '../../hooks/useUpdateMe'

interface IPersonalTab {
  me: IUser
}

const PersonalTab: FC<IPersonalTab> = (props: IPersonalTab) => {
  const form = useForm<
    Pick<ISchemaUser, 'firstName' | 'middleName' | 'lastName'>
  >({
    resolver: yupResolver(
      userSchema.pick(['firstName', 'middleName', 'lastName'])
    )
  })

  const { mutate: submit } = useUpdateMe()

  useEffect(() => form.reset(props.me), [props.me])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(data => submit(data as IUser))}>
        <Card>
          <CardHeader>
            <CardTitle>Personal</CardTitle>
            <CardDescription>
              Make changes to your personal information here.
            </CardDescription>
            <Separator />
          </CardHeader>
          <CardContent className='flex flex-col sm:flex-row gap-8'>
            <EditableAvatar user={props.me} className='mt-4' />
            <div className='flex flex-col gap-4 w-full'>
              <FormField
                control={form.control}
                name='firstName'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First Name *</FormLabel>
                    <FormControl>
                      <Input className='max-w-80 h-8' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='middleName'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Middle Name</FormLabel>
                    <FormControl>
                      <Input className='max-w-80 h-8' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='lastName'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last Name *</FormLabel>
                    <FormControl>
                      <Input className='max-w-80 h-8' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
          <CardFooter>
            <Button className='w-64 h-8' type='submit'>
              <TbPencil className='mr-1' />
              Save changes
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}
export default PersonalTab
