import { useToast } from '@/components/ui/use-toast'
import shiftsService from '@/services/shifts.service'
import { IAPIError, ITimeRange } from '@/types/common.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteShiftBulk = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      driverId,
      range
    }: {
      driverId: number
      range: ITimeRange
    }) => {
      return await shiftsService.deleteBulk(driverId, range)
    },
    onSuccess: async ({ data }) => {
      toast({
        title: 'Deleted!',
        description: `${data} shift${data > 1 ? 's' : ''} were deleted successfully.`
      })

      queryClient.invalidateQueries({ queryKey: ['shifts'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
