import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { format } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import { TbCalendar } from 'react-icons/tb'
import { TimePicker } from './TimePicker'

interface IDateInputProps {
  title?: string
  value?: Date
  required?: boolean
  id?: string
  type?: 'date' | 'dateAndTime' | 'time'
  placeholder?: string
  className?: string
  onChange?: (value: Date) => void
  onBlur?: (value: Date) => void
}

const DateInput: FC<IDateInputProps> = (props: IDateInputProps) => {
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    if (value) props.onChange?.(value)
  }, [value])

  return (
    <div className='flex flex-col gap-2'>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Popover
        onOpenChange={open => {
          if (value && !open) props.onBlur?.(value)
        }}
      >
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn(
              'justify-start text-left font-normal place-items-center h-8 pl-2 w-64',
              !props.value && 'text-muted-foreground',
              props.className
            )}
          >
            <TbCalendar className='mr-2 h-4 w-4' />
            {props.value ? (
              format(
                props.value,
                props.type === 'dateAndTime'
                  ? 'MM.dd.yyyy HH:mm'
                  : props.type === 'time'
                    ? 'HH:mm'
                    : 'MM.dd.yyyy'
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0'>
          {props.type !== 'time' && (
            <Calendar
              mode='single'
              id={props.id}
              selected={props.value}
              onSelect={date => {
                if (date) {
                  if (props.value)
                    date.setHours(
                      props.value.getHours(),
                      props.value.getMinutes(),
                      props.value.getSeconds(),
                      props.value.getMilliseconds()
                    )
                  setValue(date)
                } else setValue(new Date())
              }}
              initialFocus
            />
          )}
          {props.type !== 'date' && (
            <TimePicker
              className='p-2 border-t justify-center'
              value={props.value}
              onChange={date => {
                if (date) setValue(date)
                else setValue(new Date())
              }}
            />
          )}
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default DateInput
