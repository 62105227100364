import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { useRequestPasswordRecovery } from '@/hooks/mutations/useRequestPasswordRecovery'
import { cn } from '@/lib/utils'
import { FC } from 'react'

export interface IPasswordRecoveryDialog {
  mail: string
  className?: string
}

const PasswordRecoveryDialog: FC<IPasswordRecoveryDialog> = (
  props: IPasswordRecoveryDialog
) => {
  const { mutate: recoverPassword } = useRequestPasswordRecovery()

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          type='button'
          variant='outline'
          className={cn('w-20 h-8', props.className)}
        >
          Recover
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Recover password</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to recover password for {props.mail}? This
            action will send a password recovery link to email.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => recoverPassword(props.mail)}>
            Send link
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default PasswordRecoveryDialog
