import { Button } from '@/components/ui/button'
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { IEmailChangeJWT } from '@/types/auth.type'
import { jwtDecode } from 'jwt-decode'
import { FC } from 'react'
import { TbPencil } from 'react-icons/tb'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useChangeEmail } from './hooks/useChangeEmail'

const tryJwtDecode = (token: string) => {
  try {
    return jwtDecode<IEmailChangeJWT>(token)
  } catch (error) {
    return null
  }
}

const EmailChangePage: FC = () => {
  const navigate = useNavigate()

  const [params] = useSearchParams()

  const token = params.get('token')
  const jwt = token && tryJwtDecode(token)

  const { mutate: changeEmail } = useChangeEmail()

  return (
    <>
      <div
        style={{
          backgroundImage: `url(wallpaper.svg)`
        }}
        className='fixed h-screen w-screen dark:invert -z-10 bg-no-repeat bg-fill bg-cover opacity-90'
      />
      <div className='flex justify-center place-items-center h-screen w-screen'>
        {jwt && jwt.exp > Math.floor(Date.now() / 1000) ? (
          <Card className='relative flex flex-col w-[500px] h-[300px] justify-center place-items-center bg-background'>
            <img src='/favicon.png' className='w-16 h-16' />
            <CardHeader className='flex flex-col justify-center place-items-center'>
              <CardTitle>Change Email</CardTitle>
              <CardDescription className='text-center'>
                Welcome back! Click button below to change your email from{' '}
                <strong>{jwt.oldEmail}</strong> to{' '}
                <strong>{jwt.newEmail}</strong>.
              </CardDescription>
            </CardHeader>
            <CardFooter className='flex flex-col jusitfy-center place-items-center'>
              <Button onClick={() => changeEmail(token)}>
                <TbPencil className='h-4 w-4 mr-1' />
                Change email
              </Button>
            </CardFooter>
          </Card>
        ) : (
          <Card className='w-[600px] h-[300px] flex flex-col justify-center place-items-center bg-background'>
            <img src='/favicon.png' className='w-16 h-16' />
            <CardHeader className='flex flex-col justify-center text-center place-items-center'>
              <CardTitle>Invalid or Expired link</CardTitle>
              <CardDescription>
                The link you used to change email is either invalid or has
                expired. Please request a new link or try again later.
              </CardDescription>
            </CardHeader>
          </Card>
        )}
      </div>
    </>
  )
}

export default EmailChangePage
