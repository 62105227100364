import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { useVehiclesSearch } from '@/hooks/queries/useVehiclesSearch'
import { cn, formatVehicle } from '@/lib/utils'
import { IVehicleSearch } from '@/types/shifts.type'
import { IVehicle } from '@/types/vehicles.type'
import { FC, useState } from 'react'
import { TbCheck, TbSearch } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import VehiclePreview from '../previews/VehiclePreview'
import Spinner from '../Spinner'

interface IVehicleInputProps {
  defaultValue?: IVehicle
  params?: IVehicleSearch
  id?: string
  title?: string
  required?: boolean
  fieldClassName?: string
  popoverClassName?: string
  onChange?: (value?: IVehicle) => any
}

const VehicleInput: FC<IVehicleInputProps> = (props: IVehicleInputProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')

  const [value, setValue] = useState<IVehicle | undefined>(props.defaultValue)
  const search = useVehiclesSearch(input, props.params)

  const handleSelect = (vehicle: IVehicle | undefined) => {
    props.onChange?.(vehicle)
    setValue(vehicle)
    setOpen(false)
  }

  return (
    <div className='flex flex-col gap-2'>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            className={cn('h-8 w-64 justify-between', props.fieldClassName)}
            aria-expanded={open}
          >
            <span className='overflow-hidden whitespace-nowrap text-ellipsis'>
              {value ? formatVehicle(value) : `Search for vehicles...`}
            </span>
            <TbSearch />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command shouldFilter={false}>
            <CommandInput
              placeholder={`Search for vehicles...`}
              value={input}
              onValueChange={setInput}
            />
            <CommandList>
              <CommandGroup>
                {search.isLoading ? (
                  <div className='flex justify-center my-2'>
                    <Spinner />
                  </div>
                ) : (
                  <CommandEmpty className='flex flex-col h-12 place-items-center justify-center text-sm'>
                    <span>No results found.</span>
                    {input && (
                      <Link to='/vehicles/create'>
                        Click to create a new vehicle.
                      </Link>
                    )}
                  </CommandEmpty>
                )}
                <CommandItem onSelect={() => handleSelect(undefined)}>
                  <span className='ml-6'>Search for vehicles...</span>
                </CommandItem>
                {search.data?.map(vehicle => (
                  <CommandItem
                    key={vehicle.id}
                    onSelect={() => handleSelect(vehicle)}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${value?.id === vehicle.id ? 'opacity-100' : 'opacity-0'}`}
                    />
                    <VehiclePreview value={vehicle} />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default VehicleInput
