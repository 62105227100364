import driversService from '@/services/drivers.service'
import { useQuery } from '@tanstack/react-query'

export const useDriver = (driverId: number) =>
  useQuery({
    enabled: !isNaN(driverId),
    retry: 1,
    queryKey: ['driver', driverId],
    queryFn: async () => await driversService.getById(driverId),
    select: response => response.data
  })
