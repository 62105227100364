import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { useAppSelector } from '@/hooks/useAppSelector'
import { formatAddress } from '@/lib/utils'
import { CellContext } from '@tanstack/react-table'
import { FC, MouseEvent } from 'react'
import { TbClipboard } from 'react-icons/tb'

const AddressesCell: FC<CellContext<any, any>> = (
  props: CellContext<any, any>
) => {
  const { toast } = useToast()
  const showClipboard = useAppSelector(
    state => state.settingsState.showClipboard
  )

  const handleCopy = async (
    event: MouseEvent<HTMLButtonElement>,
    cell: 'from' | 'to'
  ) => {
    const formattedAddress = formatAddress(props.getValue()[cell], 'normal')

    event.stopPropagation()
    await navigator.clipboard.writeText(formattedAddress).then(() =>
      toast({
        title: 'Copied!',
        description: `${formattedAddress} was copied to your clipboard.`,
        duration: 750
      })
    )
  }

  return (
    props.getValue().from &&
    props.getValue().to && (
      <div className='flex flex-col gap-1 text-xs w-60 overflow-ellipsis'>
        <div className='flex gap-2 place-items-center'>
          {window.isSecureContext && showClipboard && (
            <Button
              className='h-4 w-4'
              size='icon'
              variant='ghost'
              onClick={async event => handleCopy(event, 'from')}
            >
              <TbClipboard />
            </Button>
          )}
          <span>{formatAddress(props.getValue().from, 'normal')}</span>
        </div>
        <div className='flex gap-2 place-items-center'>
          {window.isSecureContext && true && (
            <Button
              className='h-4 w-4'
              size='icon'
              variant='ghost'
              onClick={async event => handleCopy(event, 'to')}
            >
              <TbClipboard />
            </Button>
          )}
          <span>{formatAddress(props.getValue().to, 'normal')}</span>
        </div>
      </div>
    )
  )
}

export default AddressesCell
