import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { useDeleteAdmin } from '@/hooks/mutations/useDeleteAdmin'
import { useAppSelector } from '@/hooks/useAppSelector'
import { IAdmin } from '@/types/admins.type'
import { FC, ReactNode } from 'react'

interface IAdminDeleteDialogProps {
  admin: IAdmin
  children: ReactNode
}

const AdminDeleteDialog: FC<IAdminDeleteDialogProps> = (
  props: IAdminDeleteDialogProps
) => {
  const { mutate: deleteAdmin } = useDeleteAdmin()
  const user = useAppSelector(selector => selector.userState.user)

  return (
    <AlertDialog>
      <AlertDialogTrigger
        onSelect={e => e.preventDefault()}
        disabled={props.admin.userId === user?.id}
        asChild
      >
        {props.children}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete this
            admin.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => deleteAdmin(props.admin)}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default AdminDeleteDialog
