import { toast } from '@/components/ui/use-toast'
import shiftsService from '@/services/shifts.service'
import { IAPIError } from '@/types/common.type'
import { ISchemaMultipleShifts } from '@/types/shifts.type'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useCreateMultipleShifts = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (shifts: ISchemaMultipleShifts) => {
      toast({
        title: 'Creating...',
        description: `Creating recurring shifts...`
      })

      return await shiftsService.createMultiple(shifts)
    },
    onSuccess: async ({ data }) => {
      navigate(-1)

      toast({
        title: 'Saved!',
        description: `Recurring shifts were created successfully.`
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive',
        duration: 5000
      })
    }
  })
}

