import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { IPasswordRecoveryJWT } from '@/types/auth.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { jwtDecode } from 'jwt-decode'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { TbRefresh } from 'react-icons/tb'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { object, string } from 'yup'
import { useRecoverPassword } from './hooks/useRecoverPassword'

const tryJwtDecode = (token: string) => {
  try {
    return jwtDecode<IPasswordRecoveryJWT>(token)
  } catch (error) {
    return null
  }
}

const PasswordResetPage: FC = () => {
  const navigate = useNavigate()

  const [params] = useSearchParams()

  const token = params.get('token')
  const jwt = token && tryJwtDecode(token)

  const { mutate: recoverPassword } = useRecoverPassword()

  const form = useForm<{
    password: string
    passwordRepeat: string
  }>({
    resolver: yupResolver(
      object({
        password: string()
          .required('Password is required.')
          .min(8, 'Minimum 8 characters.')
          .max(64, 'Maximum 64 characters.')
          .test(
            'test-equality',
            'Passwords must match.',
            (value, context) => context.parent.passwordRepeat === value
          ),
        passwordRepeat: string()
          .required('Password is required.')
          .min(8, 'Minimum 8 characters.')
          .max(64, 'Maximum 64 characters.')
          .test(
            'test-equality',
            'Passwords must match.',
            (value, context) => context.parent.password === value
          )
      })
    )
  })

  return (
    <Form {...form}>
      <div
        style={{
          backgroundImage: `url(wallpaper.svg)`
        }}
        className='fixed h-screen w-screen dark:invert -z-10 bg-no-repeat bg-fill bg-cover opacity-90'
      />
      <div className='flex justify-center place-items-center h-screen w-screen'>
        {jwt && jwt.exp > Math.floor(Date.now() / 1000) ? (
          <form
            onSubmit={form.handleSubmit(data =>
              recoverPassword({
                token: token,
                password: data.password
              })
            )}
          >
            <Card className='relative flex flex-col w-[600px] h-[500px] justify-center place-items-center bg-background'>
              <img src='/favicon.png' className='w-16 h-16' />
              <CardHeader className='flex flex-col justify-center place-items-center'>
                <CardTitle>Recover password</CardTitle>
                <CardDescription className='text-center'>
                  Enter a new password for {jwt.email}. If you haven't requested
                  a password recovery link, don't do anything.
                </CardDescription>
              </CardHeader>
              <CardContent className='flex flex-col gap-4 justify-center place-items-center'>
                <FormField
                  control={form.control}
                  name='password'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          autoComplete='new-password'
                          type='password'
                          className='w-80 h-10'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='passwordRepeat'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Confirm password</FormLabel>
                      <FormControl>
                        <Input
                          autoComplete='new-password'
                          type='password'
                          className='w-80 h-10'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
              <CardFooter className='flex flex-col jusitfy-center place-items-center'>
                <Button className='w-80 h-10'>
                  <TbRefresh className='h-4 w-4 mr-1' />
                  Reset password
                </Button>
              </CardFooter>
            </Card>
          </form>
        ) : (
          <Card className='w-[600px] h-[300px] flex flex-col justify-center place-items-center bg-background'>
            <img src='/favicon.png' className='w-16 h-16' />
            <CardHeader className='flex flex-col justify-center text-center place-items-center'>
              <CardTitle>Invalid or Expired link</CardTitle>
              <CardDescription>
                The link you used to reset your password is either invalid or
                has expired. Please request a new password reset link or try
                again later.
              </CardDescription>
            </CardHeader>
            <CardContent className='flex justify-center plcae-items-center'>
              <Button onClick={() => navigate('/login')}>
                Back to the login page
              </Button>
            </CardContent>
          </Card>
        )}
      </div>
    </Form>
  )
}

export default PasswordResetPage
