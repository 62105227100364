import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card'
import { Input } from '@/components/ui/input'
import LocationInput from '@/components/ui/inputs/LocationInput'
import TextInput from '@/components/ui/inputs/TextInput'
import Spinner from '@/components/ui/Spinner'
import { Switch } from '@/components/ui/switch'
import { useBroker } from '@/hooks/queries/useBroker'
import { useCompany } from '@/hooks/queries/useCompany'
import { IAddress } from '@/types/address.type'
import {
  brokerSchema,
  IBroker,
  IBrokerCounty,
  ISchemaBroker
} from '@/types/brokers.type'
import { AutocompleteType } from '@/types/geo.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil, TbPlus, TbTrash } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateOrUpdateBroker } from './hooks/useCreateOrUpdateBroker'
import { PhoneInput } from '@/components/ui/phone-input'

const defaultCounty: Partial<IBrokerCounty> = {
  ambulatoryPricing: 0,
  wheelchairPricing: 0,
  stretcherPricing: 0,
  ambulatoryMilageFee: 0,
  wheelchairMilageFee: 0,
  stretcherMilageFee: 0,
  stretcherFreeMiles: 0,
  wheelchairFreeMiles: 0,
  ambulatoryFreeMiles: 0
}

const BrokerForm: FC = () => {
  const brokerId = Number(useParams().brokerId)

  const navigate = useNavigate()
  const company = useCompany()

  const form = useForm<ISchemaBroker>({
    resolver: yupResolver(brokerSchema),
    defaultValues: {
      counties: [],
      requireRiderSignature: false,
      requireDriverSignature: false,
      overrideCompanyTimes: false
    }
  })

  const broker = useBroker(brokerId)
  useEffect(() => form.reset(broker.data), [broker.data])

  const { mutate: submit } = useCreateOrUpdateBroker()

  const handleCountyDelete = (index: number) => {
    form.setValue(
      'counties',
      (form.getValues('counties') || []).filter((_, i) => i !== index)
    )
  }

  const handleCountyCreate = () => {
    form.setValue('counties', [
      ...(form.getValues('counties') || []),
      defaultCounty as IBrokerCounty
    ])
  }

  return (
    <Form {...form}>
      {broker.isLoading ? (
        <Spinner />
      ) : (
        <form
          className='flex flex-col gap-4'
          onSubmit={form.handleSubmit(data => submit(data as IBroker))}
        >
          <FormField
            control={form.control}
            name='name'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name *</FormLabel>
                <FormControl>
                  <Input className='w-96 h-8' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='requireRiderSignature'
            render={({ field }) => (
              <FormItem className='flex flex-row place-items-center border w-max p-4 pt-2 rounded-lg gap-4'>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div>
                  <FormLabel>Requires Rider Signature</FormLabel>
                  <FormDescription>
                    Rider's signature will be required when driver picks up a
                    person.
                  </FormDescription>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='requireDriverSignature'
            render={({ field }) => (
              <FormItem className='flex flex-row place-items-center border w-max p-4 pt-2 rounded-lg gap-4'>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div>
                  <FormLabel>Requires Driver Signature</FormLabel>
                  <FormDescription>
                    Driver's signature will be required when driver picks up a
                    person.
                  </FormDescription>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <Card className='w-max'>
            <CardHeader>
              <CardTitle>Billing</CardTitle>
              <CardDescription>
                Edit billing information for this broker.
              </CardDescription>
            </CardHeader>
            <CardContent className='flex flex-col gap-2'>
              <FormField
                control={form.control}
                name='billingPhone'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone *</FormLabel>
                    <FormControl>
                      <PhoneInput
                        defaultCountry='US'
                        className='w-96 h-8'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='billingEmail'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email *</FormLabel>
                    <FormControl>
                      <Input
                        placeholder='inbox@example.com '
                        className='w-96 h-8'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='billingAddress'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Address *</FormLabel>
                    <FormControl>
                      <LocationInput fieldClassName='w-96 h-8' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>

          <Card className='w-max'>
            <CardHeader>
              <CardTitle>Times</CardTitle>
              <CardDescription>
                Time adjustments for autoscheduler. Check "Override Company
                Times"
                <br />
                to override parameters that are set in{' '}
                <HoverCard>
                  <HoverCardTrigger className='underline font-semibold cursor-pointer'>
                    company settings
                  </HoverCardTrigger>
                  <HoverCardContent className='w-full'>
                    <span className='text-lg font-medium'>
                      Company settings
                      <Button
                        variant='outline'
                        size='icon'
                        title='Change company time settings.'
                        className='w-6 h-6 ml-2'
                        onClick={() => navigate('/settings?tab=autoscheduler')}
                      >
                        <TbPencil />
                      </Button>
                    </span>
                    <div className='flex flex-col gap-4 mt-4'>
                      <div className='flex flex-row gap-4'>
                        <TextInput
                          title='Pickup Early (in minutes)'
                          className='w-60'
                          disabled
                          value={company.data?.settings.pickupEarly}
                        />
                        <TextInput
                          title='Pickup Late (in minutes)'
                          className='w-60'
                          disabled
                          value={company.data?.settings.pickupLate}
                        />
                      </div>
                      <div className='flex flex-row gap-4'>
                        <TextInput
                          title='Appointment Early (in minutes)'
                          className='w-60'
                          disabled
                          value={company.data?.settings.apptEarly}
                        />
                        <TextInput
                          title='Appointment Late (in minutes)'
                          className='w-60'
                          disabled
                          value={company.data?.settings.apptLate}
                        />
                      </div>
                    </div>
                  </HoverCardContent>
                </HoverCard>
                .
              </CardDescription>
            </CardHeader>
            <CardContent className='flex flex-col gap-4'>
              <FormField
                control={form.control}
                name='overrideCompanyTimes'
                render={({ field }) => (
                  <FormItem className='flex flex-row place-items-center border w-full p-4 pt-2 rounded-lg gap-4'>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div>
                      <FormLabel>Override Company Times</FormLabel>
                      <FormDescription>
                        Time parameters will be overwritten due autoscheduling.
                      </FormDescription>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <div className='flex flex-row gap-4'>
                <FormField
                  control={form.control}
                  name='pickupEarly'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Pickup Early (in minutes)</FormLabel>
                      <FormControl>
                        <Input
                          min={0}
                          max={60}
                          type='nubmer'
                          disabled={!form.watch('overrideCompanyTimes')}
                          className='w-60 h-8'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='pickupLate'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Pickup Late (in minutes)</FormLabel>
                      <FormControl>
                        <Input
                          min={0}
                          max={60}
                          type='nubmer'
                          disabled={!form.watch('overrideCompanyTimes')}
                          className='w-60 h-8'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className='flex flex-row gap-4'>
                <FormField
                  control={form.control}
                  name='apptEarly'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Appointment Early (in minutes)</FormLabel>
                      <FormControl>
                        <Input
                          min={0}
                          max={60}
                          type='nubmer'
                          disabled={!form.watch('overrideCompanyTimes')}
                          className='w-60 h-8'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='apptLate'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Appointment Late (in minutes)</FormLabel>
                      <FormControl>
                        <Input
                          min={0}
                          max={60}
                          type='nubmer'
                          disabled={!form.watch('overrideCompanyTimes')}
                          className='w-60 h-8'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </CardContent>
          </Card>
          <Card className='w-[650px]'>
            <CardHeader>
              <CardTitle className='flex flex-row place-items-end gap-2'>
                Counties
                <Button
                  size='icon'
                  variant='outline'
                  className='w-6 h-6'
                  type='button'
                  onClick={handleCountyCreate}
                >
                  <TbPlus />
                </Button>
              </CardTitle>
              {form.getFieldState('counties').error && (
                <FormMessage>
                  There are problem with some county. Please, check your input
                  and try again.
                </FormMessage>
              )}
              <CardDescription>
                Manage counties, prices and fees for this broker.
              </CardDescription>
            </CardHeader>
            <CardContent>
              {form.watch('counties')?.length ? (
                <Accordion type='multiple' className='w-full'>
                  {form.getValues('counties')?.map((county, index) => (
                    <AccordionItem key={index} value={String(index)}>
                      <AccordionTrigger>
                        <div className='flex flex-row gap-2'>
                          <span>
                            County #{index + 1}
                            {county.county && ` (${county.county})`}
                          </span>
                          <AlertDialog>
                            <AlertDialogTrigger
                              onClick={event => event.stopPropagation()}
                              asChild
                            >
                              <Button
                                className='h-6 w-6'
                                type='button'
                                size='icon'
                                variant='outline'
                              >
                                <TbTrash />
                              </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Are you absolutely sure?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  This action cannot be undone. This will delete
                                  County #{index + 1}
                                  {county.county && ` (${county.county})`}.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel
                                  onClick={event => event.stopPropagation()}
                                >
                                  Cancel
                                </AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={event => {
                                    event.stopPropagation()
                                    handleCountyDelete(index)
                                  }}
                                >
                                  Continue
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className='flex flex-col gap-4 w-max pl-2'>
                        <div className='flex flex-row gap-4'>
                          <FormField
                            control={form.control}
                            name={`counties.${index}.ambulatoryPricing`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Ambulatory Pricing</FormLabel>
                                <FormControl>
                                  <Input
                                    className='w-40 h-8'
                                    type='number'
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`counties.${index}.wheelchairPricing`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Wheelchair Pricing</FormLabel>
                                <FormControl>
                                  <Input
                                    className='w-40 h-8'
                                    type='number'
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`counties.${index}.stretcherPricing`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Stretcher Pricing</FormLabel>
                                <FormControl>
                                  <Input
                                    className='w-40 h-8'
                                    type='number'
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                        <div className='flex flex-row gap-4'>
                          <FormField
                            control={form.control}
                            name={`counties.${index}.ambulatoryMilageFee`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Ambulatory Milage Fee</FormLabel>
                                <FormControl>
                                  <Input
                                    className='w-40 h-8'
                                    type='number'
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`counties.${index}.wheelchairMilageFee`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Wheelchair Milage Fee</FormLabel>
                                <FormControl>
                                  <Input
                                    className='w-40 h-8'
                                    type='number'
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`counties.${index}.stretcherMilageFee`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Stretcher Milage Fee</FormLabel>
                                <FormControl>
                                  <Input
                                    className='w-40 h-8'
                                    type='number'
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                        <div className='flex flex-row gap-4'>
                          <FormField
                            control={form.control}
                            name={`counties.${index}.ambulatoryFreeMiles`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Ambulatory Free Miles</FormLabel>
                                <FormControl>
                                  <Input
                                    className='w-40 h-8'
                                    type='number'
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`counties.${index}.wheelchairFreeMiles`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Wheelchair Free Miles</FormLabel>
                                <FormControl>
                                  <Input
                                    className='w-40 h-8'
                                    type='number'
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`counties.${index}.stretcherFreeMiles`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Stretcher Free Miles</FormLabel>
                                <FormControl>
                                  <Input
                                    className='w-40 h-8'
                                    type='number'
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                        <FormField
                          control={form.control}
                          name={`counties.${index}.county`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>County</FormLabel>
                              <FormControl>
                                <LocationInput
                                  type={AutocompleteType.COUNTY}
                                  format='county'
                                  fieldClassName='w-full h-8'
                                  {...field}
                                  value={
                                    form.watch(
                                      `counties.${index}`
                                    ) as unknown as IAddress
                                  }
                                  onChange={value => {
                                    form.setValue(`counties.${index}`, {
                                      ...form.getValues(`counties.${index}`),
                                      county: value.county,
                                      state: value.state
                                    })
                                  }}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              ) : (
                <span className='text-sm text-muted-foreground'>
                  There are no counties added yet.
                </span>
              )}
            </CardContent>
          </Card>
          <Button className='w-64 h-8' type='submit'>
            <TbPencil className='mr-1' />
            Save changes
          </Button>
        </form>
      )}
    </Form>
  )
}

export default BrokerForm
