import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { useAppDispatch } from '@/hooks/useAppDispatch'
import { useAppSelector } from '@/hooks/useAppSelector'
import {
  setDispatchAutoRefresh,
  setEnableContextMenu,
  setShowClipboard,
  setTheme,
  Theme
} from '@/reducers/settingsSlice'
import { FC } from 'react'
import { TbAlertTriangleFilled, TbClipboard } from 'react-icons/tb'
import SettingField from './SettingField'

const AppearanceTab: FC = () => {
  const dispatch = useAppDispatch()

  const settings = useAppSelector(state => state.settingsState)

  return (
    <div className='flex flex-col gap-4'>
      <Card>
        <CardHeader>
          <CardTitle>Theme</CardTitle>
          <CardDescription>
            Choose your favourite theme for dashboard.
          </CardDescription>
          <Separator />
        </CardHeader>
        <CardContent className='overflow-x-auto'>
          <RadioGroup
            onValueChange={value => {
              dispatch(setTheme(Theme[value as keyof typeof Theme]))
            }}
            className='flex flex-row flex-wrap'
          >
            <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
              <Card className='w-max h-max dark:invert'>
                <RadioGroupItem
                  value='Light'
                  className='sr-only'
                  data-state={settings.theme === Theme.Light && 'checked'}
                />
                <CardHeader>
                  <CardTitle>Light</CardTitle>
                  <CardDescription>Best for light days.</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className='flex flex-col gap-2 '>
                    <Skeleton className='h-12 w-12 rounded-full animate-none' />
                    <Skeleton className='h-4 w-[250px] animate-none' />
                    <Skeleton className='h-4 w-[200px] animate-none' />
                  </div>
                </CardContent>
              </Card>
            </label>
            <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
              <Card className='w-max h-max dark'>
                <RadioGroupItem
                  value='Dark'
                  className='sr-only'
                  data-state={settings.theme === Theme.Dark && 'checked'}
                />
                <CardHeader>
                  <CardTitle>Dark</CardTitle>
                  <CardDescription>Best for dark nights.</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className='flex flex-col gap-2'>
                    <Skeleton className='h-12 w-12 rounded-full dark animate-none' />
                    <Skeleton className='h-4 w-[250px] dark animate-none' />
                    <Skeleton className='h-4 w-[200px] dark animate-none' />
                  </div>
                </CardContent>
              </Card>
            </label>
            <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
              <Card className='w-max h-max dark'>
                <RadioGroupItem
                  value='System'
                  className='sr-only'
                  data-state={settings.theme === Theme.System && 'checked'}
                />
                <CardHeader>
                  <CardTitle>System</CardTitle>
                  <CardDescription>
                    Adjusts to your current system theme.
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className='flex flex-col gap-2'>
                    <Skeleton className='h-12 w-12 rounded-full invert animate-none' />
                    <Skeleton className='h-4 w-[250px] invert animate-none' />
                    <Skeleton className='h-4 w-[200px] invert animate-none' />
                  </div>
                </CardContent>
              </Card>
            </label>
          </RadioGroup>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Miscellaneous</CardTitle>
          <CardDescription>Change additional settings.</CardDescription>
          <Separator />
        </CardHeader>
        <CardContent className='flex flex-col gap-4'>
          <SettingField
            checked={settings.showClipboard}
            icon={<TbClipboard />}
            onCheckedChange={checked => dispatch(setShowClipboard(checked))}
            title='Address Copy'
            description='Display a clipboard icon next to addresses so you can quickly copy them.'
          />
          <SettingField
            checked={settings.dispatchAutoRefresh}
            icon={
              <Tooltip>
                <TooltipTrigger>
                  <TbAlertTriangleFilled
                    className='text-orange-300/80'
                    title='This feature is experimental.'
                  />
                </TooltipTrigger>
                <TooltipContent>
                  This feature is Experimental. Enabling this can cause some
                  user experience issues.
                </TooltipContent>
              </Tooltip>
            }
            onCheckedChange={checked =>
              dispatch(setDispatchAutoRefresh(checked))
            }
            title='Dispatch Page Auto Refresh'
            description='Automatically refresh information on dispatch page every 30 seconds.'
          />
          <SettingField
            checked={settings.enableContextMenu}
            onCheckedChange={checked => dispatch(setEnableContextMenu(checked))}
            title='Context Menu'
            description='Enable context menu for tables with a right-click.'
          />
        </CardContent>
      </Card>
    </div>
  )
}
export default AppearanceTab
