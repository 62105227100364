import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { FC } from 'react'
import { Link } from 'react-router-dom'

export interface IErrorPageProps {
  code: number
  text: string
  buttonHref?: string
  buttonText?: string
}

const ErrorPage: FC<IErrorPageProps> = (props: IErrorPageProps) => {
  return (
    <div className='flex flex-col gap-16 justify-center place-items-center h-screen w-screen font-thin'>
      <div className='flex flex-row justify-center place-items-center gap-8'>
        <h1 className='text-9xl'>{props.code}</h1>
        <Separator orientation='vertical' />
        <h2 className='text-7xl'>{props.text}</h2>
      </div>

      {props.buttonHref && props.buttonText && (
        <Button>
          <Link
            className='neutral-200 rounded-xl px-4 py-2 font-semibold'
            to={props.buttonHref}
          >
            {props.buttonText}
          </Link>
        </Button>
      )}

      <div className='flex flex-row gap-2 place-items-center fixed bottom-4'>
        <img className='w-6 h-6' src='/favicon.png' />
        <h6 className='font-light'>ManageNEMT</h6>
      </div>
    </div>
  )
}

export default ErrorPage
