import { yupTransformer } from '@/lib/yup'
import { array, boolean, date, number, object, ObjectSchema } from 'yup'
import { ITimeRange, timeRangeSchema } from './common.type'
import { IDriver } from './drivers.type'
import { IVehicle } from './vehicles.type'

export interface IShift {
  id: number
  date: Date
  driver: IDriver
  driverId: number
  vehicle: IVehicle
  vehicleId: number
  companyId: number
  started: boolean
  breaks: ITimeRange[]
  workingTime: ITimeRange
}

export interface IVehicleSearch {
  notUsedForDay?: Date
  notUsedForDayOfWeek?: number
}

export interface ISchemaShift
  extends Omit<IShift, 'id' | 'driver' | 'vehicle' | 'companyId' | 'started'> {}

export const shiftSchema: ObjectSchema<ISchemaShift> = object({
  date: date().required('Date is required.'),
  driverId: number().required('Driver is required.'),
  vehicleId: number().required('Vehicle is required.'),
  breaks: array()
    .of(timeRangeSchema)
    .max(5, 'Maximum 5 breaks allowed.')
    .required('Breaks are required.'),
  workingTime: timeRangeSchema.required('Working time is required.')
}).transform(yupTransformer)

interface ISchemaMultipleShiftsDay
  extends Omit<ISchemaShift, 'driverId' | 'vehicleId' | 'date'> {
  enabled?: boolean
}

const multipleShiftsDaySchema: ObjectSchema<ISchemaMultipleShiftsDay> = object({
  enabled: boolean(),
  breaks: array()
    .of(timeRangeSchema)
    .max(5, 'Maximum 5 breaks allowed.')
    .required('Breaks are required.'),
  workingTime: timeRangeSchema.required('Working time is required.')
}).transform(yupTransformer)

export interface ISchemaMultipleShifts {
  range: ITimeRange
  driverId: number
  vehicleId: number
  sunday?: ISchemaMultipleShiftsDay
  monday?: ISchemaMultipleShiftsDay
  tuesday?: ISchemaMultipleShiftsDay
  wednesday?: ISchemaMultipleShiftsDay
  thursday?: ISchemaMultipleShiftsDay
  friday?: ISchemaMultipleShiftsDay
  saturday?: ISchemaMultipleShiftsDay
}

export const createMultipleShiftsSchema: ObjectSchema<ISchemaMultipleShifts> =
  object({
    range: timeRangeSchema.required('Range is required.'),
    driverId: number().required('Driver is required.'),
    vehicleId: number().required('Vehicle is required.'),
    sunday: multipleShiftsDaySchema,
    monday: multipleShiftsDaySchema,
    tuesday: multipleShiftsDaySchema,
    wednesday: multipleShiftsDaySchema,
    thursday: multipleShiftsDaySchema,
    friday: multipleShiftsDaySchema,
    saturday: multipleShiftsDaySchema
  }).transform(yupTransformer)
