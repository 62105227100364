import { IAddress } from '@/types/address.type'
import {
  IDriver,
  IDriverGeolocation,
  IDriverSearch
} from '@/types/drivers.type'
import { format } from 'date-fns'
import { api } from './axios.interceptor'

class DriversService {
  async getById(id: number) {
    const response = await api.get<IDriver>(`/drivers/${id}`)

    return response
  }

  async getAll() {
    const response = await api.get<IDriver[]>('/drivers')

    return response
  }

  async getGeolocationHistory(driverId: number) {
    const response = await api.get<IDriverGeolocation[]>(
      `/drivers/${driverId}/geolocation`
    )

    return response
  }

  async getLatestGeolocations() {
    const response = await api.get<
      {
        driver: IDriver
        location: IAddress
        timestamp: Date
      }[]
    >(`/drivers/geolocation/latest`)

    return response
  }

  async getWorkingToday() {
    const response = await api.get<IDriver[]>(
      `/drivers/workingToday/${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'")}`
    )

    return response
  }

  async search(input: string, params?: IDriverSearch) {
    const response = await api.get<IDriver[]>(`/drivers/search/${input}`, {
      params: params
    })

    return response
  }

  async create(request: IDriver) {
    const response = await api.post<IDriver>('/drivers', request)

    return response
  }

  async update(id: number, request: Partial<IDriver>) {
    const response = await api.patch<IDriver>(`/drivers/${id}`, request)

    return response
  }
}

const driversService = new DriversService()

export default driversService
