import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { useCreatePreset } from '@/hooks/mutations/useCreatePreset'
import { IImportPreset } from '@/types/import.type'
import { FC } from 'react'
import { useDeletePreset } from '../../hooks/useDeletePreset'
import { useRenamePreset } from '../../hooks/useRenamePreset'
import DeleteDialog from './DeleteDialog'
import DuplicateDialog from './DuplicateDialog'
import RenameDialog from './RenameDialog'

interface IPresetsTable {
  name: string
  data: IImportPreset
}

const PresetsTable: FC<ICustomizableTableProps<IPresetsTable>> = (
  props: ICustomizableTableProps<IPresetsTable>
) => {
  const { mutate: renamePreset } = useRenamePreset()
  const { mutate: deletePreset } = useDeletePreset()
  const { mutate: createPreset } = useCreatePreset()

  return (
    <Table
      {...props}
      className='w-[1000px] max-w-full'
      emptyElement='No presets found.'
      disablePagination
      columns={[
        {
          header: 'Name',
          accessorKey: 'name',
          accessorFn: data => data,
          size: 900,
                              cell: props => (
            <div>
              <span>{props.getValue().name}</span>{' '}
              {props.getValue().data.isDefault && (
                <span className='text-muted-foreground'>(Default)</span>
              )}
            </div>
          )
        },
        {
          header: 'Actions',
          accessorKey: 'actions',
          accessorFn: data => data,
          enableSorting: false,
          size: 200,
                              cell: props => (
            <div className='flex flex-row gap-2'>
              <RenameDialog
                disabled={props.getValue().data.isDefault}
                initialName={props.getValue().name}
                onAccept={name =>
                  renamePreset({
                    name: props.getValue().name,
                    updatedName: name
                  })
                }
              />
              <DeleteDialog
                disabled={props.getValue().data.isDefault}
                onAccept={() => deletePreset(props.getValue().name)}
              />
              <DuplicateDialog
                onAccept={() =>
                  createPreset({
                    name: `${props.getValue().name} (Copy)`,
                    data: props.getValue().data
                  })
                }
              />
            </div>
          )
        }
      ]}
    />
  )
}
export default PresetsTable
