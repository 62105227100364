import { useToast } from '@/components/ui/use-toast'
import { formatName } from '@/lib/utils'
import tripService from '@/services/trips.service'
import { IAPIError } from '@/types/common.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUncombineTrip = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async (id: number) => {
      return await tripService.uncombine(id)
    },
    onSuccess: async ({ data }) => {
      toast({
        title: `Assigned!`,
        description: `Trip (${data.map(trip => formatName(trip.schedules[0].rider)).join(', ')}) was uncombined to ${data.length} trips.`
      })

      await queryClient.invalidateQueries({ queryKey: ['trips'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}

