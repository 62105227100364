import { IAddress } from '@/types/address.type'
import { AddressSearchRequest } from '@/types/geo.type'
import { api } from './axios.interceptor'

class GeoService {
  async search(input: string, params: AddressSearchRequest) {
    const response = await api.get<string[]>(`/geo/search/${input}`, {
      params: params
    })
    return response
  }

  async geocode(address: string) {
    const response = await api.get<IAddress>(`/geo/geocode/${address}`)
    return response
  }
}

const geoService = new GeoService()

export default geoService
