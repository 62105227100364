import { yupTransformer } from '@/lib/yup'
import { number, object, ObjectSchema, string } from 'yup'

export interface IGeocode {
  lat: number
  lng: number
}

export interface IAddress {
  address: string
  city: string
  county: string
  state: string
  zipCode: number
  latitude: number
  longitude: number
}

export interface ICounty {
  county: string
  state: string
  zipCode: number
}

const generalAddressSchema: ObjectSchema<IAddress> = object({
  address: string().required(),
  city: string().required(),
  county: string().required(),
  state: string().required(),
  zipCode: number().required(),
  latitude: number().required(),
  longitude: number().required()
}).transform(yupTransformer)

export const addressSchema = generalAddressSchema.default(undefined)
