import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import Spinner from '@/components/ui/Spinner'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { useShifts } from '@/hooks/queries/useShifts'
import { useTrips } from '@/hooks/queries/useTrips'
import { useAppSelector } from '@/hooks/useAppSelector'
import { ITimeRange } from '@/types/common.type'
import { IShift } from '@/types/shifts.type'
import {
  endOfDay,
  endOfToday,
  format,
  startOfDay,
  startOfToday
} from 'date-fns'
import { FC, useState } from 'react'
import {
  TbCalendarPlus,
  TbFileImport,
  TbLayout,
  TbLayoutColumns,
  TbWand
} from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import CalendarView from './CalendarView'
import ColumnView from './ColumnView'
import DispatchPageContext from './DispatchPageContext'
import { useAutoscheduleMutation } from './hooks/useAutoscheduleMutation'

enum View {
  Column = 'column',
  Calendar = 'calendar'
}

const DispatchPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const autoRefresh = useAppSelector(
    state => state.settingsState.dispatchAutoRefresh
  )

  const [searchRange, setSearchRange] = useState<ITimeRange>({
    start: location.state?.timestamp
      ? startOfDay(new Date(location.state.timestamp))
      : startOfToday(),
    end: location.state?.timestamp
      ? endOfDay(new Date(location.state.timestamp))
      : endOfToday()
  })
  const [selectedShift, setSelectedShift] = useState<IShift>()
  const [selectedView, setSelectedView] = useState<View>(
    location.state?.view ?? View.Column
  )

  const autoschedule = useAutoscheduleMutation()

  const shifts = useShifts(searchRange)
  const trips = useTrips(
    searchRange.start,
    searchRange.end,
    autoRefresh ? 30000 : undefined
  )

  const handleViewChange = (view: View) => {
    navigate('.', {
      state: {
        ...history.state,
        view: view
      },
      replace: true
    })
    setSelectedView(view)
    setSelectedShift(undefined)
  }

  return (
    <div className='flex flex-col gap-4 w-full h-full overflow-hidden'>
      <AlertDialog open={autoschedule.isPending}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className='flex flex-row gap-2 place-items-center'>
              <Spinner className='h-4 w-4' />
              Autoscheduling...
            </AlertDialogTitle>
            <AlertDialogDescription>
              We are currently auto-scheduling your trips for{' '}
              <b>{format(searchRange.start, 'MM.dd.yyyy')}</b>.
              <br />
              <br />
              Please wait for the process to complete, this may take a few
              moments. Thank you for your patience and understanding!
            </AlertDialogDescription>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>
      <DispatchPageContext.Provider
        value={{
          date: searchRange.start,
          selectedShift: selectedShift,
          setSelectedShift: setSelectedShift,
          shifts: shifts.data,
          trips: trips.data
        }}
      >
        <div className='flex flex-row gap-2 place-items-end h-8 justify-between'>
          <div className='flex flex-row gap-2'>
            <DateInput
              type='date'
              value={searchRange.start}
              onChange={value => {
                setSearchRange({
                  start: startOfDay(value),
                  end: endOfDay(value)
                })
                navigate('.', {
                  state: {
                    ...location.state,
                    timestamp: value
                  },
                  replace: true
                })
              }}
            />
            <div className='flex flex-row w-max'>
              <Button
                className='w-8 h-8 rounded-r-none'
                variant={selectedView === View.Column ? 'secondary' : 'outline'}
                onClick={() => handleViewChange(View.Column)}
                size='icon'
              >
                <TbLayout className='rotate-[90deg] scale-y-[-1]' />
              </Button>
              <Button
                className='w-8 h-8 rounded-l-none'
                variant={
                  selectedView === View.Calendar ? 'secondary' : 'outline'
                }
                onClick={() => handleViewChange(View.Calendar)}
                size='icon'
              >
                <TbLayoutColumns />
              </Button>
            </div>
          </div>
          <div className='flex flex-row gap-2'>
            <Tooltip>
              <TooltipContent>Create new Trip</TooltipContent>
              <TooltipTrigger asChild>
                <Button
                  size='icon'
                  className='h-8 w-8'
                  onClick={() => navigate('/trips/create')}
                >
                  <TbCalendarPlus />
                </Button>
              </TooltipTrigger>
            </Tooltip>
            <Dialog>
              <Tooltip>
                <TooltipTrigger asChild>
                  <DialogTrigger asChild>
                    <Button size='icon' className='h-8 w-8'>
                      <TbWand />
                    </Button>
                  </DialogTrigger>
                </TooltipTrigger>
                <TooltipContent>Autoschedule</TooltipContent>
              </Tooltip>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle className='flex flex-row gap-2 mb-1 place-items-center'>
                    <TbWand />
                    Autoscheduling
                  </DialogTitle>
                  <DialogDescription>
                    Are you sure?
                    <br />
                    <br />
                    This action cannot be undone. This will automatically
                    multiload trips, reassign trips for that day and assign
                    drivers.
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant='outline'>Cancel</Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button
                      onClick={() => autoschedule.mutate(searchRange.start)}
                    >
                      Continue
                    </Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size='icon'
                  className='h-8 w-8'
                  onClick={() => navigate('/trips?import')}
                >
                  <TbFileImport />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Import</TooltipContent>
            </Tooltip>
          </div>
        </div>
        {selectedView === View.Column && <ColumnView />}
        {selectedView === View.Calendar && <CalendarView />}
      </DispatchPageContext.Provider>
    </div>
  )
}

export default DispatchPage
