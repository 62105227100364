import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum Theme {
  Dark = 'dark',
  Light = 'light',
  System = 'system'
}

const initialState = {
  theme: Theme.System,
  showClipboard: true,
  enableContextMenu: true,
  dispatchAutoRefresh: false
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload
    },
    setShowClipboard: (state, action: PayloadAction<boolean>) => {
      state.showClipboard = action.payload
    },
    setEnableContextMenu: (state, action: PayloadAction<boolean>) => {
      state.enableContextMenu = action.payload
    },
    setDispatchAutoRefresh: (state, action: PayloadAction<boolean>) => {
      state.dispatchAutoRefresh = action.payload
    }
  }
})

export const {
  setTheme,
  setShowClipboard,
  setEnableContextMenu,
  setDispatchAutoRefresh
} = settingsSlice.actions
export default settingsSlice.reducer
