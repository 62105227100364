import EditableAvatar from '@/components/ui/EditableAvatar'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import MailChangeDialog from '@/components/ui/MailChangeDialog'
import PasswordRecoveryDialog from '@/components/ui/PasswordRecoveryDialog'
import { PhoneInput } from '@/components/ui/phone-input'
import { IDriver, ISchemaDriver } from '@/types/drivers.type'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

interface IAccountTabProps {
  driver?: IDriver
  form: UseFormReturn<ISchemaDriver, any, undefined>
}

const AccountTab: FC<IAccountTabProps> = (props: IAccountTabProps) => {
  return (
    <div className='flex flex-col gap-4'>
      {props.driver?.user && <EditableAvatar user={props.driver.user} />}
      <div className='flex flex-row gap-4'>
        <FormField
          control={props.form.control}
          name='user.firstName'
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name *</FormLabel>
              <FormControl>
                <Input placeholder='John' className='w-48 h-8' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={props.form.control}
          name='user.middleName'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Middle Name</FormLabel>
              <FormControl>
                <Input className='w-48 h-8' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={props.form.control}
          name='user.lastName'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name *</FormLabel>
              <FormControl>
                <Input placeholder='Doe' className='w-48 h-8' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <FormField
        control={props.form.control}
        name='user.username'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Username *</FormLabel>
            <FormControl>
              <Input placeholder='johndoe' className='w-64 h-8' {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='user.email'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Email *</FormLabel>
            <FormControl>
              <div className='flex flex-row gap-2 max-w-64'>
                <Input
                  disabled={!!props.driver}
                  className='w-full h-8'
                  {...field}
                />
                {props.driver && <MailChangeDialog id={props.driver.id} />}
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='user.phone'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Phone Number</FormLabel>
            <FormControl>
              <PhoneInput className='w-64' defaultCountry='US' {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='user.password'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Password</FormLabel>
            <FormControl>
              <div className='flex flex-row max-w-64 gap-2'>
                <Input
                  className='w-full h-8'
                  disabled
                  type='password'
                  defaultValue={props.driver && '*************'}
                  {...field}
                />
                {props.driver && (
                  <PasswordRecoveryDialog mail={props.driver.user.email} />
                )}
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
export default AccountTab
