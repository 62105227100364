import { Button } from '@/components/ui/button'
import Spinner from '@/components/ui/Spinner'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { FC, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { TbMapCancel, TbMaximize, TbMinimize } from 'react-icons/tb'
import { useLatestGeolocation } from './hooks/useLatestGeolocation'
import LiveMap from './LiveMap'
import LiveMapContext from './LiveMapContext'
import LiveMapTable from './LiveMapTable'

const LiveMapPage: FC = () => {
  const latestGeolocation = useLatestGeolocation()
  const fullScreenHandle = useFullScreenHandle()

  const [hiddenDrivers, setHiddenDrivers] = useState<number[]>([])

  return latestGeolocation.data ? (
    <LiveMapContext.Provider
      value={{
        latestGeolocations: latestGeolocation.data,
        hiddenDrivers,
        setHiddenDrivers
      }}
    >
      <div className='flex flex-row gap-2 w-full h-full'>
        {latestGeolocation.data.length ? (
          <>
            <FullScreen className='w-2/3 relative' handle={fullScreenHandle}>
              <LiveMap className='w-full h-full' />
              <Tooltip>
                <TooltipTrigger className='absolute top-2 right-2'>
                  <Button
                    className='h-8 w-8'
                    size='icon'
                    variant='outline'
                    onClick={
                      fullScreenHandle.active
                        ? fullScreenHandle.exit
                        : fullScreenHandle.enter
                    }
                  >
                    {fullScreenHandle.active ? <TbMinimize /> : <TbMaximize />}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  {fullScreenHandle.active
                    ? 'Exit fullscreen'
                    : 'Enter fullscreen'}
                </TooltipContent>
              </Tooltip>
            </FullScreen>
            <LiveMapTable className='w-1/3' />
          </>
        ) : (
          <div className='flex flex-col gap-2 justify-center place-items-center w-full h-full border border-dashed rounded-md'>
            <TbMapCancel className='h-8 w-8' />
            <h3 className='font-semibold text-lg'>
              No geolocation data found.
            </h3>
            <span className='text-sm text-muted-foreground'>
              There are no available driver locations to show.
            </span>
          </div>
        )}
      </div>
    </LiveMapContext.Provider>
  ) : (
    <Spinner />
  )
}
export default LiveMapPage

