import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import { Switch } from '@/components/ui/switch'
import { ISchemaMultipleShifts } from '@/types/shifts.type'
import { startOfDay } from 'date-fns'
import { capitalize } from 'lodash'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { TbChevronRight, TbPlus, TbTrash } from 'react-icons/tb'

interface IShiftMultipleFormDayProps {
  day:
    | 'sunday'
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
  form: UseFormReturn<ISchemaMultipleShifts, any, undefined>
}

const ShiftMultipleFormDay: FC<IShiftMultipleFormDayProps> = ({
  day,
  form
}: IShiftMultipleFormDayProps) => {
  return (
    <div className='flex flex-row gap-4 border rounded-md p-4'>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-row gap-2 place-items-center'>
          <h2 className='font-semibold text-xl'>{capitalize(day)}</h2>
          <Switch
            checked={form.watch(`${day}.enabled`)}
            onCheckedChange={checked =>
              form.setValue(`${day}.enabled`, checked)
            }
          />
        </div>
        <div className='flex flex-row gap-2'>
          <FormField
            control={form.control}
            name={`${day}.workingTime.start`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Start Time *</FormLabel>
                <FormControl>
                  <DateInput
                    {...field}
                    type='time'
                    className='w-36 h-8'
                    onBlur={value => {
                      if (value > form.getValues(`${day}.workingTime.end`))
                        form.setValue(`${day}.workingTime.end`, value)
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <TbChevronRight className='place-self-center mt-8' />
          <FormField
            control={form.control}
            name={`${day}.workingTime.end`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Start End *</FormLabel>
                <FormControl>
                  <DateInput
                    {...field}
                    type='time'
                    onBlur={value => {
                      if (value < form.getValues(`${day}.workingTime.start`))
                        form.setValue(`${day}.workingTime.start`, value)
                    }}
                    className='w-36 h-8'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
      <div className='flex flex-col w-full gap-2'>
        <div className='flex flex-row gap-2 place-items-start'>
          <h2 className='font-semibold'>Breaks</h2>
          <Button
            size='icon'
            variant='outline'
            type='button'
            disabled={form.getValues(`${day}.breaks`)?.length >= 5}
            onClick={() => {
              const breaks = form.getValues(`${day}.breaks`)

              const time =
                breaks?.length > 0
                  ? breaks[breaks?.length - 1].end
                  : startOfDay(new Date())

              form.setValue(`${day}.breaks`, [
                ...(breaks || []),
                {
                  start: time,
                  end: time
                }
              ])
            }}
            className='h-6 w-6'
          >
            <TbPlus />
          </Button>
        </div>
        {form.watch(`${day}.breaks`)?.length ? (
          <div className='flex flex-row w-full flex-wrap gap-2'>
            {form.watch(`${day}.breaks`).map((_, index) => (
              <div key={index} className='flex flex-row gap-2 place-items-end'>
                <FormField
                  control={form.control}
                  name={`${day}.breaks.${index}.start`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <DateInput
                          {...field}
                          type='time'
                          onBlur={value => {
                            if (
                              value >
                              form.getValues(`${day}.breaks.${index}.end`)
                            )
                              form.setValue(`${day}.breaks.${index}.end`, value)
                          }}
                          className='w-32 h-8'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={`${day}.breaks.${index}.end`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <DateInput
                          {...field}
                          type='time'
                          onBlur={value => {
                            if (
                              value <
                              form.getValues(`${day}.breaks.${index}.start`)
                            )
                              form.setValue(
                                `${day}.breaks.${index}.start`,
                                value
                              )
                          }}
                          className='w-32 h-8'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  size='icon'
                  variant='outline'
                  type='button'
                  className='h-8 w-8'
                  onClick={() => {
                    form.setValue(
                      `${day}.breaks`,
                      form
                        .getValues(`${day}.breaks`)
                        .filter((_, i) => index !== i)
                    )
                  }}
                >
                  <TbTrash />
                </Button>
              </div>
            ))}
          </div>
        ) : <span className='text-sm text-muted-foreground'>No breaks created.</span>}
      </div>
    </div>
  )
}
export default ShiftMultipleFormDay

