import { useAppSelector } from '@/hooks/useAppSelector'
import { Theme } from '@/reducers/settingsSlice'
import { FC, ReactNode, useEffect } from 'react'

export interface IThemeProviderProps {
  children?: ReactNode
}

export const ThemeProvider: FC<IThemeProviderProps> = ({
  children
}: IThemeProviderProps) => {
  const { theme } = useAppSelector(state => state.settingsState)

  useEffect(() => {
    const root = window.document.documentElement

    root.classList.remove('light', 'dark')

    if (theme === Theme.System) {
      root.classList.add(
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
      )
      return
    }

    root.classList.add(String(theme))
  }, [theme])

  return <>{children}</>
}
