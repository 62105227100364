import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import Spinner from '@/components/ui/Spinner'
import { useBrokers } from '@/hooks/queries/useBrokers'
import { FC, useState } from 'react'
import { TbPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import BrokersTable from './BrokersTable'

const BrokersPage: FC = () => {
  const brokers = useBrokers()
  const navigate = useNavigate()

  const [nameFilter, setNameFilter] = useState<string>('')

  return (
    <div className='flex flex-col gap-4 w-full h-full'>
      <div className='flex flex-row gap-2 relative'>
        <Input
          value={nameFilter}
          onChange={e => setNameFilter(e.target.value)}
          placeholder='Filter name...'
          className='w-40 h-8'
        />
        <Button
          onClick={() => {
            navigate(`/brokers/create`)
          }}
          className='absolute h-8 right-0'
        >
          <TbPlus className='h-4 w-4 mr-2' />
          Create
        </Button>
      </div>
      {brokers.data && !brokers.isLoading ? (
        <BrokersTable
          data={brokers.data}
          columnFilters={[{ id: 'name', value: nameFilter }]}
        />
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default BrokersPage
