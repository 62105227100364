import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import DriverInput from '@/components/ui/inputs/DriverInput'
import VehicleInput from '@/components/ui/inputs/VehicleInput'
import {
  createMultipleShiftsSchema,
  ISchemaMultipleShifts
} from '@/types/shifts.type'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  addMonths,
  differenceInMonths,
  differenceInWeeks,
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfWeek,
  subMonths
} from 'date-fns'
import { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil } from 'react-icons/tb'
import { useCreateMultipleShifts } from './hooks/useCreateMultipleShifts'
import ShiftMultipleFormDay from './ShiftMultipleFormDay'

const ShiftMultipleForm: FC = () => {
  const form = useForm<ISchemaMultipleShifts>({
    resolver: yupResolver(createMultipleShiftsSchema),
    defaultValues: {
      range: {
        start: startOfWeek(new Date()),
        end: endOfWeek(new Date())
      },
      sunday: {
        breaks: [],
        enabled: false,
        workingTime: {
          start: startOfDay(new Date()),
          end: endOfDay(new Date())
        }
      },
      monday: {
        breaks: [],
        enabled: false,
        workingTime: {
          start: startOfDay(new Date()),
          end: endOfDay(new Date())
        }
      },
      tuesday: {
        breaks: [],
        enabled: false,
        workingTime: {
          start: startOfDay(new Date()),
          end: endOfDay(new Date())
        }
      },
      wednesday: {
        breaks: [],
        enabled: false,
        workingTime: {
          start: startOfDay(new Date()),
          end: endOfDay(new Date())
        }
      },
      thursday: {
        breaks: [],
        enabled: false,
        workingTime: {
          start: startOfDay(new Date()),
          end: endOfDay(new Date())
        }
      },
      friday: {
        breaks: [],
        enabled: false,
        workingTime: {
          start: startOfDay(new Date()),
          end: endOfDay(new Date())
        }
      },
      saturday: {
        breaks: [],
        enabled: false,
        workingTime: {
          start: startOfDay(new Date()),
          end: endOfDay(new Date())
        }
      }
    }
  })

  const { mutate: submit } = useCreateMultipleShifts()

  const enabledDays = useMemo(
    () => [
      ...(form.watch('sunday')?.enabled ? ['sunday'] : []),
      ...(form.watch('monday')?.enabled ? ['monday'] : []),
      ...(form.watch('tuesday')?.enabled ? ['tuesday'] : []),
      ...(form.watch('wednesday')?.enabled ? ['wednesday'] : []),
      ...(form.watch('thursday')?.enabled ? ['thursday'] : []),
      ...(form.watch('friday')?.enabled ? ['friday'] : []),
      ...(form.watch('saturday')?.enabled ? ['saturday'] : [])
    ],
    [form.watch()]
  )

  const rangeDifference = useMemo(
    () =>
      Math.abs(
        differenceInWeeks(
          form.getValues('range.end'),
          form.getValues('range.start')
        )
      ),
    [form.getValues()]
  )

  const handleSubmit = (data: ISchemaMultipleShifts) => {
    if (!data['sunday']?.enabled) delete data['sunday']
    if (!data['monday']?.enabled) delete data['monday']
    if (!data['tuesday']?.enabled) delete data['tuesday']
    if (!data['wednesday']?.enabled) delete data['wednesday']
    if (!data['thursday']?.enabled) delete data['thursday']
    if (!data['friday']?.enabled) delete data['friday']
    if (!data['saturday']?.enabled) delete data['saturday']

    submit(data)
  }

  return (
    <Form {...form}>
      <form
        className='flex flex-col gap-4'
        onSubmit={form.handleSubmit(handleSubmit, console.error)}
      >
        <div className='flex flex-row gap-4'>
          <Card>
            <CardHeader className='h-16'>
              <CardTitle>Information</CardTitle>
            </CardHeader>
            <CardContent className='flex flex-col gap-2'>
              <FormField
                control={form.control}
                name='driverId'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Driver *</FormLabel>
                    <FormControl>
                      <DriverInput
                        {...field}
                        fieldClassName='w-64 h-8'
                        onChange={driver => field.onChange(driver?.id)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='vehicleId'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Vehicle *</FormLabel>
                    <FormControl>
                      <VehicleInput
                        {...field}
                        fieldClassName='w-64 h-8'
                        onChange={vehicle => field.onChange(vehicle?.id)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>
          <Card className='w-96'>
            <CardHeader>
              <CardTitle>Creation Range</CardTitle>
              <CardDescription>
                Set the time range for creating these shifts. Maximum is 3
                months.
              </CardDescription>
            </CardHeader>
            <CardContent className='flex flex-col gap-2'>
              <div className='flex flex-row gap-4'>
                <FormField
                  control={form.control}
                  name='range.start'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Start *</FormLabel>
                      <FormControl>
                        <DateInput
                          {...field}
                          type='date'
                          className='w-36 h-8'
                          onBlur={value => {
                            if (value > form.getValues(`range.end`))
                              return form.setValue(`range.end`, value)

                            const difference = Math.abs(
                              differenceInMonths(
                                value,
                                form.getValues(`range.end`)
                              )
                            )

                            if (difference >= 3)
                              form.setValue('range.end', addMonths(value, 3))

                            field.onChange(value)
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='range.end'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>End *</FormLabel>
                      <FormControl>
                        <DateInput
                          {...field}
                          type='date'
                          onBlur={value => {
                            if (value < form.getValues(`range.start`))
                              form.setValue(`range.start`, value)

                            const difference = Math.abs(
                              differenceInMonths(
                                value,
                                form.getValues(`range.start`)
                              )
                            )

                            if (difference >= 3)
                              form.setValue('range.start', subMonths(value, 3))

                            field.onChange(value)
                          }}
                          className='w-36 h-8'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <span className='text-muted-foreground text-sm'>
                ({rangeDifference + 1} week{rangeDifference > 1 && 's'})
              </span>
            </CardContent>
          </Card>
        </div>
        <Card className='w-[1350px]'>
          <CardHeader>
            <CardTitle>Days of Week</CardTitle>
            <CardDescription>
              Set breaks and working time for each day of the week.
            </CardDescription>
          </CardHeader>
          <CardContent className='flex flex-col gap-4'>
            <ShiftMultipleFormDay form={form} day='sunday' />
            <ShiftMultipleFormDay form={form} day='monday' />
            <ShiftMultipleFormDay form={form} day='tuesday' />
            <ShiftMultipleFormDay form={form} day='wednesday' />
            <ShiftMultipleFormDay form={form} day='thursday' />
            <ShiftMultipleFormDay form={form} day='friday' />
            <ShiftMultipleFormDay form={form} day='saturday' />
          </CardContent>
        </Card>
        <Button
          disabled={!enabledDays.length}
          className='w-64 h-8'
          type='submit'
        >
          <TbPencil className='mr-1' />
          Save changes
        </Button>
        {/*  <AlertDialog>
          <AlertDialogTrigger asChild>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action will create shifts for all{' '}
                {enabledDays.map(day => `${capitalize(day)}s`).join(', ')} from{' '}
                <strong>
                  {format(form.getValues('range.start'), 'MM.dd.yyyy')}
                </strong>{' '}
                to{' '}
                <strong>
                  {format(form.getValues('range.end'), 'MM.dd.yyyy')}
                </strong>{' '}
                ({rangeDifference + 1} week{rangeDifference > 1 && 's'}).
                <br />
                <br />
                You can edit or delete these shifts later.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel type='button'>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={() => form.trigger()}>Continue</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog> */}
      </form>
    </Form>
  )
}
export default ShiftMultipleForm

