import Spinner from '@/components/ui/Spinner'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useMe } from '@/hooks/queries/useMe'
import { TabsContent } from '@radix-ui/react-tabs'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import AccountTab from './tabs/accountTab/AccountTab'
import AppearanceTab from './tabs/appearanceTab/AppearanceTab'
import PersonalTab from './tabs/personalTab/PersonalTab'

enum Tab {
  Account = 'account',
  Personal = 'personal',
  Appearance = 'appearance'
}

const MeSettingsPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    tab: Tab.Account
  })

  const me = useMe()

  return (
    <div className='flex flex-col gap-4 w-full h-full'>
      <Tabs
        defaultValue={searchParams.get('tab') || Tab.Account}
        className='flex flex-col gap-4'
      >
        <TabsList className='flex flex-row w-full justify-start'>
          <TabsTrigger
            value={Tab.Account}
            onClick={() => setSearchParams({ tab: Tab.Account })}
          >
            Account
          </TabsTrigger>
          <TabsTrigger
            value={Tab.Personal}
            onClick={() => setSearchParams({ tab: Tab.Personal })}
          >
            Personal
          </TabsTrigger>
          <TabsTrigger
            value={Tab.Appearance}
            onClick={() => setSearchParams({ tab: Tab.Appearance })}
          >
            Appearance
          </TabsTrigger>
        </TabsList>
        <TabsContent value={Tab.Account}>
          {me.data ? <AccountTab me={me.data} /> : <Spinner />}
        </TabsContent>
        <TabsContent value={Tab.Personal}>
          {me.data ? <PersonalTab me={me.data} /> : <Spinner />}
        </TabsContent>
        <TabsContent value={Tab.Appearance}>
          <AppearanceTab />
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default MeSettingsPage
