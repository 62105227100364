import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import EnumInput from '@/components/ui/inputs/EnumInput'
import LocationInput from '@/components/ui/inputs/LocationInput'
import Spinner from '@/components/ui/Spinner'
import { useVehicle } from '@/hooks/queries/useVehicle'
import { Mobility } from '@/types/common.type'
import { IVehicle, vehicleSchema } from '@/types/vehicles.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { useCreateOrUpdateVehicle } from './hooks/useCreateOrUpdateVehicle'

const VehicleForm: FC = () => {
  const vehicleId = Number(useParams().vehicleId)

  const vehicle = useVehicle(vehicleId)

  const form = useForm<Omit<IVehicle, 'id'>>({
    resolver: yupResolver(vehicleSchema),
    defaultValues: {
      vehicleType: Mobility.Ambulatory,
      mobilityCapacity: 0
    }
  })

  useEffect(() => form.reset(vehicle.data), [vehicle.data])

  const { mutate: submit } = useCreateOrUpdateVehicle()

  return (
    <Form {...form}>
      {vehicle.isLoading ? (
        <Spinner />
      ) : (
        <form
          className='flex flex-col gap-4'
          onSubmit={form.handleSubmit(data => submit(data as IVehicle))}
        >
          <div className='flex flex-row gap-4 w-max'>
            <FormField
              control={form.control}
              name='make'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Make *</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='model'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Model *</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name *</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='year'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Year *</FormLabel>
                  <FormControl>
                    <Input type='number' className='w-32 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='flex flex-row gap-4'>
            <FormField
              control={form.control}
              name='vehicleType'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Vehicle Type *</FormLabel>
                  <FormControl>
                    <EnumInput
                      enum={Mobility}
                      fieldClassName='w-64 h-8'
                      required
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {form.watch('vehicleType') !== Mobility.Ambulatory && (
              <FormField
                control={form.control}
                name='mobilityCapacity'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {form.watch('vehicleType')} Capacity *
                    </FormLabel>
                    <FormControl>
                      <Input className='w-64 h-8' type='number' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name='ambulatoryCapacity'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ambulatory Capacity *</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' type='number' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className='flex flex-row gap-4'>
            <FormField
              control={form.control}
              name='vinNumber'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>VIN Number</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='KMHCM36C58U048324'
                      className='w-64 h-8'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='licensePlateNumber'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>License Plate Number</FormLabel>
                  <FormControl>
                    <Input className='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='garagingSite'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Location *</FormLabel>
                  <FormControl>
                    <LocationInput fieldClassName='w-64 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button className='w-64 h-8' type='submit'>
            <TbPencil className='mr-1' />
            Save changes
          </Button>
        </form>
      )}
    </Form>
  )
}

export default VehicleForm
