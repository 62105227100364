import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import SideBar from './components/layout/sideBar/SideBar'
import SideBarContext from './components/layout/sideBar/SideBarContext'
import TopBar from './components/layout/topBar/TopBar'
import ErrorPage from './pages/ErrorPage'

const Layout = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const isAuthorized = !!window.localStorage.getItem('accessToken')
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthorized) navigate('/login')
  })

  return isAuthorized ? (
    <SideBarContext.Provider value={{ isCollapsed, setIsCollapsed }}>
      <div className='flex flex-row h-screen w-screen overflow-hidden gap-0'>
        <SideBar />
        <div className='flex flex-col gap-4 h-full w-full overflow-y-scroll lg:p-6 p-3 pb-6'>
          <TopBar />
          <Outlet />
        </div>
      </div>
    </SideBarContext.Provider>
  ) : (
    <ErrorPage
      code={401}
      text='Unauthorized.'
      buttonHref='/login'
      buttonText='Login'
    />
  )
}

export default Layout
