import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import FileInput from '@/components/ui/inputs/FileInput'
import Spinner from '@/components/ui/Spinner'
import { useUpdateAvatar } from '@/hooks/mutations/useUpdateAvatar'
import { cn } from '@/lib/utils'
import { IUser } from '@/types/users.type'
import { FC, useState } from 'react'
import { TbPencil, TbPhoto } from 'react-icons/tb'
import UserAvatar from './UserAvatar'

interface IAvatarDialogProps {
  user: IUser
  className?: string
}

const EditableAvatar: FC<IAvatarDialogProps> = (props: IAvatarDialogProps) => {
  const [file, setFile] = useState<File>()
  const [open, setOpen] = useState<boolean>(false)

  const updateAvatarMutation = useUpdateAvatar()

  const submit = () => {
    if (!file) return

    updateAvatarMutation.mutate(
      {
        file: file,
        user: props.user
      },
      {
        onSuccess: () => {
          setOpen(false)
        }
      }
    )
  }

  return (
    <div className={cn('relative w-max h-max', props.className)}>
      {!updateAvatarMutation.isPending && (
        <UserAvatar
          user={props.user}
          title={`${props.user.username}'s profile picture`}
          className='h-24 w-24 text-4xl'
        />
      )}
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button
            variant='outline'
            className='absolute -right-1 -top-1 h-8 w-8 p-2 rounded-full'
            size='icon'
            type='button'
            title='Update profile picture'
          >
            <TbPencil />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upload profile picture</DialogTitle>
            <DialogDescription>
              Select a new image below and click "Upload" to update your picture
              profile.
            </DialogDescription>
          </DialogHeader>
          <FileInput
            accept='.png'
            required
            onChange={event => {
              if (event.target.files?.[0]) setFile(event.target.files[0])
            }}
          />
          <DialogFooter>
            <Button
              disabled={!file || updateAvatarMutation.isPending}
              onClick={submit}
              type='button'
            >
              Upload
              {updateAvatarMutation.isPending ? (
                <Spinner className='ml-2 h-4 w-4' />
              ) : (
                <TbPhoto className='ml-2 h-4 w-4' />
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default EditableAvatar
