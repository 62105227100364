import { Input } from '@/components/ui/input'
import Spinner from '@/components/ui/Spinner'
import { useAdmins } from '@/hooks/queries/useAdmins'
import { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import AdminInviteDialog from './AdminInviteDialog'
import AdminsTable from './AdminsTable'

const AdminsPage: FC = () => {
  const [searchParams] = useSearchParams()
  const admins = useAdmins()

  const [usernameFilter, setUsernameFilter] = useState<string>('')

  return (
    <div className='flex flex-col gap-4 w-full h-full'>
      <div className='flex flex-row gap-2 justify-between'>
        <Input
          value={usernameFilter}
          onChange={e => setUsernameFilter(e.target.value)}
          placeholder='Filter username...'
          className='w-40 h-8'
        />
        <AdminInviteDialog defaultOpen={searchParams.get('invite') !== null} />
      </div>
      {admins.data && !admins.isLoading ? (
        <AdminsTable
          columnFilters={[{ id: 'username', value: usernameFilter }]}
          data={admins.data}
        />
      ) : (
        <Spinner />
      )}
    </div>
  )
}
export default AdminsPage
