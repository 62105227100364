import { useToast } from '@/components/ui/use-toast'
import reportsService from '@/services/reports.service'
import { IAPIError } from '@/types/common.type'
import { ICreateTripReport } from '@/types/reports.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreateTripsReport = () => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: ICreateTripReport) => {
      return await reportsService.createTripsReport(data)
    },
    onSuccess: async () => {
      toast({
        title: 'Processing...',
        description: `Your request is being processed. Once completed, the report will be ready for download.`
      })

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['reports'] })
      }, 1000)
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}

