import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { useContext } from 'react'
import { IconType } from 'react-icons'
import { Link, useLocation } from 'react-router-dom'
import SideBarContext from './SideBarContext'

interface ISideBarButton {
  icon: IconType
  href: string
  title: string
}

const SideBarButton = (props: ISideBarButton) => {
  const context = useContext(SideBarContext)

  const location = useLocation()

  return (
    <Button variant='ghost' size='sm' className='justify-start p-2' asChild>
      <Link
        to={props.href}
        className={cn(
          'flex gap-3 place-items-center justify-start',
          location.pathname === props.href && 'bg-accent',
          context.isCollapsed && 'justify-center'
        )}
      >
        <props.icon className='min-h-6 min-w-6' />
        <h5 className={cn('text-md', context.isCollapsed && '!hidden')}>
          {props.title}
        </h5>
      </Link>
    </Button>
  )
}

export default SideBarButton
