import { Switch } from '@/components/ui/switch'
import { FC, ReactNode } from 'react'

interface ISettingFieldProps {
  checked: boolean
  icon?: ReactNode
  onCheckedChange: (checked: boolean) => void
  title: ReactNode
  description: ReactNode
}

const SettingField: FC<ISettingFieldProps> = (props: ISettingFieldProps) => {
  return (
    <div className='flex flex-row place-items-center border p-4 rounded-lg gap-4'>
      <Switch checked={props.checked} onCheckedChange={props.onCheckedChange} />
      <div>
        <div className='flex gap-1 place-items-center'>
          {props.icon && props.icon}
          <h3 className='text-sm font-medium'>{props.title}</h3>
        </div>
        <p className='text-sm text-muted-foreground flex flex-row gap-1 place-items-center flex-wrap'>
          {props.description}
        </p>
      </div>
    </div>
  )
}
export default SettingField
