import usersService from '@/services/users.service'
import { useQuery } from '@tanstack/react-query'

export const useAvatar = (id: number, enabled?: boolean) =>
  useQuery({
    queryKey: ['avatar', id],
    enabled: enabled,
    queryFn: async () => await usersService.getAvatarURL(id),
    select: response => response.data,
    retry: 1,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })
