import { Theme } from '@/reducers/settingsSlice'
import { useAppSelector } from './useAppSelector'

export const useTheme = () => {
  const theme = useAppSelector(state => state.settingsState.theme)

  return theme === Theme.System
    ? window.matchMedia('(prefers-color-scheme: dark)').matches
      ? Theme.Dark
      : Theme.Light
    : theme
}
