import { yupTransformer } from '@/lib/yup'
import { array, mixed, number, object, ObjectSchema } from 'yup'

export enum AutoschedulerOptimization {
  Distance = 'distance',
  Duration = 'duration',
  VehicleCount = 'vehicleCount'
}

export interface ICompanySettings {
  apptLate?: number
  apptEarly?: number
  pickupLate?: number
  pickupEarly?: number
  ambulatoryPickupServiceTime?: number
  ambulatoryApptServiceTime?: number
  wheelchairPickupServiceTime?: number
  wheelchairApptServiceTime?: number
  stretcherApptServiceTime?: number
  stretcherPickupServiceTime?: number
  liftApptServiceTime?: number
  liftPickupServiceTime?: number
  autoschedulerOptimizations?: AutoschedulerOptimization[]
  importPickupAdjustment?: number
  importDropoffAdjustment?: number
}

export interface ICompany {
  id: number
  name: string
  settings: ICompanySettings
}

export interface ISchemaCompany extends Omit<ICompany, 'id' | 'name'> {}

export const companySettingsSchema: ObjectSchema<ICompanySettings> = object({
  apptLate: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  apptEarly: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  pickupLate: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  pickupEarly: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  ambulatoryPickupServiceTime: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  ambulatoryApptServiceTime: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  wheelchairPickupServiceTime: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  wheelchairApptServiceTime: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  stretcherApptServiceTime: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  stretcherPickupServiceTime: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  liftPickupServiceTime: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  liftApptServiceTime: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(60, 'Maximum is 60 minutes.')
    .required('Required field.'),
  autoschedulerOptimizations: array()
    .of(
      mixed<AutoschedulerOptimization>().required('Optimization is required.')
    )
    .required('Optimizations are required.'),
  importPickupAdjustment: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(300, 'Maximum is 300 minutes.')
    .required('Required field.'),
  importDropoffAdjustment: number()
    .typeError('Required field.')
    .min(0, 'Minimum is 0 minutes.')
    .max(300, 'Maximum is 300 minutes.')
    .required('Required field.')
}).transform(yupTransformer)

export const companySchema: ObjectSchema<ISchemaCompany> = object({
  settings: companySettingsSchema
}).transform(yupTransformer)
