import ScheduleStatusBadge from '@/components/tables/TripsTable/ScheduleStatusBadge'
import BigCalendar from '@/components/ui/big-calendar/big-calendar'
import TripPreview from '@/components/ui/previews/TripPreview'
import { Skeleton } from '@/components/ui/skeleton'
import { calendarLocalizer } from '@/lib/calendarLocalizer'
import { formatName } from '@/lib/utils'
import { FC, useContext, useMemo } from 'react'
import { Views } from 'react-big-calendar'
import DispatchPageContext from './DispatchPageContext'
import AssignedTripsEmpty from './tables/AssignedTripsEmpty'
import DispatchPageShiftsTable from './tables/ShiftsTable'

const CalendarView: FC = () => {
  const context = useContext(DispatchPageContext)

  const calendarEvents = useMemo(
    () =>
      context.trips
        ?.filter(trip => trip.shiftId === context.selectedShift?.id)
        .map((trip, index) => {
          const start = Math.min(
            ...trip.schedules.map(schedule =>
              new Date(
                schedule.scheduledPickupTime ?? schedule.pickupTime
              ).getTime()
            )
          )
          const end = Math.max(
            ...trip.schedules.map(schedule =>
              new Date(
                schedule.scheduledDropoffTime ?? schedule.dropoffTime
              ).getTime()
            )
          )

          return {
            id: index,
            start: new Date(start),
            end: new Date(end),
            resource: trip,
            resourceId: trip.id
          }
        }),
    [context.trips, context.selectedShift]
  )

  return (
    <div className='flex flex-row gap-4 min-h-full w-full pb-12'>
      {context.shifts ? (
        <DispatchPageShiftsTable
          data={context.shifts}
          className='min-w-[30%] max-w-[30%] h-full'
          onSelectionChange={value => context.setSelectedShift(value)}
        />
      ) : (
        <div className='flex flex-col gap-4 min-w-[30%] h-full border rounded-md p-2'>
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
          <Skeleton className='w-full h-16' />
        </div>
      )}
      {context.trips ? (
        <div className='w-full'>
          {context.selectedShift ? (
            <BigCalendar
              className='w-full h-full [&>*:first-child]:border-none'
              events={calendarEvents}
              defaultDate={context.date}
              defaultView={Views.DAY}
              localizer={calendarLocalizer}
              selectable={false}
              toolbar={false}
              timeslots={2}
              components={{
                eventWrapper: eventWrapperProps => {
                  return (
                    <TripPreview
                      trip={eventWrapperProps.event.resource}
                      /* @ts-ignore: Bad library type declaration. */
                      children={eventWrapperProps.children}
                    />
                  )
                },
                event: eventProps => {
                  return (
                    <div className='flex flex-row gap-2'>
                      {eventProps.event.resource.schedules.map(schedule => (
                        <span>
                          {formatName(schedule.rider)}{' '}
                          <ScheduleStatusBadge
                            className='h-4'
                            value={schedule.status}
                          />
                        </span>
                      ))}
                    </div>
                  )
                }
              }}
            />
          ) : (
            <AssignedTripsEmpty />
          )}
        </div>
      ) : (
        <div className='flex flex-col gap-4 w-full h-full border rounded-md p-2'>
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
          <Skeleton className='w-full h-20' />
        </div>
      )}
    </div>
  )
}
export default CalendarView
