import { useToast } from '@/components/ui/use-toast'
import companyService from '@/services/company.service'
import { IAPIError } from '@/types/common.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useRenamePreset = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      name,
      updatedName
    }: {
      name: string
      updatedName: string
    }) => {
      return await companyService.renamePreset(name, updatedName)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['presets'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
