import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import RiderPreview from '@/components/ui/previews/RiderPreview'
import { Skeleton } from '@/components/ui/skeleton'
import { ITrip } from '@/types/trips.type'
import { UseQueryResult } from '@tanstack/react-query'
import { uniqBy } from 'lodash'
import { FC, useMemo } from 'react'

interface IRidersListProps {
  trips: UseQueryResult<ITrip[]>
}

const RidersList: FC<IRidersListProps> = (props: IRidersListProps) => {
  const riders = useMemo(
    () =>
      uniqBy(
        props.trips.data
          ?.map(trip => trip.schedules.map(schedule => schedule.rider))
          .flat(),
        item => item.id
      ),
    [props.trips.data]
  )

  return (
    <Card className='w-full overflow-y-auto'>
      <CardHeader className='pb-2'>
        <CardTitle>Riders</CardTitle>
        <CardDescription>List of today riders</CardDescription>
      </CardHeader>
      <CardContent className='flex gap-4 flex-wrap'>
        {props.trips.isLoading && (
          <>
            <Skeleton className='w-28 h-4' />
            <Skeleton className='w-16 h-4' />
            <Skeleton className='w-20 h-4' />
            <Skeleton className='w-16 h-4' />
            <Skeleton className='w-40 h-4' />
            <Skeleton className='w-24 h-4' />
            <Skeleton className='w-20 h-4' />
          </>
        )}
        {riders.length ? (
          riders.map(rider => (
            <RiderPreview key={`rider${rider.id}`} value={rider} />
          ))
        ) : (
          <span className='text-sm text-muted-foreground'>
            There are no trips today.
          </span>
        )}
      </CardContent>
    </Card>
  )
}
export default RidersList

