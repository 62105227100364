import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { useDeleteBroker } from '@/hooks/mutations/useDeleteBroker'
import { IBroker } from '@/types/brokers.type'
import { FC, ReactNode } from 'react'

interface IBrokerDeleteDialogProps {
  broker: IBroker
  children: ReactNode
}

const BrokerDeleteDialog: FC<IBrokerDeleteDialogProps> = (
  props: IBrokerDeleteDialogProps
) => {
  const { mutate: deleteBroker } = useDeleteBroker()

  return (
    <AlertDialog>
      <AlertDialogTrigger
        onSelect={e => e.preventDefault()}
        disabled={!props.broker.deletable}
        asChild
      >
        {props.children}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete this
            broker.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => deleteBroker(props.broker)}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default BrokerDeleteDialog
