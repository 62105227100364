import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { cn } from '@/lib/utils'
import { FC, InputHTMLAttributes } from 'react'

interface ITextInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => any
}

const TextInput: FC<ITextInputProps> = (props: ITextInputProps) => {
  return (
    <div className={cn(`flex flex-col gap-2 w-64`, props.className)}>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Input
        {...props}
        className={cn('h-8', props.className)}
        value={props.value || undefined}
        onChange={event => props.onChange?.(event.target.value, event)}
      />
    </div>
  )
}

export default TextInput
