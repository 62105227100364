import brokersService from '@/services/brokers.service'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'use-debounce'

export const useBrokersSearch = (input: string, debounce = 200) => {
  const debounced = useDebounce(input, debounce)

  return useQuery({
    queryKey: ['brokersSearch', debounced[0]],
    queryFn: async () => await brokersService.search(input),
    select: response => response.data
  })
}

