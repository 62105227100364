import { Button } from '@/components/ui/button'
import EditableAvatar from '@/components/ui/EditableAvatar'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import MailChangeDialog from '@/components/ui/MailChangeDialog'
import PasswordRecoveryDialog from '@/components/ui/PasswordRecoveryDialog'
import { PhoneInput } from '@/components/ui/phone-input'
import Spinner from '@/components/ui/Spinner'
import { useToast } from '@/components/ui/use-toast'
import { useAdmin } from '@/hooks/queries/useAdmin'
import { useAppSelector } from '@/hooks/useAppSelector'
import { adminSchema, IAdmin, ISchemaAdmin } from '@/types/admins.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpdateAdmin } from './hooks/useUpdateAdmin'

const AdminForm: FC = () => {
  const { adminId } = useParams()
  const { toast } = useToast()

  const navigate = useNavigate()

  const admin = useAdmin(Number(adminId))
  const user = useAppSelector(selector => selector.userState.user)

  const form = useForm<ISchemaAdmin>({
    resolver: yupResolver(adminSchema)
  })

  const { mutate: submit } = useUpdateAdmin()

  useEffect(() => form.reset(admin.data), [admin.data])

  useEffect(() => {
    if (adminId === user?.id) navigate('/preferences')

    if (isNaN(Number(adminId)) || (admin.isFetched && !admin.data)) {
      navigate('/admins')
      toast({
        title: 'Error!',
        description: `Admin ${adminId} was not found. If you want to create a new admin, use "Invite" button.`,
        variant: 'destructive'
      })
    }
  }, [admin])

  return (
    <Form {...form}>
      {admin.isLoading ? (
        <Spinner />
      ) : (
        <form
          className='flex flex-col gap-4'
          onSubmit={form.handleSubmit((data: ISchemaAdmin) =>
            submit(data as IAdmin)
          )}
        >
          {admin.data?.user && <EditableAvatar user={admin.data.user} />}
          <div className='flex flex-row gap-4'>
            <FormField
              control={form.control}
              name='user.firstName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name *</FormLabel>
                  <FormControl>
                    <Input className='w-48 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='user.middleName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Middle Name</FormLabel>
                  <FormControl>
                    <Input className='w-48 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='user.lastName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name *</FormLabel>
                  <FormControl>
                    <Input className='w-48 h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name='user.username'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username *</FormLabel>
                <FormControl>
                  <Input className='w-96 h-8' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='user.email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email *</FormLabel>
                <FormControl>
                  <div className='flex flex-row gap-2 max-w-96'>
                    <Input
                      disabled={!!admin.data}
                      className='w-full h-8'
                      {...field}
                    />
                    {admin.data && <MailChangeDialog id={admin.data?.id} />}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='user.phone'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <PhoneInput className='w-96' defaultCountry='US' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='user.password'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <div className='flex flex-row gap-2 max-w-96'>
                    <Input
                      className='w-full h-8'
                      disabled
                      type='password'
                      defaultValue={admin.data && '*************'}
                      {...field}
                    />
                    {admin.data && (
                      <PasswordRecoveryDialog
                        className='w-20'
                        mail={admin.data.user.email}
                      />
                    )}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button className='w-64 h-8' type='submit'>
            <TbPencil className='mr-1' />
            Save changes
          </Button>
        </form>
      )}
    </Form>
  )
}
export default AdminForm
