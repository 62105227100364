import { ICompany, ICompanySettings } from '@/types/company.type'
import { IImportPreset, IImportProperties } from '@/types/import.type'
import { api } from './axios.interceptor'

class CompanyService {
  async get() {
    const response = await api.get<ICompany>(`/company`)

    return response
  }

  async updateSettings(data: Partial<ICompanySettings>) {
    const response = await api.patch<ICompany>(`/company/settings`, data)

    return response
  }

  async getPreset(name: string) {
    const response = await api.get<IImportPreset>(`/company/presets/${name}`)

    return response
  }

  async getPresets() {
    const response =
      await api.get<Record<string, IImportPreset>>(`/company/presets`)

    return response
  }

  async createPreset(name: string, preset: IImportProperties) {
    const response = await api.post(`/company/presets/${name}`, preset)

    return response
  }

  async renamePreset(name: string, updatedName: string) {
    const response = await api.patch(`/company/presets`, {
      name: name,
      updatedName: updatedName
    })

    return response
  }

  async deletePreset(name: string) {
    const response = await api.delete(`/company/presets/${name}`)

    return response
  }
}

const companyService = new CompanyService()

export default companyService
