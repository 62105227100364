import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible'
import { cn } from '@/lib/utils'
import { FC, ReactNode, useContext, useState } from 'react'
import { IconType } from 'react-icons'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'
import SideBarContext from './SideBarContext'

interface ISideBarCategoryProps {
  icon: IconType
  title: string
  children?: ReactNode
}

const SideBarCategory: FC<ISideBarCategoryProps> = (
  props: ISideBarCategoryProps
) => {
  const context = useContext(SideBarContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className='space-y-4 p-2'
    >
      <CollapsibleTrigger className='flex flex-row place-items-center justify-start w-full'>
        <div className='flex gap-3 place-items-center mr-2'>
          <props.icon className='h-6 w-6' />
          <h2
            className={cn(
              'text-sm font-medium',
              context.isCollapsed && 'hidden'
            )}
          >
            {props.title}
          </h2>
        </div>
        {isOpen ? (
          <TbChevronUp className='h-4 w-4' />
        ) : (
          <TbChevronDown className='h-4 w-4' />
        )}
      </CollapsibleTrigger>
      <CollapsibleContent className='flex flex-col gap-2'>
        {props.children}
      </CollapsibleContent>
    </Collapsible>
  )
}
export default SideBarCategory
