import {
  TableBody as PrimitiveTableBody,
  TableCell as PrimitiveTableCell,
  TableRow as PrimitiveTableRow
} from '@/components/ui/table'
import { Row } from '@tanstack/react-table'
import { FC } from 'react'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuTrigger
} from '../context-menu'
import { ITableDND } from './Table'
import TableRow from './TableRow'

interface ITableBodyProps<T> {
  rows: Row<T>[]
  contextMenu?: (
    data: T,
    row: Row<T>
  ) => React.ReactElement<typeof ContextMenuContent>
  dnd?: ITableDND<T>
  onSelectionChange?: (data: T | undefined, row?: Row<T>) => any
}

const TableBody: FC<ITableBodyProps<any>> = <T,>(props: ITableBodyProps<T>) => {
  return (
    <PrimitiveTableBody>
      {props.rows.length === 0 ? (
        <PrimitiveTableRow>
          <PrimitiveTableCell className='text-center h-16' colSpan={100}>
            <span className='text-muted-foreground text-sm'>
              No results found.
            </span>
          </PrimitiveTableCell>
        </PrimitiveTableRow>
      ) : (
        props.rows.map(row => {
          const isContextMenu = props.contextMenu

          const tableRow = (
            <TableRow
              row={row}
              key={`row-${row.id}`}
              onSelectionChange={props.onSelectionChange}
              dnd={props.dnd}
            />
          )

          return isContextMenu ? (
            <ContextMenu key={row.id}>
              <ContextMenuTrigger asChild>{tableRow}</ContextMenuTrigger>
              {props.contextMenu?.(row.original, row)}
            </ContextMenu>
          ) : (
            tableRow
          )
        })
      )}
    </PrimitiveTableBody>
  )
}
export default TableBody
