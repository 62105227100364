import { IAdmin } from '@/types/admins.type'
import { api } from './axios.interceptor'

class AdminsService {
  async getById(id: number) {
    const response = await api.get<IAdmin>(`/admins/${id}`)

    return response
  }

  async getAll() {
    const response = await api.get<IAdmin[]>('/admins')

    return response
  }

  async create(request: IAdmin) {
    const response = await api.post<IAdmin>('/admins', request)

    return response
  }

  async update(id: number, request: IAdmin) {
    const response = await api.patch<IAdmin>(`/admins/${id}`, request)

    return response
  }
}

const adminsService = new AdminsService()

export default adminsService
