import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { TbMail } from 'react-icons/tb'
import { useSearchParams } from 'react-router-dom'
import { object, string } from 'yup'
import { useRequestPasswordRecovery } from '../../hooks/mutations/useRequestPasswordRecovery'

const PasswordRecoveryForm: FC = () => {
  const form = useForm<{
    email: string
  }>({
    resolver: yupResolver(
      object({
        email: string()
          .email('Invalid email address.')
          .required('Email is required.')
      })
    )
  })

  const [_, setSearchParams] = useSearchParams()

  const resetPassword = useRequestPasswordRecovery(true)

  return (
    <Form {...form}>
      <form
        className={`flex justify-center place-items-center h-screen w-screen`}
        onSubmit={form.handleSubmit(data => resetPassword.mutate(data.email))}
      >
        <Card className='relative flex flex-col w-[600px] h-[400px] justify-center place-items-center bg-background'>
          <img src='/favicon.png' className='w-16 h-16' />
          <CardHeader className='flex flex-col justify-center place-items-center'>
            <CardTitle>Forgot your password?</CardTitle>
            <CardDescription>
              Enter your email to recover your password.
            </CardDescription>
          </CardHeader>
          <CardContent className='flex flex-col gap-4 justify-center place-items-center'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input className='w-80 h-10' {...field} />
                  </FormControl>
                  <FormMessage />
                  <button
                    className='text-xs text-muted-foreground'
                    type='button'
                    onClick={() => setSearchParams({})}
                  >
                    Found my password.
                  </button>
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className='flex flex-col gap-4 jusitfy-center place-items-center'>
            <Button
              disabled={resetPassword.isSuccess}
              type='submit'
              className='w-80 h-10'
            >
              <TbMail className='h-4 w-4 mr-1' />
              Send link to email
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}
export default PasswordRecoveryForm
