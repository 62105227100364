import { useToast } from '@/components/ui/use-toast'
import { formatName } from '@/lib/utils'
import usersService from '@/services/users.service'
import { IAPIError } from '@/types/common.type'
import { IDriver } from '@/types/drivers.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteDriver = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async (driver: IDriver) => {
      if (!driver) throw new Error('No driver selected.')

      await usersService.delete(driver.userId)

      return driver
    },
    onSuccess: async data => {
      toast({
        title: 'Deleted!',
        description: `Driver ${formatName(data.user)} (${data.user.username}) was deleted successfully.`
      })

      queryClient.invalidateQueries({ queryKey: ['drivers'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
