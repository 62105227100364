import ridersService from '@/services/riders.service'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'use-debounce'

export const useRidersSearch = (input: string, debounce = 200) => {
  const debounced = useDebounce(input, debounce)

  return useQuery({
    queryKey: ['riderSearch', debounced[0]],
    queryFn: async () => await ridersService.search(input),
    select: response => response.data
  })
}
