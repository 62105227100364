import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface ILinkProps {
  to: string
  children?: React.ReactNode
  className?: string
}

const Link: FC<ILinkProps> = (props: ILinkProps) => {
  return (
    <RouterLink
      className={`hover: transition-colors ${props.className}`}
      to={props.to}
    >
      {props.children}
    </RouterLink>
  )
}

export default Link
