import tripsService from '@/services/trips.service'
import { ISchedule } from '@/types/trips.type'
import { useQuery } from '@tanstack/react-query'

export const useSignature = (
  schedule: ISchedule,
  signatureType: 'rider' | 'driver'
) =>
  useQuery({
    enabled:
      !!schedule.id &&
      ((signatureType === 'rider' && schedule.isRiderSignatureUploaded) ||
        (signatureType === 'driver' && schedule.isDriverSignatureUploaded)),
    queryFn: async () =>
      await tripsService.getSignature(schedule.id, signatureType),
    select: response => response.data,
    retry: 0,
    queryKey: [`signature`, signatureType, schedule.id]
  })
