import {
  TableCell,
  TableHead as PrimitiveTableHead,
  TableRow
} from '@/components/ui/table'
import { flexRender, HeaderGroup } from '@tanstack/react-table'
import { FC } from 'react'
import { TbArrowDown, TbArrowsUpDown, TbArrowUp } from 'react-icons/tb'

interface ITableHeadProps<T> {
  headerGroup: HeaderGroup<T>
}

const TableHead: FC<ITableHeadProps<any>> = <T,>(props: ITableHeadProps<T>) => {
  return (
    <TableRow key={props.headerGroup.id}>
      {props.headerGroup.headers.map(header => (
        <PrimitiveTableHead
          style={{
            width: header.column.getSize()
          }}
          key={header.id}
        >
          {flexRender(
            <TableCell
              onClick={header.column.getToggleSortingHandler()}
              key={header.column.id}
              className='flex flex-row place-items-center gap-2'
            >
              <span className='font-normal -ml-4'>
                {header.column.columnDef.header as string}
              </span>
              {header.column.getIsSorted() === 'asc' && (
                <TbArrowUp className='text-xs' />
              )}
              {header.column.getIsSorted() === 'desc' && (
                <TbArrowDown className='text-xs' />
              )}
              {header.column.getIsSorted() === false &&
                header.column.getCanSort() && (
                  <TbArrowsUpDown className='text-xs' />
                )}
            </TableCell>,
            header.getContext()
          )}
        </PrimitiveTableHead>
      ))}
    </TableRow>
  )
}
export default TableHead
