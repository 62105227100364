import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuRadioGroup,
  ContextMenuRadioItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger
} from '@/components/ui/context-menu'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { useCalled } from '@/hooks/mutations/useCalled'
import { useSetScheduleStatus } from '@/hooks/mutations/usetSetScheduleStatus'
import { useUncombineTrip } from '@/hooks/mutations/useUncombineTrip'
import { formatScheduleStatus } from '@/lib/utils'
import { ISchedule, ITrip, ScheduleStatus } from '@/types/trips.type'
import { FC, useState } from 'react'
import { TbAnchor, TbPencil, TbPhone, TbUnlink, TbUser } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

interface ITripContextMenuProps {
  schedule: ISchedule
  trip: ITrip
}

const ScheduleContextMenu: FC<ITripContextMenuProps> = (
  props: ITripContextMenuProps
) => {
  const navigate = useNavigate()

  const [dialogStatus, setDialogStatus] = useState<undefined | ScheduleStatus>()

  const { mutate: setStatus } = useSetScheduleStatus()
  const { mutate: setCalled } = useCalled()
  const { mutate: uncombine } = useUncombineTrip()

  const handleStatusChange = (status: ScheduleStatus) => {
    if (status === ScheduleStatus.Canceled || status === ScheduleStatus.NoShow)
      return setDialogStatus(status)

    return setStatus({
      schedule: props.schedule,
      status: status
    })
  }

  return (
    <>
      <Dialog
        onOpenChange={() => setDialogStatus(undefined)}
        open={!!dialogStatus}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Change status to {formatScheduleStatus(dialogStatus!)}
            </DialogTitle>
            <DialogDescription>
              You can update the status of this trip only for this trip or for
              all "Assigned" or "Unassigned" trips of this rider for selected
              day. You can change status later.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              onClick={() => {
                setStatus({
                  schedule: props.schedule,
                  forAllRiderTrips: true,
                  status: dialogStatus!
                })
                setDialogStatus(undefined)
              }}
              variant='destructive'
            >
              All trips of this rider
            </Button>
            <Button
              onClick={() => {
                setStatus({
                  schedule: props.schedule,
                  forAllRiderTrips: false,
                  status: dialogStatus!
                })
                setDialogStatus(undefined)
              }}
            >
              This trip only
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {props.trip ? (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem
            onClick={() => navigate(`/trips/${props.schedule.tripId}`)}
          >
            <TbPencil className='mr-2 h-4 w-4' />
            Edit Trip
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => navigate(`/drivers/${props.trip?.shift?.driverId}`)}
            disabled={!!!props.trip?.shift}
          >
            <TbUser className='mr-2 h-4 w-4' />
            Edit Driver
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => navigate(`/riders/${props.schedule.riderId}`)}
          >
            <TbAnchor className='mr-2 h-4 w-4' />
            Edit Rider
          </ContextMenuItem>
          {props.schedule.willCall && (
            <ContextMenuItem onClick={() => setCalled(props.schedule)}>
              <TbPhone className='mr-2 h-4 w-4' />
              Person Called
            </ContextMenuItem>
          )}
          <ContextMenuSeparator />
          <ContextMenuSub>
            <ContextMenuSubTrigger>Status</ContextMenuSubTrigger>
            <ContextMenuSubContent>
              <ContextMenuRadioGroup
                onValueChange={value =>
                  handleStatusChange(value as ScheduleStatus)
                }
                value={props.schedule.status}
              >
                {props.trip.shift ? (
                  <ContextMenuRadioItem value={ScheduleStatus.Assigned}>
                    Assigned
                  </ContextMenuRadioItem>
                ) : (
                  <ContextMenuRadioItem value={ScheduleStatus.Unassigned}>
                    Unassigned
                  </ContextMenuRadioItem>
                )}
                <ContextMenuRadioItem value={ScheduleStatus.NoShow}>
                  No Show
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.ArrivedAtDO}>
                  Arrived (DO)
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.ArrivedAtPU}>
                  Arrived (PU)
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.Canceled}>
                  Canceled
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.Completed}>
                  Completed
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.InRoute}>
                  In Route
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.InTransit}>
                  In Transit
                </ContextMenuRadioItem>
              </ContextMenuRadioGroup>
            </ContextMenuSubContent>
          </ContextMenuSub>
        </ContextMenuContent>
      ) : (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem onClick={() => uncombine(props.schedule.id)}>
            <TbUnlink className='mr-2 h-4 w-4' />
            Uncombine
          </ContextMenuItem>
        </ContextMenuContent>
      )}
    </>
  )
}
export default ScheduleContextMenu
