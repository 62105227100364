import { useToast } from '@/components/ui/use-toast'
import reportsService from '@/services/reports.service'
import { IAPIError } from '@/types/common.type'
import { useMutation } from '@tanstack/react-query'

export const useDownloadReport = (preview?: boolean) => {
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (id: number) =>
      preview
        ? await reportsService.getPreviewUrl(id)
        : await reportsService.getDownloadUrl(id),
    onSuccess: ({ data }) => window.open(data)?.focus(),
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}

