import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { useDeleteShift } from '@/hooks/mutations/useDeleteShift'
import { useDeleteShiftBulk } from '@/hooks/mutations/useDeleteShiftBulk'
import { cn, formatName } from '@/lib/utils'
import { IShift } from '@/types/shifts.type'
import { addYears, endOfYear, format, startOfDay, startOfYear } from 'date-fns'
import { FC, ReactNode, useState } from 'react'

interface IShiftDeleteDialogProps {
  shift: IShift
  children: ReactNode
}

enum DeleteType {
  Single = 'single',
  Following = 'following',
  Year = 'year'
}

const ShiftDeleteDialog: FC<IShiftDeleteDialogProps> = (
  props: IShiftDeleteDialogProps
) => {
  const { mutate: deleteShift } = useDeleteShift()
  const { mutate: deleteShiftBulk } = useDeleteShiftBulk()

  const handleDeleteClick = () => {
    if (deleteType === DeleteType.Single) deleteShift(props.shift)

    if (deleteType === DeleteType.Following)
      deleteShiftBulk({
        driverId: props.shift.driverId,
        range: {
          start: startOfDay(props.shift.date),
          end: addYears(props.shift.date, 1)
        }
      })

    if (deleteType === DeleteType.Year)
      deleteShiftBulk({
        driverId: props.shift.driverId,
        range: {
          start: startOfYear(new Date()),
          end: endOfYear(new Date())
        }
      })
  }

  const [deleteType, setDeleteType] = useState<DeleteType>(DeleteType.Single)

  return (
    <AlertDialog>
      <AlertDialogTrigger onSelect={e => e.preventDefault()} asChild>
        {props.children}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete{' '}
            {deleteType === DeleteType.Single
              ? `this ${formatName(props.shift.driver.user)}'s shift.`
              : `all non-started ${formatName(props.shift.driver.user)}'s shifts.`}
            <RadioGroup
              value={deleteType}
              onValueChange={value => setDeleteType(value as DeleteType)}
              className='mt-2 [&>div]:flex [&>div]:flex-row [&>div]:place-items-center [&>div]:gap-2'
            >
              <div>
                <RadioGroupItem
                  value={DeleteType.Single}
                  id={DeleteType.Single}
                />
                <Label htmlFor={DeleteType.Single}>Only this shift</Label>
              </div>
              <div>
                <RadioGroupItem
                  value={DeleteType.Following}
                  id={DeleteType.Following}
                />
                <Label htmlFor={DeleteType.Following}>
                  This and following shifts{' '}
                  <span className='text-muted-foreground/50'>
                    ({format(props.shift.date, 'MM.dd')} - ...)
                  </span>
                </Label>
              </div>
              <div>
                <RadioGroupItem value={DeleteType.Year} id={DeleteType.Year} />
                <Label htmlFor={DeleteType.Year}>
                  All shifts in this year{' '}
                  <span className='text-muted-foreground/50'>
                    ({format(startOfYear(new Date()), 'MM.dd')} -{' '}
                    {format(endOfYear(new Date()), 'MM.dd')})
                  </span>
                </Label>
              </div>
            </RadioGroup>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className={cn(
              deleteType !== DeleteType.Single &&
                'bg-destructive text-destructive-foreground'
            )}
            onClick={handleDeleteClick}
          >
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default ShiftDeleteDialog
