import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useAppDispatch } from '@/hooks/useAppDispatch'
import { setTheme, Theme } from '@/reducers/settingsSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { TbLogin, TbMoon, TbSun } from 'react-icons/tb'
import { useSearchParams } from 'react-router-dom'
import { object, string } from 'yup'
import { useLogin } from './hooks/useLogin'

const LoginForm: FC = () => {
  const dispatch = useAppDispatch()
  const [_, setSearchParams] = useSearchParams()

  const loginForm = useForm<{
    username: string
    password: string
  }>({
    resolver: yupResolver(
      object({
        username: string().required('Username is required.'),
        password: string().required('Password is required.')
      })
    )
  })

  const { mutate: login } = useLogin()

  return (
    <Form {...loginForm}>
      <form
        className={`flex justify-center place-items-center h-screen w-screen`}
        onSubmit={loginForm.handleSubmit(data =>
          login({
            username: data.username,
            password: data.password
          })
        )}
      >
        <Card className='relative flex flex-col w-[750px] h-[600px] justify-center place-items-center bg-background'>
          <img src='/favicon.png' className='w-16 h-16' />
          <CardHeader className='flex flex-col justify-center place-items-center'>
            <CardTitle>Sign In</CardTitle>
            <CardDescription>
              Enter your credentials below to continue with ManageNEMT.
            </CardDescription>
            <Button variant='ghost' className='absolute right-4 top-4' asChild>
              <a href='https://managenemt.com/register' target='_blank'>
                Sign Up
              </a>
            </Button>
          </CardHeader>
          <CardContent className='flex flex-col gap-4 justify-center place-items-center'>
            <FormField
              control={loginForm.control}
              name='username'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Username</FormLabel>
                  <FormControl>
                    <Input
                      autoComplete='username'
                      placeholder='johndoe'
                      className='w-80 h-10'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={loginForm.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      autoComplete='password'
                      type='password'
                      className='w-80 h-10'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                  <button
                    className='text-xs text-muted-foreground'
                    type='button'
                    onClick={() =>
                      setSearchParams({
                        'forgot-password': '1'
                      })
                    }
                  >
                    Forgot your password?
                  </button>
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className='flex flex-col jusitfy-center place-items-center'>
            <Button className='w-80 h-10'>
              <TbLogin className='h-4 w-4 mr-1' /> Sign In
            </Button>
            <div className='flex flex-row gap-2 absolute bottom-8'>
              <Button
                size='icon'
                variant='ghost'
                type='button'
                onClick={() => dispatch(setTheme(Theme.Light))}
              >
                <TbSun className='h-4 w-4' />
              </Button>
              <Button
                size='icon'
                variant='ghost'
                type='button'
                onClick={() => dispatch(setTheme(Theme.Dark))}
              >
                <TbMoon className='h-4 w-4' />
              </Button>
            </div>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}
export default LoginForm
