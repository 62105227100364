import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { FC, useState } from 'react'
import { TbCheck, TbSelector } from 'react-icons/tb'

interface IComboboxProps {
  value?: string
  options: string[]
  hideEmptyOption?: boolean
  id?: string
  title?: string
  required?: boolean
  fieldClassName?: string
  popoverClassName?: string
  onChange?: (value: string | undefined) => any
}

const Combobox: FC<IComboboxProps> = (props: IComboboxProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')

  return (
    <div className={cn('flex flex-col gap-2')}>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            aria-expanded={open}
            className={cn('h-8 w-64 justify-between', props.fieldClassName)}
          >
            <span className='overflow-hidden whitespace-nowrap text-ellipsis'>
              {props.value || 'Select...'}
            </span>
            <TbSelector />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command>
            <CommandInput
              placeholder={`Search...`}
              value={input}
              onValueChange={setInput}
            />
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {!props.hideEmptyOption && (
                  <CommandItem
                    value={'Select...'}
                    onSelect={() => {
                      props.onChange?.(undefined)
                      setOpen(false)
                    }}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${props.value === undefined ? 'opacity-100' : 'opacity-0'}`}
                    />
                    Select...
                  </CommandItem>
                )}
                {props.options.map(option => (
                  <CommandItem
                    key={option}
                    value={option}
                    onSelect={() => {
                      props.onChange?.(option)
                      setOpen(false)
                    }}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${props.value === option ? 'opacity-100' : 'opacity-0'}`}
                    />
                    {option}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default Combobox
