import driverService from '@/services/drivers.service'
import { useQuery } from '@tanstack/react-query'

export const useDrivers = (workingToday?: boolean) =>
  useQuery({
    queryKey: ['drivers', workingToday],
    queryFn: () => {
      return workingToday
        ? driverService.getWorkingToday()
        : driverService.getAll()
    },
    select: response => response.data
  })
