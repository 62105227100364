import { cn } from '@/lib/utils'
import { FC, HTMLAttributes } from 'react'
import { TbLoader2 } from 'react-icons/tb'

interface ISpinnerProps extends HTMLAttributes<SVGElement> {}

const Spinner: FC<ISpinnerProps> = (props: ISpinnerProps) => {
  return (
    <TbLoader2
      {...props}
      className={cn('text-2xl animate-spin', props.className)}
    />
  )
}

export default Spinner
