import { useToast } from '@/components/ui/use-toast'
import { formatName } from '@/lib/utils'
import tripService from '@/services/trips.service'
import { IAPIError } from '@/types/common.type'
import { IShift } from '@/types/shifts.type'
import { ITrip } from '@/types/trips.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useAssignTrip = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({ trip, shift }: { trip: ITrip; shift: IShift }) => {
      if (!trip.id) throw new Error('Trip not found.')

      await tripService.assign(trip.id, shift.id)

      return { trip, shift }
    },
    onSuccess: async data => {
      toast({
        title: `Assigned!`,
        description: `Trip #${data.trip.id} (${data.trip.schedules.map(schedule => `${formatName(schedule.rider)}`).join(', ')}) was assigned to ${formatName(data.shift.driver.user)}.`
      })

      await queryClient.invalidateQueries({ queryKey: ['trips'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
