import geoService from '@/services/geo.service'
import { AutocompleteType } from '@/types/geo.type'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'use-debounce'

export const useAddressSearch = (
  input: string,
  type: AutocompleteType,
  debounce = 300
) => {
  const debounced = useDebounce(input, debounce)

  return useQuery({
    queryKey: ['addressSearch', debounced[0]],
    enabled: !!input,
    queryFn: async () =>
      await geoService.search(input, {
        type: type
      }),
    select: response => response.data
  })
}
