import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator
} from '@/components/ui/breadcrumb'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { FC, Fragment, useContext, useMemo } from 'react'
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand
} from 'react-icons/tb'
import { useMatches } from 'react-router-dom'
import SideBarContext from '../sideBar/SideBarContext'
import User from './User'

const TopBar: FC = () => {
  const matches = useMatches()
  const crumbs = useMemo(
    () =>
      matches
        .filter(match => Boolean((match.handle as any)?.crumb))
        .map((match, index) => {
          const rawParam = Object.values(match.params)[0]

          if (!rawParam) return (match.handle as any).crumb()

          /* Example: /trips/create -> Trips > Create */
          const param = rawParam[0].toUpperCase() + rawParam.slice(1)

          return (match.handle as any).crumb(param)
        }),
    [matches]
  )

  const sidebarContext = useContext(SideBarContext)
  const handleSidebarToggle = () =>
    sidebarContext.setIsCollapsed(!sidebarContext.isCollapsed)

  return (
    <div className='relative flex flex-row gap-3 w-full h-8 min-h-8 max-h-8 mb-2 place-items-center'>
      <Button
        onClick={handleSidebarToggle}
        variant='link'
        size='icon'
        className='h-4 w-4'
      >
        {sidebarContext.isCollapsed ? (
          <TbLayoutSidebarLeftExpand className='h-4 w-4' />
        ) : (
          <TbLayoutSidebarLeftCollapse className='h-4 w-4' />
        )}
      </Button>
      <Separator orientation='vertical' className='h-4' />
      <Breadcrumb>
        <BreadcrumbList>
          {crumbs.map((crumb, index) => (
            <Fragment key={index}>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>{crumb}</BreadcrumbLink>
              </BreadcrumbItem>
              {index !== crumbs.length - 1 && <BreadcrumbSeparator />}
            </Fragment>
          ))}
        </BreadcrumbList>
      </Breadcrumb>
      <User />
    </div>
  )
}
export default TopBar
