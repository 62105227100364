import vehiclesService from '@/services/vehicles.service'
import { useQuery } from '@tanstack/react-query'

export const useVehicle = (vehicleId: number) =>
  useQuery({
    enabled: !isNaN(vehicleId),
    queryKey: ['vehicle', vehicleId],
    queryFn: async () => await vehiclesService.getById(vehicleId),
    select: response => response.data,
    retry: 1
  })
