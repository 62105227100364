import { object, ObjectSchema } from 'yup'
import { ISchemaUser, IUser, userSchema } from './users.type'

export interface IAdmin {
  id: number
  userId: number
  user: IUser
}

export interface ISchemaAdmin {
  user: ISchemaUser
}

export const adminSchema: ObjectSchema<ISchemaAdmin> = object({
  user: userSchema.required()
})
