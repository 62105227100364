import driverService from '@/services/drivers.service'
import { IDriverSearch } from '@/types/drivers.type'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'use-debounce'

export const useDriversSearch = (
  input: string,
  params?: IDriverSearch,
  debounce = 200
) => {
  const debounced = useDebounce(input, debounce)

  return useQuery({
    queryKey: ['driversSearch', debounced[0], params],
    queryFn: async () => await driverService.search(input, params),
    select: response => response.data
  })
}
