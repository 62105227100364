import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel
} from '@/components/ui/context-menu'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import DriverPreview from '@/components/ui/previews/DriverPreview'
import VehiclePreview from '@/components/ui/previews/VehiclePreview'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { cn } from '@/lib/utils'
import { ITimeRange } from '@/types/common.type'
import { IShift } from '@/types/shifts.type'
import { format } from 'date-fns'
import { FC } from 'react'
import { TbDots } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import ShiftDeleteDialog from './ShiftDeleteDialog'

export interface IShiftsTableProps extends ICustomizableTableProps<IShift> {}

const ShiftsTable: FC<IShiftsTableProps> = (props: IShiftsTableProps) => {
  const navigate = useNavigate()

  return (
    <Table
      {...props}
      initialState={{
        sorting: [
          {
            desc: false,
            id: 'date'
          }
        ]
      }}
      contextMenu={(shift: IShift) => (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem onClick={() => navigate(`/shifts/${shift.id}`)}>
            Edit
          </ContextMenuItem>
          <ShiftDeleteDialog shift={shift}>
            <ContextMenuItem disabled={shift.started}>Delete</ContextMenuItem>
          </ShiftDeleteDialog>
        </ContextMenuContent>
      )}
      columns={[
        {
          id: 'started',
          header: 'Status',
          accessorKey: 'started',
          cell: props => {
            return (
              <Badge
                className={cn(
                  'w-24 justify-center',
                  props.getValue() ? 'bg-green-500' : 'bg-red-400'
                )}
              >
                {props.getValue() ? 'Started' : 'Not Started'}
              </Badge>
            )
          }
        },
        {
          id: 'date',
          header: 'Date',
          accessorKey: 'date',
          cell: props => format(props.getValue(), 'MM.dd.yyyy')
        },
        {
          id: 'driver',
          header: 'Driver',
          accessorKey: 'driver',
          cell: props => <DriverPreview value={props.getValue()} />
        },
        {
          id: 'vehicle',
          header: 'Vehicle',
          accessorKey: 'vehicle',
          cell: props => <VehiclePreview value={props.getValue()} />
        },
        {
          id: 'breaks',
          header: 'Breaks',
          accessorKey: 'breaks',
          cell: props =>
            props.getValue<ITimeRange[]>().map((break_, index) => (
              <p key={index} className='whitespace-nowrap'>
                {format(break_.start, 'HH:mm')} - {format(break_.end, 'HH:mm')}
              </p>
            ))
        },
        {
          id: 'workingTime',
          accessorKey: 'workingTime',
          header: 'Working Time',
          cell: props => (
            <span>
              {format(props.getValue().start, 'HH:mm')} -{' '}
              {format(props.getValue().end, 'HH:mm')}
            </span>
          )
        },
        {
          id: 'actions',
          enableSorting: false,
                    size: 100,
                    accessorFn: data => data,
          cell: props => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='ghost' className='h-8 w-8 p-0'>
                  <TbDots className='h-4 w-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                onClick={event => event.stopPropagation()}
                align='end'
              >
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() => navigate(`/shifts/${props.row.original.id}`)}
                >
                  Edit
                </DropdownMenuItem>
                <ShiftDeleteDialog shift={props.getValue()}>
                  <DropdownMenuItem disabled={props.getValue().started}>
                    Delete
                  </DropdownMenuItem>
                </ShiftDeleteDialog>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      ]}
    />
  )
}
export default ShiftsTable
