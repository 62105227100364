import { createContext } from 'react'

interface ISideBarContext {
  isCollapsed: boolean
  setIsCollapsed: (value: boolean) => void
}

const SideBarContext = createContext<ISideBarContext>({} as ISideBarContext)

export default SideBarContext
