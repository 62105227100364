import { Badge } from '@/components/ui/badge'
import { cn } from '@/lib/utils'
import { ReportProcessStatus } from '@/types/reports.type'
import { FC } from 'react'

interface IReportStatusBadgeProps {
  value: ReportProcessStatus
}

const statusToColor: Record<ReportProcessStatus, string> = {
  Started: 'bg-stone-200',
  Processing: 'bg-yellow-500',
  Uploading: 'bg-blue-500',
  Completed: 'bg-green-500',
  Error: 'bg-red-500'
}

const ReportStatusBadge: FC<IReportStatusBadgeProps> = (
  props: IReportStatusBadgeProps
) => {
  return (
    <Badge className={cn('w-24 justify-center', statusToColor[props.value])}>
      {props.value}
    </Badge>
  )
}
export default ReportStatusBadge

