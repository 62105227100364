import tripsService from '@/services/trips.service'
import { useQuery } from '@tanstack/react-query'

export const useSchedules = (start: Date, end: Date) =>
  useQuery({
    queryKey: ['schedules', start, end],
    queryFn: async () =>
      await tripsService.getSchedulesByTime({
        start: start,
        end: end
      }),
    select: response => response.data
  })
