import brokersService from '@/services/brokers.service'
import { useQuery } from '@tanstack/react-query'

export const useBroker = (brokerId: number) =>
  useQuery({
    enabled: !isNaN(brokerId),
    retry: 1,
    queryKey: ['broker', brokerId],
    queryFn: async () => await brokersService.getById(brokerId),
    select: response => response.data
  })
