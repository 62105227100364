import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel
} from '@/components/ui/context-menu'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { formatAddress } from '@/lib/utils'
import { IBroker } from '@/types/brokers.type'
import { FC } from 'react'
import { TbDots } from 'react-icons/tb'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'
import BrokerDeleteDialog from './BrokerDeleteDialog'

const BrokersTable: FC<ICustomizableTableProps<IBroker>> = (
  props: ICustomizableTableProps<IBroker>
) => {
  const navigate = useNavigate()

  return (
    <Table
      {...props}
      initialState={{
        sorting: [
          {
            id: 'name',
            desc: false
          }
        ]
      }}
      contextMenu={(row: IBroker) => (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem onClick={() => navigate(`/brokers/${row.id}`)}>
            Edit
          </ContextMenuItem>
          <BrokerDeleteDialog broker={row}>
            <ContextMenuItem>Delete</ContextMenuItem>
          </BrokerDeleteDialog>
        </ContextMenuContent>
      )}
      columns={[
        {
          accessorKey: 'name',
          accessorFn: (broker: IBroker) => broker.name,
          header: 'Name',
          cell: props => <p>{props.getValue()}</p>
        },
        {
          accessorKey: 'billingAddress',
          accessorFn: (broker: IBroker) => broker.billingAddress,
          header: 'Billing Address',
          cell: props => (
            <span className='text-xs'>{formatAddress(props.getValue())}</span>
          )
        },
        {
          accessorKey: 'billingEmail',
          accessorFn: (broker: IBroker) => broker.billingEmail,
          header: 'Billing Email',
          cell: props => <span>{props.getValue()}</span>
        },
        {
          accessorKey: 'billingPhone',
          accessorFn: (broker: IBroker) => broker.billingPhone,
          header: 'Billing Phone',
          cell: props => (
            <span>
              {formatPhoneNumberIntl(props.getValue()) || props.getValue()}
            </span>
          )
        },
        {
          id: 'actions',
                    size: 100,
                    cell: props => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='ghost' className='h-8 w-8 p-0'>
                  <TbDots className='h-4 w-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                onClick={event => event.stopPropagation()}
                align='end'
              >
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() => navigate(`/brokers/${props.row.original.id}`)}
                >
                  Edit
                </DropdownMenuItem>
                <BrokerDeleteDialog broker={props.row.original}>
                  <DropdownMenuItem>Delete</DropdownMenuItem>
                </BrokerDeleteDialog>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      ]}
    />
  )
}

export default BrokersTable
