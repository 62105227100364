import Combobox from '@/components/ui/combobox'
import TextInput from '@/components/ui/inputs/TextInput'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import {
  HeaderType,
  IImportProperties,
  IImportProperty
} from '@/types/import.type'
import { FC, useContext } from 'react'
import { TbArrowsSplit, TbPlus, TbPuzzle, TbTarget } from 'react-icons/tb'
import ImportContext from '../ImportContext'

interface IImportPropertiesHeader {
  name: string
  description?: string
  required?: boolean
  property: keyof IImportProperties
}

const ImportPropertiesHeader: FC<IImportPropertiesHeader> = (
  props: IImportPropertiesHeader
) => {
  const context = useContext(ImportContext)

  const propertyValue = context.form.getValues(`properties`)[props.property]

  const handleHeaderSelection = (header: string | undefined, index: number) => {
    if (index < propertyValue.headers.length) {
      const updatedArray = propertyValue.headers.flatMap((element, i) => {
        if (i === index) {
          if (header) return header
          if (!header) return []
          if (index > propertyValue.headers.length && !header) return []
        }

        return element
      })

      return handleHeaderChange({
        headers: updatedArray
      })
    }

    if (header) {
      return handleHeaderChange({
        headers: [...propertyValue.headers, header]
      })
    }
  }

  const handleHeaderChange = (value: Partial<IImportProperty>) => {
    context.form.setValue('properties', {
      ...context.form.getValues('properties'),
      [props.property]: {
        ...[props.property],
        ...value
      }
    })
  }

  const handleTypeChange = (type: HeaderType) => {
    const updated = [propertyValue.headers[0]]

    handleHeaderChange({
      headers: updated,
      type: type
    })
  }

  /* 
    Array that contains showed comboboxes. If type equals "Add" - makes comboboxes with
    choosed headers + 1. Else, if type is not "Add" - makes one combobox.
  */
  const showedHeaders = [
    ...propertyValue.headers,
    ...(propertyValue.type === HeaderType.Add ||
    propertyValue.headers.length === 0
      ? [undefined]
      : [])
  ]

  return (
    <div className='flex flex-row border gap-4 rounded-md place-items-center w-full h-16'>
      <div className='flex flex-col place-items-center justify-center text-center w-60 h-10 px-2 gap-0 border-r-2'>
        <span className='font-semibold text-sm'>
          {props.name}
          {props.required && <span className='text-destructive'> *</span>}
        </span>
        {props.description && (
          <span className='text-[10px] text-muted-foreground'>
            {props.description}
          </span>
        )}
      </div>
      <div className='flex flex-row place-items-center gap-4 h-10 w-full px-4 overflow-x-auto'>
        <ToggleGroup
          type='single'
          value={propertyValue?.type}
          defaultValue={HeaderType.Get}
          className='pr-4 border-r-2 min-w-40 h-10'
          variant='outline'
          onValueChange={value => {
            /* Make it required */
            if (!value) return

            handleTypeChange(value as HeaderType)
          }}
        >
          <ToggleGroupItem
            title='Add mode. Adds all selected headers to each other with selected separator.'
            className='w-8 h-8 p-0'
            value={HeaderType.Add}
          >
            <TbPlus />
          </ToggleGroupItem>
          <ToggleGroupItem
            title='Each mode. Gets each separated element from selected header, then adds values into data array as different array elements.'
            className='w-8 h-8 p-0'
            value={HeaderType.Each}
          >
            <TbPuzzle />
          </ToggleGroupItem>
          <ToggleGroupItem
            title='Get mode. Just gets value from selected header.'
            className='w-8 h-8 p-0'
            value={HeaderType.Get}
          >
            <TbTarget />
          </ToggleGroupItem>
          <ToggleGroupItem
            title='Split mode. Splits header by selected separator then selected N element from resulted array.'
            className='w-8 h-8 p-0'
            value={HeaderType.Split}
          >
            <TbArrowsSplit />
          </ToggleGroupItem>
        </ToggleGroup>
        <div className='flex flex-row place-items-center gap-2 w-full overflow-x-auto'>
          {showedHeaders.map((header, index) => (
            <Combobox
              options={context.headers}
              value={header}
              key={`${header}${index}`}
              fieldClassName='w-40'
              onChange={value => handleHeaderSelection(value, index)}
            />
          ))}
        </div>
        {propertyValue.type !== HeaderType.Get && (
          <div className='flex flex-row-reverse gap-4'>
            {(propertyValue.type === HeaderType.Split ||
              propertyValue.type === HeaderType.Each ||
              propertyValue.type === HeaderType.Add) && (
              <TextInput
                placeholder='Separator'
                className='w-24'
                value={propertyValue.separator}
                onChange={value => handleHeaderChange({ separator: value })}
              />
            )}
            {propertyValue.type === HeaderType.Split && (
              <TextInput
                placeholder='Select'
                className='w-24'
                min={0}
                type='number'
                value={String(propertyValue.select)}
                onChange={value =>
                  handleHeaderChange({ select: Number(value) })
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default ImportPropertiesHeader
