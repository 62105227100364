import { IRider } from '@/types/riders.type'
import { api } from './axios.interceptor'

class RidersService {
  async getAll() {
    const response = await api.get<IRider[]>(`/riders`)

    return response
  }

  async getById(id: number) {
    const response = await api.get<IRider>(`/riders/${id}`)

    return response
  }

  async search(input: string) {
    const response = await api.get<IRider[]>(`/riders/search/${input}`)

    return response
  }

  async create(request: IRider) {
    const response = await api.post<IRider>('/riders', request)

    return response
  }

  async update(id: number, request: IRider) {
    const response = await api.patch<IRider>(`/riders/${id}`, request)

    return response
  }
}

const ridersService = new RidersService()

export default ridersService
