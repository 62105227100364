import shiftsService from '@/services/shifts.service'
import { useQuery } from '@tanstack/react-query'

export const useShift = (shiftId: number) =>
  useQuery({
    enabled: !isNaN(shiftId),
    retry: 1,
    queryKey: ['shift', shiftId],
    queryFn: async () => await shiftsService.getById(shiftId),
    select: response => response.data
  })
