import { toast } from '@/components/ui/use-toast'
import { setUser } from '@/reducers/userSlice'
import usersService from '@/services/users.service'
import { IAPIError } from '@/types/common.type'
import { IUser } from '@/types/users.type'
import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'

export const useUpdateMe = () => {
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: async (data: IUser) => {
      return await usersService.updateMe(data)
    },
    onSuccess: ({ data }) => {
      dispatch(setUser(data))
      toast({
        title: 'Changes have been saved!',
        description: 'Your account was updated successfully.'
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
