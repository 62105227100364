import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import MailChangeDialog from '@/components/ui/MailChangeDialog'
import PasswordRecoveryDialog from '@/components/ui/PasswordRecoveryDialog'
import { PhoneInput } from '@/components/ui/phone-input'
import { Separator } from '@/components/ui/separator'
import { ISchemaUser, IUser, userSchema } from '@/types/users.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil } from 'react-icons/tb'
import { useUpdateMe } from '../../hooks/useUpdateMe'

interface IAccountTabProps {
  me: IUser
}

const AccountTab: FC<IAccountTabProps> = (props: IAccountTabProps) => {
  const form = useForm<
    Pick<ISchemaUser, 'username' | 'phone' | 'email' | 'password'>
  >({
    resolver: yupResolver(
      userSchema.pick(['username', 'phone', 'email', 'password'])
    )
  })

  const { mutate: submit } = useUpdateMe()

  useEffect(() => form.reset(props.me), [props.me])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(data => submit(data as IUser))}>
        <Card>
          <CardHeader>
            <CardTitle>Account</CardTitle>
            <CardDescription>
              Make changes to your account here.
            </CardDescription>
            <Separator />
          </CardHeader>
          <CardContent className='flex flex-col gap-4'>
            <FormField
              control={form.control}
              name='username'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Username *</FormLabel>
                  <FormControl>
                    <Input className='max-w-80 h-8' {...field} />
                  </FormControl>
                  <FormDescription>
                    Your username is what you are using to log in.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='phone'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <PhoneInput
                      className='max-w-80'
                      defaultCountry='US'
                      {...field}
                    />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email *</FormLabel>
                  <FormControl>
                    <div className='flex flex-row gap-2 max-w-80'>
                      <Input disabled className='max-w-80 h-8' {...field} />
                      <MailChangeDialog id='me' />
                    </div>
                  </FormControl>
                  <FormDescription>
                    Your email address. It's used for account notifications and
                    password recovery.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <div className='flex flex-row gap-2 max-w-80'>
                      <Input
                        type='password'
                        disabled
                        className='max-w-60 h-8'
                        {...field}
                        value='*************'
                      />
                      <PasswordRecoveryDialog mail={props.me.email} />
                    </div>
                  </FormControl>
                  <FormDescription>
                    Your account password. Press <b>Change</b> to update it,
                    using unique and strong passwords is recommended.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter>
            <Button className='w-64 h-8' type='submit'>
              <TbPencil className='mr-1' />
              Save changes
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}
export default AccountTab
