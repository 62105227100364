import reportsService from '@/services/reports.service'
import { TripsStatsPeriod } from '@/types/reports.type'
import { useQuery } from '@tanstack/react-query'

export const useTripsStats = (period: TripsStatsPeriod) =>
  useQuery({
    queryKey: [`tripStats`, period],
    queryFn: () => reportsService.getTripsStats(new Date(), period),
    select: response => {
      return response.data.map(stat => ({
        ...stat,
        date: new Date(stat.date).toISOString()
      }))
    }
  })
