import { yupTransformer } from '@/lib/yup'
import { date, object, ObjectSchema } from 'yup'

export interface ITimeRange {
  start: Date
  end: Date
}

export const timeRangeSchema: ObjectSchema<ITimeRange> = object({
  start: date().required('Start time is required.'),
  end: date().required('End time is required.')
}).transform(yupTransformer)

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export enum Mobility {
  Ambulatory = 'Ambulatory',
  Lift = 'Lift',
  Stretcher = 'Stretcher',
  Wheelchair = 'Wheelchair'
}

export interface IAPIError {
  error: string
  message: string
  statusCode: number
}
