import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { useBrokersSearch } from '@/hooks/queries/useBrokersSearch'
import { cn } from '@/lib/utils'
import { IBroker } from '@/types/brokers.type'
import { FC, useState } from 'react'
import { TbCheck, TbSearch } from 'react-icons/tb'
import Spinner from '../Spinner'

interface IBrokerInputProps {
  defaultValue?: IBroker
  title?: string
  id?: string
  required?: boolean
  fieldClassName?: string
  popoverClassName?: string
  onChange?: (value: IBroker | undefined) => any
}

const BrokerInput: FC<IBrokerInputProps> = (props: IBrokerInputProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')

  const [value, setValue] = useState<IBroker | undefined>(props.defaultValue)
  const search = useBrokersSearch(input)

  const handleSelect = (broker: IBroker | undefined) => {
    props.onChange?.(broker)
    setValue(broker)
    setOpen(false)
  }

  return (
    <div className='flex flex-col gap-2'>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            className={cn('h-8 w-64 justify-between', props.fieldClassName)}
            aria-expanded={open}
          >
            <span className='overflow-hidden whitespace-nowrap text-ellipsis'>
              {value ? value.name : 'Search for brokers...'}
            </span>
            <TbSearch />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command shouldFilter={false}>
            <CommandInput
              placeholder='Search for brokers...'
              value={input}
              onValueChange={setInput}
            />
            <CommandList>
              <CommandGroup>
                {search.isLoading ? (
                  <div className='flex justify-center my-2'>
                    <Spinner />
                  </div>
                ) : (
                  <CommandEmpty className='flex h-8 place-items-center justify-center'>
                    <span className='text-sm'>No results found.</span>
                  </CommandEmpty>
                )}
                <CommandItem onSelect={() => handleSelect(undefined)}>
                  <span className='ml-6'>Search for brokers...</span>
                </CommandItem>
                {search.data?.map(broker => (
                  <CommandItem
                    key={broker.id}
                    onSelect={() => handleSelect(broker)}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${value?.id === broker.id ? 'opacity-100' : 'opacity-0'}`}
                    />
                    <span>{broker.name}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default BrokerInput
