import { toast } from '@/components/ui/use-toast'
import usersService from '@/services/users.service'
import { IAPIError } from '@/types/common.type'
import { IUser } from '@/types/users.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUpdateAvatar = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ user, file }: { user: IUser; file: File }) => {
      await usersService.updateAvatar(user.id, file)
      return user
    },
    onSuccess: async user => {
      queryClient.refetchQueries({
        queryKey: ['avatar', user.id]
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
