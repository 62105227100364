import ridersService from '@/services/riders.service'
import { useQuery } from '@tanstack/react-query'

export const useRider = (riderId: number) =>
  useQuery({
    enabled: !isNaN(riderId),
    retry: 1,
    queryKey: ['rider', riderId],
    queryFn: async () => await ridersService.getById(riderId),
    select: response => response.data
  })
