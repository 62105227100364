import { toast } from '@/components/ui/use-toast'
import { formatName } from '@/lib/utils'
import driverService from '@/services/drivers.service'
import { IAPIError } from '@/types/common.type'
import { IDriver } from '@/types/drivers.type'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useUpdateDriver = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (data: IDriver) => {
      toast({
        title: 'Updating...',
        description: `Updating driver #${data.id}.`
      })

      return await driverService.update(data.id, data)
    },
    onSuccess: ({ data }) => {
      navigate(-1)

      toast({
        title: 'Updated!',
        description: `${formatName(data.user)} (${data.id}) was updated successfully.`
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
