import { toast } from '@/components/ui/use-toast'
import companyService from '@/services/company.service'
import { IAPIError } from '@/types/common.type'
import { ICompanySettings } from '@/types/company.type'
import { useMutation } from '@tanstack/react-query'

export const useUpdateCompanySettings = () => {
  return useMutation({
    mutationFn: async (data: ICompanySettings) => {
      return await companyService.updateSettings(data)
    },
    onSuccess: ({ data }) => {
      toast({
        title: 'Changes have been saved!',
        description: 'Company was updated successfully.'
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
