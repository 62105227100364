import { Badge } from '@/components/ui/badge'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card'
import { cn, formatAddress } from '@/lib/utils'
import { Mobility } from '@/types/common.type'
import { IVehicle } from '@/types/vehicles.type'
import { FC } from 'react'
import { Link } from 'react-router-dom'

interface IDriverPreviewProps {
  value: IVehicle
  className?: string
}

const VehiclePreview: FC<IDriverPreviewProps> = (
  props: IDriverPreviewProps
) => {
  return (
    <HoverCard>
      <HoverCardTrigger className={cn('w-max', props.className)} asChild>
        {props.value.name && (
          <span>
            {props.value.name} - {props.value.make} {props.value.model} (
            {props.value.year})
          </span>
        )}
      </HoverCardTrigger>
      <HoverCardContent className='flex flex-col gap-4 min-w-max'>
        <div className='flex flex-row place-items-center gap-4'>
          <Link to={`/vehicles/${props.value.id}`}>
            {props.value.name} - {props.value.make} {props.value.model} (
            {props.value.year})
          </Link>
          <Badge className='h-max'>{props.value.vehicleType}</Badge>
        </div>
        <span className='text-sm text-muted-foreground'>
          Garaging Site: {formatAddress(props.value.garagingSite)}
        </span>
        <span className='text-sm text-muted-foreground'>
          Ambulatory Capacity: {props.value.ambulatoryCapacity}
        </span>
        {props.value.vehicleType !== Mobility.Ambulatory && (
          <span className='text-sm text-muted-foreground'>
            {String(props.value.vehicleType)} Capacity:{' '}
            {props.value.mobilityCapacity}
          </span>
        )}
        {props.value.licensePlateNumber && (
          <span className='text-sm text-muted-foreground'>
            License Plate: {props.value.licensePlateNumber}
          </span>
        )}
      </HoverCardContent>
    </HoverCard>
  )
}
export default VehiclePreview
