import adminsService from '@/services/admins.service'
import { useQuery } from '@tanstack/react-query'

export const useAdmin = (adminId: number) =>
  useQuery({
    enabled: !isNaN(adminId),
    retry: 1,
    queryKey: ['admin', adminId],
    queryFn: async () => await adminsService.getById(adminId),
    select: response => response.data
  })
