import { useToast } from '@/components/ui/use-toast'
import companyService from '@/services/company.service'
import { IAPIError } from '@/types/common.type'
import { IImportProperties } from '@/types/import.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreatePreset = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      name,
      data
    }: {
      name: string
      data: IImportProperties
    }) => {
      await companyService.createPreset(name, data)
      return name
    },
    onSuccess: name => {
      toast({
        title: 'Created!',
        description: `Preset ${name} was created successfully.`
      })

      queryClient.invalidateQueries({ queryKey: ['presets'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
