import { isValidPhoneNumber } from 'react-phone-number-input'
import { TestConfig } from 'yup'

export const yupTransformer = (value: any) => {
  return Object.fromEntries(
    Object.entries(value).map(([key, value]) => [
      key,
      value === null ? undefined : value
    ])
  )
}

export const phoneTest = (): TestConfig => ({
  name: 'is-valid-phone-number',
  message: 'Invalid phone number.',
  test: (value: any) => !value || isValidPhoneNumber(value)
})
