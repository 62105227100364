import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import { IDriver, ISchemaDriver } from '@/types/drivers.type'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

interface ILicenseTabProps {
  driver?: IDriver
  form: UseFormReturn<ISchemaDriver, any, undefined>
}

const LicenseTab: FC<ILicenseTabProps> = (props: ILicenseTabProps) => {
  return (
    <div className='flex flex-col gap-4'>
      <FormField
        control={props.form.control}
        name='licenseNumber'
        render={({ field }) => (
          <FormItem>
            <FormLabel>License Number</FormLabel>
            <FormControl>
              <Input className='w-64 h-8' {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='licenseType'
        render={({ field }) => (
          <FormItem>
            <FormLabel>License Type</FormLabel>
            <FormControl>
              <Input className='w-64 h-8' {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='licenseState'
        render={({ field }) => (
          <FormItem>
            <FormLabel>License State</FormLabel>
            <FormControl>
              <Input className='w-64 h-8' {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.form.control}
        name='licenseExpires'
        render={({ field }) => (
          <FormItem>
            <FormLabel>License Expires</FormLabel>
            <FormControl>
              <DateInput type='date' className='w-64 h-8' {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
export default LicenseTab
