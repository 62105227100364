import { FC } from 'react'

const AssignedTripsEmpty: FC = () => {
  return (
    <div className='w-full h-full flex justify-center place-items-center border border-dashed rounded-md'>
      <span className='text-muted-foreground text-sm'>
        Select a shift to view assigned trips.
      </span>
    </div>
  )
}
export default AssignedTripsEmpty

