import { IUser } from '@/types/users.type'
import { api } from './axios.interceptor'

type NumberOrMe = 'me' | number

class UsersService {
  async isUsernameExists(username: string) {
    const response = await api.get<boolean>(
      `/users/usernameInUsage/${username}`
    )
    return response
  }

  async isEmailExists(email: string) {
    const response = await api.get<boolean>(`/users/usernameInUsage/${email}`)
    return response
  }

  async getMe() {
    const response = await api.get<IUser>('/users/me')
    return response
  }

  async getAvatarURL(id: NumberOrMe) {
    const response = await api.get(`/users/${id}/avatar`)
    return response
  }

  async updateMe(data: IUser) {
    const response = await api.patch<IUser>('/users/me', data)
    return response
  }

  async changePassword(
    id: number | 'me',
    request: {
      previousPassword?: string
      updatedPassword: string
    }
  ) {
    const response = await api.patch(`/users/${id}/changePassword`, request)
    return response
  }

  async reinvite(id: number) {
    const response = await api.post(`/users/${id}/reinvite`)
    return response
  }

  async requestEmailChange(id: number | 'me', email: string) {
    const response = await api.post(`/users/${id}/change-email`, {
      email: email
    })

    return response
  }

  async changeEmail(token: string) {
    const response = await api.patch(`/users/change-email`, {
      token: token
    })

    return response
  }

  async updateAvatar(id: NumberOrMe, file: File) {
    const formData = new FormData()
    formData.append('file', file)

    const response = await api.put(`/users/${id}/avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response
  }
  async delete(id: number) {
    const response = await api.delete<IUser>(`/users/${id}`)
    return response
  }
}

const usersService = new UsersService()

export default usersService
