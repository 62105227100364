import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import Spinner from '@/components/ui/Spinner'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useToast } from '@/components/ui/use-toast'
import { useDriver } from '@/hooks/queries/useDriver'
import { driverSchema, IDriver, ISchemaDriver } from '@/types/drivers.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbPencil } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpdateDriver } from '../hooks/useUpdateDriver'
import AccountTab from './AccountTab'
import LicenseTab from './LicenseTab'
import MiscellaneousTab from './MiscellaneousTab'

enum Tab {
  Account = 'account',
  License = 'license',
  Miscellaneous = 'miscellaneous'
}

const DriverForm: FC = () => {
  const { driverId } = useParams()
  const { toast } = useToast()

  const navigate = useNavigate()

  const form = useForm<ISchemaDriver>({
    resolver: yupResolver(driverSchema),
    defaultValues: {}
  })

  const driver = useDriver(Number(driverId))

  useEffect(() => form.reset(driver.data), [driver.data])

  useEffect(() => {
    if (isNaN(Number(driverId)) || (driver.isFetched && !driver.data)) {
      navigate('/drivers')
      toast({
        title: 'Error!',
        description: `Driver ${driverId} was not found. If you want to create a new driver, use "Invite" button.`,
        variant: 'destructive'
      })
    }
  }, [driver])

  const { mutate: submit } = useUpdateDriver()

  return (
    <Form {...form}>
      {driver.isLoading ? (
        <Spinner />
      ) : (
        <form
          className='flex flex-col gap-4'
          onSubmit={form.handleSubmit(data => submit(data as IDriver))}
        >
          <Tabs defaultValue={Tab.Account}>
            <TabsList className='flex flex-row w-full justify-start'>
              <TabsTrigger value={Tab.Account}>Account</TabsTrigger>
              <TabsTrigger value={Tab.License}>License</TabsTrigger>
              <TabsTrigger value={Tab.Miscellaneous}>Miscellaneous</TabsTrigger>
            </TabsList>
            <TabsContent value={Tab.Account}>
              <AccountTab driver={driver.data as IDriver} form={form} />
            </TabsContent>
            <TabsContent value={Tab.License}>
              <LicenseTab driver={driver.data as IDriver} form={form} />
            </TabsContent>
            <TabsContent value={Tab.Miscellaneous}>
              <MiscellaneousTab driver={driver.data as IDriver} form={form} />
            </TabsContent>
          </Tabs>
          <Button className='w-64 h-8' type='submit'>
            <TbPencil className='mr-1' />
            Save changes
          </Button>
        </form>
      )}
    </Form>
  )
}

export default DriverForm
