import { IAddress } from '@/types/address.type'
import { IDriver } from '@/types/drivers.type'
import { createContext } from 'react'

interface ILiveMapContext {
  latestGeolocations: {
    driver: IDriver
    location: IAddress
    timestamp: Date
  }[]
  hiddenDrivers: number[]
  setHiddenDrivers: (value: number[]) => void
}

const LiveMapContext = createContext<ILiveMapContext>({} as ILiveMapContext)

export default LiveMapContext
