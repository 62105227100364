import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import Combobox from '@/components/ui/combobox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import TextInput from '@/components/ui/inputs/TextInput'
import Spinner from '@/components/ui/Spinner'
import { useCreatePreset } from '@/hooks/mutations/useCreatePreset'
import { usePresets } from '@/hooks/queries/usePresets'
import { IImportProperties } from '@/types/import.type'
import { FC, useContext, useState } from 'react'
import { TbDeviceFloppy } from 'react-icons/tb'
import ImportContext from '../ImportContext'

const PresetToolbox: FC = () => {
  const presets = usePresets()
  const context = useContext(ImportContext)

  const { mutate: createPreset } = useCreatePreset()

  const [name, setName] = useState<string>('')

  const [presetWarningValue, setPresetWarningValue] =
    useState<IImportProperties>({} as IImportProperties)
  const [presetWarningOpen, setPresetWarningOpen] = useState<boolean>(false)

  return (
    <div className='flex flex-row place-items-end gap-2'>
      <AlertDialog open={presetWarningOpen} onOpenChange={setPresetWarningOpen}>
        <AlertDialogContent>
          <AlertDialogTrigger asChild></AlertDialogTrigger>
          <AlertDialogHeader>
            <AlertDialogTitle>Preset</AlertDialogTitle>
            <AlertDialogDescription>
              You are trying to replace properties with preset. This action will
              replace existing properties. Are you sure?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() =>
                context.form.setValue('properties', {
                  ...context.form.getValues('properties'),
                  ...presetWarningValue
                })
              }
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {presets.data ? (
        <Combobox
          options={Object.keys(presets.data)}
          onChange={value => {
            if (value) {
              setPresetWarningOpen(true)
              setPresetWarningValue(presets.data[value])
            }
          }}
          title='Preset'
        />
      ) : (
        <Spinner />
      )}
      <Dialog>
        <DialogTrigger asChild>
          <Button
            title='Save preset'
            variant='outline'
            size='icon'
            className='h-8 w-8'
          >
            <TbDeviceFloppy />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Preset</DialogTitle>
            <DialogDescription>
              Save your current properties to preset so you can use it later.
            </DialogDescription>
          </DialogHeader>
          <TextInput
            title='Name'
            value={name}
            onChange={setName}
            className='w-full'
          />
          {presets.data?.[name] && (
            <span className='text-red-900/50 dark:text-red-300/50 text-xs select-none'>
              Preset with this name already exists. Saving will replace it.
            </span>
          )}
          <DialogFooter className='flex flex-row gap-2 place-items-end'>
            <DialogClose asChild>
              <Button
                onClick={() =>
                  createPreset({
                    name: name,
                    data: context.form.getValues('properties')
                  })
                }
                disabled={!name}
              >
                Save
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default PresetToolbox
