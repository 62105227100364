import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import tripsService from '@/services/trips.service'
import { IAPIError } from '@/types/common.type'
import { DialogProps } from '@radix-ui/react-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FC, useContext, useState } from 'react'
import ImportContext from './ImportContext'
import ImportTaskRow from './ImportTaskRow'

export interface IImportTaskProps extends DialogProps {
  disabled?: boolean
}

const ImportTask: FC<IImportTaskProps> = (props: IImportTaskProps) => {
  const context = useContext(ImportContext)

  const [task, setTask] = useState<{
    id: string
    finished: boolean
  }>()

  const taskMutation = useMutation({
    mutationFn: async () => {
      return await tripsService.startImport(context.form.getValues())
    },
    onSuccess: response => {
      setTask({
        id: response.data,
        finished: false
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })

  const taskQuery = useQuery({
    queryFn: async () => {
      if (task) {
        const response = await tripsService.getImport(task.id)

        if (response.data.finished)
          setTask({
            id: task.id,
            finished: true
          })

        return response
      }
    },
    enabled: taskMutation.isSuccess && !task?.finished,
    select: response => response?.data,
    refetchInterval: 300,
    queryKey: ['importTask']
  })

  return (
    <>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button disabled={props.disabled}>Import</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              You want to import{' '}
              {context.form.watch('data')?.split('\n').length} lines with
              {context.form.watch('autoSchedule') ? '' : 'out'} autoscheduling
              {!context.form.watch('autoSchedule') &&
                ' (you can autoschedule later)'}
              .
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => taskMutation.mutate()}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog open={!!task} {...props}>
        <AlertDialogContent className='flex flex-col min-w-[50%] min-h-[60%] max-h-[60%]'>
          <AlertDialogHeader>
            <AlertDialogTitle>Importing...</AlertDialogTitle>
            <AlertDialogDescription>
              Your file is currently being imported. This may take a few
              moments. Please be patient as we process your data. Once the
              import is complete, press "Continue" button to finish.
              <br />
              <br />
              Task ID: {task?.id}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <div className='flex flex-col gap-4 overflow-y-auto'>
            {taskQuery.data?.rows.map(row => (
              <ImportTaskRow key={`row${row.index}`} value={row} />
            ))}
          </div>
          <AlertDialogFooter className='absolute bottom-4 right-4'>
            <AlertDialogAction
              onClick={() => window.location.reload()}
              disabled={!task?.finished}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
export default ImportTask
