import { IShift } from '@/types/shifts.type'
import { ITrip } from '@/types/trips.type'
import { createContext } from 'react'

interface IDispatchPageContext {
  date: Date
  selectedShift?: IShift
  setSelectedShift: (value: IShift | undefined) => void
  shifts?: IShift[]
  trips?: ITrip[]
}

const DispatchPageContext = createContext<IDispatchPageContext>(
  {} as IDispatchPageContext
)

export default DispatchPageContext
