import { toast } from '@/components/ui/use-toast'
import { formatName } from '@/lib/utils'
import driverService from '@/services/drivers.service'
import { IAPIError } from '@/types/common.type'
import { IDriver } from '@/types/drivers.type'
import { useMutation } from '@tanstack/react-query'

export const useCreateDriver = () => {
  return useMutation({
    mutationFn: async (data: IDriver) => {
      toast({
        title: 'Creating...',
        description: 'Creating new driver.'
      })

      return await driverService.create(data)
    },
    onSuccess: ({ data }) => {
      toast({
        title: 'Invited!',
        description: `${formatName(data.user)} (${data.user.email}) was invited to your company. Invitation link was sent to driver's email.`
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
