import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { emailToMailService } from '@/lib/utils'
import usersService from '@/services/users.service'
import { IAPIError } from '@/types/common.type'
import { useMutation } from '@tanstack/react-query'
import { TbMail } from 'react-icons/tb'

export const useRequestMailChange = () => {
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      id,
      newEmail
    }: {
      id: number | 'me'
      newEmail: string
    }) => {
      await usersService.requestEmailChange(id, newEmail)

      return {
        id: id,
        newEmail: newEmail
      }
    },
    onSuccess: ({ id, newEmail }: { id: number | 'me'; newEmail: string }) => {
      const mailService = emailToMailService(newEmail)

      toast({
        title: 'Success!',
        description: (
          <div className='flex flex-col gap-2'>
            <p>An email change request has been sent to {newEmail}.</p>
            {mailService && id === 'me' && (
              <a href={mailService.url} target='_blank'>
                <Button className='w-40 h-8' variant='outline' type='button'>
                  <TbMail className='h-4 w-4 mr-1' />
                  Open {mailService.name}
                </Button>
              </a>
            )}
          </div>
        ),
        duration: 3000
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive',
        duration: 5000
      })
    }
  })
}
