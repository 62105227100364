import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel
} from '@/components/ui/context-menu'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { formatName } from '@/lib/utils'
import { IRider } from '@/types/riders.type'
import { FC } from 'react'
import { TbDots } from 'react-icons/tb'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'

const RidersTable: FC<ICustomizableTableProps<IRider>> = (
  props: ICustomizableTableProps<IRider>
) => {
  const navigate = useNavigate()

  return (
    <Table
      {...props}
      initialState={{
        sorting: [
          {
            desc: false,
            id: 'name'
          }
        ]
      }}
      contextMenu={(rider: IRider) => (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem onClick={() => navigate(`/riders/${rider.id}`)}>
            Edit
          </ContextMenuItem>
        </ContextMenuContent>
      )}
      columns={[
        {
          id: 'name',
          header: 'Name',
          filterFn: (row, _, value) => {
            if (!value) return true

            return formatName(row.original)
              .toLowerCase()
              .includes(value.toLowerCase())
          },
          accessorFn: data => data,
          cell: props => <span>{formatName(props.getValue())}</span>
        },
        {
          accessorKey: 'email',
          header: 'Email',
          cell: props => <span>{props.getValue()}</span>
        },
        {
          accessorKey: 'phone',
          header: 'Phone Number',
          filterFn: 'includesString',
          cell: props => (
            <span>
              {formatPhoneNumberIntl(props.getValue()) || props.getValue()}
            </span>
          )
        },
        {
          accessorKey: 'socialSecurityNumber',
          header: 'Social Security Number',
          cell: props => <span>{props.getValue()}</span>
        },
        {
          accessorKey: 'notes',
          header: 'Notes',
          cell: props => <span>{props.getValue()}</span>
        },
        {
          id: 'actions',
                    size: 100,
                    cell: props => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='ghost' className='h-8 w-8 p-0'>
                  <TbDots className='h-4 w-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                onClick={event => event.stopPropagation()}
                align='end'
              >
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() => navigate(`/riders/${props.row.original.id}`)}
                >
                  Edit
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      ]}
    />
  )
}

export default RidersTable
