import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { useCalled } from '@/hooks/mutations/useCalled'
import { useSetScheduleStatus } from '@/hooks/mutations/usetSetScheduleStatus'
import { useUncombineTrip } from '@/hooks/mutations/useUncombineTrip'
import { formatScheduleStatus } from '@/lib/utils'
import { ISchedule, ITrip, ScheduleStatus } from '@/types/trips.type'
import { CellContext } from '@tanstack/react-table'
import { FC, useState } from 'react'
import {
  TbAnchor,
  TbDots,
  TbPencil,
  TbPhone,
  TbUnlink,
  TbUser
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

const TripActions: FC<CellContext<any, any>> = (
  props: CellContext<any, any>
) => {
  const navigate = useNavigate()

  const { mutate: setStatus } = useSetScheduleStatus()
  const { mutate: setCalled } = useCalled()
  const { mutate: uncombine } = useUncombineTrip()

  const [dialogStatus, setDialogStatus] = useState<undefined | ScheduleStatus>()

  const parent = props.row.getParentRow()?.original as ITrip
  const original = props.row.original as ISchedule

  const handleStatusChange = (status: ScheduleStatus) => {
    if (status === ScheduleStatus.Canceled || status === ScheduleStatus.NoShow)
      return setDialogStatus(status)

    return setStatus({
      schedule: original,
      status: status
    })
  }

  return (
    <>
      <Dialog
        onOpenChange={() => setDialogStatus(undefined)}
        open={!!dialogStatus}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Change status to {formatScheduleStatus(dialogStatus!)}
            </DialogTitle>
            <DialogDescription>
              You can update the status of this trip only for this trip or for
              all "Assigned" or "Unassigned" trips of this rider for selected
              day. You can change status later.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              onClick={() => {
                setStatus({
                  schedule: original,
                  forAllRiderTrips: true,
                  status: dialogStatus!
                })
                setDialogStatus(undefined)
              }}
              variant='destructive'
            >
              All trips of this rider
            </Button>
            <Button
              onClick={() => {
                setStatus({
                  schedule: original,
                  forAllRiderTrips: false,
                  status: dialogStatus!
                })
                setDialogStatus(undefined)
              }}
            >
              This trip only
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {parent ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant='ghost'
              className='h-8 w-8 p-0'
              onClick={event => event.stopPropagation()}
            >
              <TbDots className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => navigate(`/trips/${parent.id}`)}>
              <TbPencil className='mr-2 h-4 w-4' />
              Edit Trip
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => navigate(`/drivers/${parent.shift?.driverId}`)}
              disabled={!!!parent.shift}
            >
              <TbUser className='mr-2 h-4 w-4' />
              Edit Driver
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => navigate(`/riders/${original.riderId}`)}
            >
              <TbAnchor className='mr-2 h-4 w-4' />
              Edit Rider
            </DropdownMenuItem>
            {original.willCall && (
              <DropdownMenuItem onClick={() => setCalled(original)}>
                <TbPhone className='mr-2 h-4 w-4' />
                Person Called
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>Status</DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                <DropdownMenuRadioGroup
                  onValueChange={value =>
                    handleStatusChange(value as ScheduleStatus)
                  }
                  value={original.status}
                >
                  {parent.shift ? (
                    <DropdownMenuRadioItem value={ScheduleStatus.Assigned}>
                      Assigned
                    </DropdownMenuRadioItem>
                  ) : (
                    <DropdownMenuRadioItem value={ScheduleStatus.Unassigned}>
                      Unassigned
                    </DropdownMenuRadioItem>
                  )}
                  <DropdownMenuRadioItem value={ScheduleStatus.NoShow}>
                    No Show
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.ArrivedAtDO}>
                    Arrived (DO)
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.ArrivedAtPU}>
                    Arrived (PU)
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.Canceled}>
                    Canceled
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.Completed}>
                    Completed
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.InRoute}>
                    In Route
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.InTransit}>
                    In Transit
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant='ghost'
              className='h-8 w-8 p-0'
              onClick={event => event.stopPropagation()}
            >
              <TbDots className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => uncombine(original.id)}>
              <TbUnlink className='mr-2 h-4 w-4' />
              Uncombine
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}
export default TripActions
