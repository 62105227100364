import { Badge } from '@/components/ui/badge'
import { IImportRow } from '@/types/import.type'
import { FC } from 'react'

interface IRowStatusProps {
  value: IImportRow
}

const statuses = {
  Success: 'bg-green-600 dark:text-primary',
  Importing: 'bg-yellow-500 dark:text-primary',
  Failed: 'bg-red-500 dark:text-primary'
}

const ImportTaskRow: FC<IRowStatusProps> = (props: IRowStatusProps) => {
  return (
    <div className='w-full min-h-fit flex flex-row gap-4 justify-between overflow-hidden'>
      <div className='flex flex-row w-full gap-4 place-items-center'>
        <span className='text-lg min-w-10 text-center'>
          {props.value.index}
        </span>
        <span className='text-xs'>{props.value.row}</span>
      </div>
      <Badge
        className={`${statuses[props.value.status]} h-8 w-20 whitespace-nowrap flex justify-center`}
      >
        {props.value.status}
      </Badge>
    </div>
  )
}
export default ImportTaskRow
