import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import BrokerInput from '@/components/ui/inputs/BrokerInput'
import FileInput from '@/components/ui/inputs/FileInput'
import Spinner from '@/components/ui/Spinner'
import { Switch } from '@/components/ui/switch'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useToast } from '@/components/ui/use-toast'
import { parseAsync } from '@/lib/utils'
import { HeaderType, IImport } from '@/types/import.type'
import { DialogProps } from '@radix-ui/react-dialog'
import { FC, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TbFileImport, TbX } from 'react-icons/tb'
import ImportContext from './ImportContext'
import ImportTask from './ImportTask'
import ImportProperties from './properties/ImportProperties'

enum Tab {
  File = 0,
  Setup = 1
}

enum ParsingState {
  None,
  Loading,
  Failed,
  Success
}

const ImportDialog: FC<DialogProps> = (props: DialogProps) => {
  const { toast } = useToast()

  const [open, setOpen] = useState<boolean>(props.defaultOpen || false)

  const [tab, setTab] = useState<Tab>(Tab.File)
  const [parsingState, setParsingState] = useState<ParsingState>(
    ParsingState.None
  )
  const [parsedHeaders, setParsedHeaders] = useState<string[]>([])

  const form = useForm<IImport>({
    defaultValues: {
      data: '',
      properties: {
        firstName: {
          headers: [],
          type: HeaderType.Get
        },
        lastName: {
          headers: [],
          type: HeaderType.Get
        },
        phone: {
          headers: [],
          type: HeaderType.Get
        },
        from: {
          headers: [],
          type: HeaderType.Get
        },
        to: {
          headers: [],
          type: HeaderType.Get
        },
        guests: {
          headers: [],
          type: HeaderType.Get
        },
        comments: {
          headers: [],
          type: HeaderType.Get
        },
        mobility: {
          headers: [],
          type: HeaderType.Get
        },
        date: {
          headers: [],
          type: HeaderType.Get
        },
        pickupTime: {
          headers: [],
          type: HeaderType.Get
        },
        dropoffTime: {
          headers: [],
          type: HeaderType.Get
        },
        tripNumber: {
          headers: [],
          type: HeaderType.Get
        },
        dateOfBirth: {
          headers: [],
          type: HeaderType.Get
        },
        weight: {
          headers: [],
          type: HeaderType.Get
        }
      }
    }
  })

  const handleFileInputChange = (value: FileList | null) => {
    setParsingState(ParsingState.Loading)

    const file = value?.item(0)

    if (!file) return setParsingState(ParsingState.Failed)

    const reader = new FileReader()

    reader.readAsText(file)

    reader.onloadstart = () => {
      setParsingState(ParsingState.Loading)
    }

    reader.onloadend = async () => {
      if (!reader.result) return setParsingState(ParsingState.Failed)

      await parseAsync<any>(reader.result as string, {
        header: true,
        transformHeader: header => {
          return header.replaceAll(/[^a-zA-Z0-9]/g, '').toLowerCase()
        },
        dynamicTyping: true
      })
        .then(parsed => {
          if (!parsed.meta.fields || parsed.meta.fields.length === 0) {
            setParsingState(ParsingState.Failed)

            return toast({
              title: 'Unable to import your CSV file',
              description: `Your file was imported, but we detected that it has no headers. Please ensure that the file is correct and try again.`,
              variant: 'destructive',
              duration: 10000
            })
          }

          form.setValue('data', reader.result as string)
          setParsedHeaders(parsed.meta.fields)
          setParsingState(ParsingState.Success)
        })
        .catch(err => {
          setParsingState(ParsingState.Failed)

          toast({
            title: 'Unable to import your CSV file',
            description: `An error occurred while attempting to import the CSV file. Please ensure that the file is in the correct format and try again.\n\nError: ${err}`,
            variant: 'destructive',
            duration: 10000
          })
        })
    }
  }

  const isAvailableForImport = useMemo<boolean>(
    () =>
      Object.values(form.getValues('properties')).some(
        value => value.headers?.length > 0
      ),
    [form.watch('properties')]
  )

  return (
    <Form {...form}>
      <Dialog {...props} open={open} onOpenChange={setOpen}>
        <ImportContext.Provider
          value={{
            form: form,
            headers: parsedHeaders,
            isAvailableForImport: isAvailableForImport
          }}
        >
          <DialogTrigger asChild>
            <Button variant='outline' className='h-8'>
              <TbFileImport className='h-4 w-4 mr-2' />
              Import
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Import</DialogTitle>
              <DialogDescription>
                Import .csv file with Trips data.
              </DialogDescription>
            </DialogHeader>
            <Tabs
              value={String(tab)}
              onValueChange={value => setTab(Number(value))}
            >
              <TabsList className='w-full'>
                <TabsTrigger value={String(Tab.File)} disabled={tab < Tab.File}>
                  Import
                </TabsTrigger>
                <TabsTrigger
                  value={String(Tab.Setup)}
                  disabled={tab < Tab.Setup}
                >
                  Setup
                </TabsTrigger>
              </TabsList>
              <TabsContent
                value={String(Tab.File)}
                className='flex flex-col gap-4'
              >
                <FormField
                  control={form.control}
                  name='data'
                  render={() => (
                    <FormItem>
                      <FormLabel>File (.csv) *</FormLabel>
                      <FormControl>
                        <FileInput
                          required
                          onChange={event =>
                            handleFileInputChange(event.target.files)
                          }
                          accept='.csv'
                          className='relative'
                        >
                          {parsingState === ParsingState.Loading && (
                            <Spinner className='h-5 w-5 absolute right-4 top-2' />
                          )}
                          {parsingState === ParsingState.Failed && (
                            <TbX className='h-5 w-5 absolute right-4 top-2' />
                          )}
                        </FileInput>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <DialogFooter>
                  <Button
                    disabled={parsedHeaders.length === 0}
                    onClick={() => setTab(tab + 1)}
                  >
                    Next
                  </Button>
                </DialogFooter>
              </TabsContent>
              <TabsContent
                value={String(Tab.Setup)}
                className='flex flex-col gap-4'
              >
                <FormField
                  control={form.control}
                  name='brokerId'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Broker *</FormLabel>
                      <FormControl>
                        <BrokerInput
                          {...field}
                          required
                          fieldClassName='w-full'
                          popoverClassName='w-96'
                          onChange={broker => field.onChange(broker?.id)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <ImportProperties />
                <FormField
                  control={form.control}
                  name='autoSchedule'
                  render={({ field }) => (
                    <FormItem className='flex flex-row place-items-center border w-full p-4 pt-2 rounded-lg gap-4'>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div>
                        <FormLabel>Autoschedule</FormLabel>
                        <FormDescription>
                          After importing the file, the autoscheduling process
                          will be started for first date in the file.
                        </FormDescription>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <DialogFooter>
                  <ImportTask
                    onOpenChange={setOpen}
                    disabled={!form.watch('brokerId') || !isAvailableForImport}
                  />
                </DialogFooter>
              </TabsContent>
            </Tabs>
          </DialogContent>
        </ImportContext.Provider>
      </Dialog>
    </Form>
  )
}

export default ImportDialog
