import { formatAddress } from '@/lib/utils'
import { IDriver } from '@/types/drivers.type'
import { IRider } from '@/types/riders.type'
import { ISchedule, ITrip, ScheduleStatus } from '@/types/trips.type'
import { Row } from '@tanstack/react-table'

const selectSchedule = (row: Row<ITrip | ISchedule>) =>
  row.depth >= 1 ? (row as Row<ISchedule>) : (row.subRows[0] as Row<ISchedule>)

const selectTrip = (row: Row<ITrip | ISchedule>) => {
  return row.getParentRow()
    ? (row.getParentRow() as Row<ITrip>)
    : (row as Row<ITrip>)
}
export const sortTripTimes = (
  rowA: Row<any>,
  rowB: Row<any>,
  columnId: string
) => {
  const selectTime = (row: Row<ISchedule>) => {
    if (columnId === 'pickupTime')
      return row.original?.scheduledPickupTime || row.original?.pickupTime
    if (columnId === 'dropoffTime')
      return row.original?.scheduledDropoffTime || row.original?.dropoffTime

    return row.getValue<Date>(columnId)
  }

  const [scheduleA, scheduleB] = [selectSchedule(rowA), selectSchedule(rowB)]
  if (!scheduleA || !scheduleB) return 0

  const [willCallA, willCallB] = [
    scheduleA.original.willCall ?? false,
    scheduleB.original.willCall ?? false
  ]

  if (willCallA !== willCallB) {
    return willCallA ? 1 : -1
  }

  const [dateA, dateB] = [selectTime(scheduleA), selectTime(scheduleB)]

  return dateA.getTime?.() - dateB.getTime?.()
}

export const sortTripRiders = (
  rowA: Row<any>,
  rowB: Row<any>,
  columnId: string
) => {
  const nameA: string = selectSchedule(rowA)
    ?.getValue<IRider>(columnId)
    .firstName.toLowerCase()
  const nameB: string = selectSchedule(rowB)
    ?.getValue<IRider>(columnId)
    .firstName.toLowerCase()

  return nameA.localeCompare(nameB)
}

export const sortTripPlain = (
  rowA: Row<any>,
  rowB: Row<any>,
  columnId: string
) => {
  const columnA: string = selectSchedule(rowA).getValue(columnId)
  const columnB: string = selectSchedule(rowB).getValue(columnId)

  return columnA.localeCompare(columnB)
}

export const sortTripDrivers = (
  rowA: Row<any>,
  rowB: Row<any>,
  columnId: string
) => {
  const [driverA, driverB] = [
    selectTrip(rowA).getValue<IDriver>(columnId),
    selectTrip(rowB).getValue<IDriver>(columnId)
  ]

  if (!driverA || !driverB) return 0

  const nameA: string = driverA.user.firstName.toLowerCase()
  const nameB: string = driverB.user.firstName.toLowerCase()

  return nameA.localeCompare(nameB)
}

export const filterTripDriver = (
  row: Row<any>,
  columnId: string,
  filter: IDriver
) => filter && row.getValue<IDriver>(columnId).id === filter.id

export const filterTripRider = (
  row: Row<any>,
  columnId: string,
  filter: IRider
) =>
  filter &&
  row.originalSubRows!?.some(schedule => schedule[columnId].id === filter.id)

export const filterTripStatus = (
  row: Row<any>,
  columnId: string,
  filter: ScheduleStatus
) =>
  filter &&
  row.originalSubRows!?.some(schedule => schedule[columnId] === filter)

export const sortTripAddresses = (
  rowA: Row<any>,
  rowB: Row<any>,
  columnId: string
) => {
  const [scheduleA, scheduleB] = [selectSchedule(rowA), selectSchedule(rowB)]

  if (!scheduleA || !scheduleB) return 0

  const { from: fromA } = scheduleA.getValue(columnId) as ISchedule
  const { from: fromB } = scheduleB.getValue(columnId) as ISchedule

  return formatAddress(fromA).localeCompare(formatAddress(fromB))
}

export const filterTripAddress = (
  row: Row<any>,
  columnId: string,
  filter: string
) => {
  if (row.depth > 0) return true

  const subRows = row.subRows.some(schedule => {
    const { from, to } = schedule.getValue(columnId) as ISchedule

    return (
      formatAddress(from).toLowerCase().includes(filter.toLowerCase()) ||
      formatAddress(to).toLowerCase().includes(filter.toLowerCase())
    )
  })

  return !filter || subRows
}
