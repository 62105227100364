import { ISchedule } from '@/types/trips.type'
import { format } from 'date-fns'
import { FC } from 'react'
import { TbWand } from 'react-icons/tb'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../hover-card'

interface ITimePreviewProps {
  value: ISchedule
  className?: string
  time: 'pickup' | 'dropoff'
}

const TimePreview: FC<ITimePreviewProps> = (props: ITimePreviewProps) => {
  const otherElementsAmount =
    props.time === 'pickup'
      ? Number(!!props.value.scheduledPickupTime) +
        Number(!!props.value.pickupArrivalTime)
      : Number(!!props.value.scheduledDropoffTime) +
        Number(!!props.value.dropoffArrivalTime)

  const time =
    props.time === 'pickup' ? props.value.pickupTime : props.value.dropoffTime
  const scheduledTime =
    props.time === 'pickup'
      ? props.value.scheduledPickupTime
      : props.value.scheduledDropoffTime
  const arrivalTime =
    props.time === 'pickup'
      ? props.value.pickupArrivalTime
      : props.value.dropoffArrivalTime

  return props.value.willCall ? (
    'Will Call'
  ) : (
    <HoverCard>
      <HoverCardTrigger className={props.className}>
        <div className='flex flex-row place-items-center gap-2'>
          {scheduledTime
            ? format(scheduledTime, 'HH:mm')
            : time
              ? format(time, 'HH:mm')
              : ''}
          {scheduledTime && (
            <TbWand title='Time is autoscheduled.' className='h-4 w-4' />
          )}
        </div>
        {otherElementsAmount > 0 && (
          <span className='text-muted-foreground'>
            ({otherElementsAmount} more)
          </span>
        )}
      </HoverCardTrigger>
      <HoverCardContent className='flex flex-col gap-2 w-60'>
        <span>
          <b>{props.time === 'pickup' ? 'Pickup' : 'Dropoff'} Time:</b>{' '}
          {time ? format(time, 'MM.dd.yyyy HH:mm') : 'N/A'}
        </span>
        <span>
          <b>
            Scheduled {props.time === 'pickup' ? 'Pickup' : 'Dropoff'} Time:
          </b>{' '}
          {scheduledTime ? format(scheduledTime, 'MM.dd.yyyy HH:mm') : 'N/A'}
        </span>
        <span>
          <b>Arrival {props.time === 'pickup' ? 'Pickup' : 'Dropoff'} Time:</b>{' '}
          {arrivalTime ? format(arrivalTime, 'MM.dd.yyyy HH:mm') : 'N/A'}
        </span>
      </HoverCardContent>
    </HoverCard>
  )
}
export default TimePreview
