import { toast } from '@/components/ui/use-toast'
import { useAppSelector } from '@/hooks/useAppSelector'
import { setUser } from '@/reducers/userSlice'
import adminsService from '@/services/admins.service'
import { IAdmin } from '@/types/admins.type'
import { IAPIError } from '@/types/common.type'
import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const useUpdateAdmin = () => {
  const navigate = useNavigate()

  const me = useAppSelector(selector => selector.userState.user)
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: async (data: IAdmin) => {
      toast({
        title: 'Updating...',
        description: `Updating admin #${data.id}.`
      })

      return await adminsService.update(data.id, data)
    },
    onSuccess: async ({ data }) => {
      navigate(-1)

      if (data.userId === me?.id) dispatch(setUser(data.user))

      toast({
        title: 'Updated!',
        description: `${data.user.username} (#${data.id}) was updated successfully.`
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
