import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { useDeleteVehicle } from '@/hooks/mutations/useDeleteVehicle'
import { IVehicle } from '@/types/vehicles.type'
import { FC, ReactNode } from 'react'

interface IVehicleDeleteDialogProps {
  vehicle: IVehicle
  children: ReactNode
}

const VehicleDeleteDialog: FC<IVehicleDeleteDialogProps> = (
  props: IVehicleDeleteDialogProps
) => {
  const { mutate: deleteVehicle } = useDeleteVehicle()

  return (
    <AlertDialog>
      <AlertDialogTrigger onSelect={e => e.preventDefault()} asChild>
        {props.children}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete this
            vehicle.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => deleteVehicle(props.vehicle)}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default VehicleDeleteDialog
