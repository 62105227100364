import DriverPreview from '@/components/ui/previews/DriverPreview'
import VehiclePreview from '@/components/ui/previews/VehiclePreview'
import { IShift } from '@/types/shifts.type'
import { FC } from 'react'

interface IShiftPreviewProps {
  value: IShift
}

const ShiftPreview: FC<IShiftPreviewProps> = (props: IShiftPreviewProps) => {
  return (
    <div>
      <DriverPreview value={props.value.driver} /> (
      <VehiclePreview value={props.value.vehicle} />)
    </div>
  )
}
export default ShiftPreview

