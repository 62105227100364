import vehiclesService from '@/services/vehicles.service'
import { IVehicleSearch } from '@/types/shifts.type'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'use-debounce'

export const useVehiclesSearch = (
  input: string,
  params?: IVehicleSearch,
  debounce = 200
) => {
  const debounced = useDebounce(input, debounce)

  return useQuery({
    queryKey: ['vehiclesSearch', debounced[0], params],
    queryFn: async () => await vehiclesService.search(input, params),
    select: response => response.data
  })
}
