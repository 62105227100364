import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { createReportSchema, ICreateReport } from '@/types/reports.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TbUser } from 'react-icons/tb'
import { useCreateDriversReport } from './hooks/useCreateDriversReport'

const DriversReportDialog: FC = () => {
  const { mutate: createReport } = useCreateDriversReport()

  const [open, setOpen] = useState<boolean>(false)

  const form = useForm<ICreateReport>({
    resolver: yupResolver(createReportSchema),
    defaultValues: {
      name: 'Drivers Report'
    }
  })

  const handleSubmit = (data: ICreateReport) => {
    console.log(data)
    createReport(data)
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant='ghost'>
          <TbUser className='min-h-4 min-w-4 mr-2' />
          Drivers Report
        </Button>
      </DialogTrigger>
      <Form {...form}>
        <DialogContent>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className='flex flex-col gap-4'
          >
            <DialogHeader>
              <DialogTitle>Generate Drivers Report</DialogTitle>
              <DialogDescription>
                This action will generate a new Drivers Report. Processing may
                take some time, and you can monitor the status in the Reports
                table. Once processing is complete, the report will be available
                for download
              </DialogDescription>
            </DialogHeader>
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name *</FormLabel>
                  <FormControl>
                    <Input {...field} className='w-full h-8' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <DialogClose asChild>
                <Button variant='outline'>Close</Button>
              </DialogClose>
              <Button type='submit'>Create</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Form>
    </Dialog>
  )
}
export default DriversReportDialog

