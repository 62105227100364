import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { useDeleteDriver } from '@/hooks/mutations/useDeleteDriver'
import { useAppSelector } from '@/hooks/useAppSelector'
import { IDriver } from '@/types/drivers.type'
import { FC, ReactNode } from 'react'

interface IDriverDeleteDialogProps {
  driver: IDriver
  children: ReactNode
}

const DriverDeleteDialog: FC<IDriverDeleteDialogProps> = (
  props: IDriverDeleteDialogProps
) => {
  const { mutate: deleteDriver } = useDeleteDriver()
  const user = useAppSelector(selector => selector.userState.user)

  return (
    <AlertDialog>
      <AlertDialogTrigger onSelect={e => e.preventDefault()} asChild>
        {props.children}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete this
            driver.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => deleteDriver(props.driver)}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default DriverDeleteDialog
