import geoService from '@/services/geo.service'
import { useQuery } from '@tanstack/react-query'

export const useAddressGeocode = (address: string) => {
  return useQuery({
    queryKey: ['addressGeocode', address],
    enabled: !!address,
    queryFn: async () => await geoService.geocode(address),
    select: response => response.data
  })
}
