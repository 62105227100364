import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { FC } from 'react'
import { TbCopy } from 'react-icons/tb'

interface IDuplicateDialogProps {
  onAccept?: () => void
  disabled?: boolean
}

const DuplicateDialog: FC<IDuplicateDialogProps> = (
  props: IDuplicateDialogProps
) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          disabled={props.disabled}
          title='Duplicate'
          variant='outline'
          size='icon'
          className='h-8 w-8'
        >
          <TbCopy />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Duplicate</AlertDialogTitle>
          <AlertDialogDescription>
            This action will duplicate this preset. You can rename a copy later.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={props.onAccept}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default DuplicateDialog
