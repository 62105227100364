import { toast } from '@/components/ui/use-toast'
import { formatName } from '@/lib/utils'
import adminsService from '@/services/admins.service'
import { IAdmin } from '@/types/admins.type'
import { IAPIError } from '@/types/common.type'
import { useMutation } from '@tanstack/react-query'

export const useCreateAdmin = () => {
  return useMutation({
    mutationFn: async (data: IAdmin) => {
      toast({
        title: 'Creating...',
        description: 'Creating new admin.'
      })

      return await adminsService.create(data)
    },
    onSuccess: ({ data }) => {
      toast({
        title: 'Invited!',
        description: `${formatName(data.user)} (${data.user.email}) was invited to your company. Invitation link was sent to admin's email.`
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
