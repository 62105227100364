import TripsTable from '@/components/tables/TripsTable/TripsTable'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import Spinner from '@/components/ui/Spinner'
import { useTrips } from '@/hooks/queries/useTrips'
import { subDays } from 'date-fns'
import { FC, useMemo } from 'react'
import { TbCalendar } from 'react-icons/tb'

interface IDemandRidesProps {
  riderId: number
}

const DemandRides: FC<IDemandRidesProps> = (props: IDemandRidesProps) => {
  const today = useMemo(() => new Date(), [])
  const trips = useTrips(subDays(today, 30), today)

  return trips.data ? (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          type='button'
          variant='outline'
          className='w-64 h-8 border-dashed'
        >
          <TbCalendar className='mr-2' />
          Demand Rides
        </Button>
      </DialogTrigger>
      <DialogContent className='min-w-[95%] min-h-[90%]'>
        <TripsTable
          className='h-[95%] self-end'
          initialState={{
            pagination: {
              pageSize: 16
            }
          }}
          data={trips.data.filter(trip =>
            trip.schedules.some(schedule => schedule.riderId === props.riderId)
          )}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Spinner />
  )
}
export default DemandRides
