import {
  IAuth,
  IPasswordRecoveryRequest,
  ISignupRequest
} from '@/types/auth.type'
import { api } from './axios.interceptor'

class AuthService {
  async refresh(refreshToken: string) {
    const response = await api.get<IAuth>(`/auth/refresh`, {
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    })

    return response
  }

  async passwordRecoveryRequest(email: string) {
    const response = await api.post<IAuth>(
      `/auth/password-recovery/send/${email}`
    )

    return response
  }

  async signup(request: ISignupRequest) {
    const response = await api.post<IAuth>(`/auth/signup`, request)

    return response
  }

  async passwordRecoveryApply(request: IPasswordRecoveryRequest) {
    const response = await api.patch<IAuth>(
      `/auth/password-recovery/recover`,
      request
    )

    return response
  }

  async login(username: string, password: string) {
    const response = await api.post<IAuth>('/auth/login', {
      username: username,
      password: password
    })

    return response
  }
}

const authService = new AuthService()

export default authService
