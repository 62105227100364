import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { Progress } from '@/components/ui/progress'
import { Skeleton } from '@/components/ui/skeleton'
import { ITrip, ScheduleStatus } from '@/types/trips.type'
import { UseQueryResult } from '@tanstack/react-query'
import { FC, HTMLAttributes, useMemo } from 'react'

interface ICompletedTodayProps extends HTMLAttributes<HTMLDivElement> {
  trips: UseQueryResult<ITrip[]>
}

const countTripsByStatuses = (trips: ITrip[], statuses: ScheduleStatus[]) =>
  trips.filter(trip =>
    trip.schedules.some(schedule => statuses.includes(schedule.status))
  ).length || 0

const CompletedToday: FC<ICompletedTodayProps> = (
  props: ICompletedTodayProps
) => {
  const completedToday = useMemo(
    () =>
      props.trips.data
        ? countTripsByStatuses(props.trips.data, [
            ScheduleStatus.Completed,
            ScheduleStatus.NoShow,
            ScheduleStatus.Canceled
          ])
        : 0,
    [props.trips.data]
  )

  const completionPercentage = useMemo(() => {
    if (!props.trips.data) return 0
    if (!props.trips.data.length) return 100
    return (completedToday / props.trips.data.length) * 100
  }, [completedToday, props.trips.data])

  return (
    <Card {...props}>
      <CardHeader>
        <CardTitle>Completed Today</CardTitle>
        <CardDescription>
          {props.trips.data ? (
            props.trips.data.length ? (
              <span>
                There are {completedToday} of {props.trips.data?.length}{' '}
                completed trips today.
              </span>
            ) : (
              <span>There are no trips today.</span>
            )
          ) : (
            <>
              <Skeleton className='w-full h-6 mt-4' />
              <Skeleton className='w-full rounded-full h-4 mt-4' />
            </>
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        {props.trips.data && <Progress value={completionPercentage} />}
      </CardContent>
    </Card>
  )
}
export default CompletedToday
