import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { emailToMailService } from '@/lib/utils'
import authService from '@/services/auth.service'
import { IAPIError } from '@/types/common.type'
import { useMutation } from '@tanstack/react-query'
import { TbMail } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

export const useRequestPasswordRecovery = (redirect?: boolean) => {
  const { toast } = useToast()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (email: string) => {
      await authService.passwordRecoveryRequest(email)

      return email
    },
    onSuccess: (email: string) => {
      const mailService = emailToMailService(email)

      if (redirect) navigate('/login')

      toast({
        title: 'Success!',
        description: (
          <div className='flex flex-col gap-2'>
            <p>An email has been sent to {email}.</p>
            {mailService && (
              <a href={mailService.url} target='_blank'>
                <Button className='w-40 h-8' variant='outline' type='button'>
                  <TbMail className='h-4 w-4 mr-1' />
                  Open {mailService.name}
                </Button>
              </a>
            )}
          </div>
        ),
        duration: 3000
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive',
        duration: 5000
      })
    }
  })
}
