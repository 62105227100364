import { cn, formatScheduleStatus } from '@/lib/utils'
import { ScheduleStatus } from '@/types/trips.type'
import { FC } from 'react'
import { Badge } from '../../ui/badge'

interface IScheduleStatusBadgeProps {
  value: ScheduleStatus
  className?: string
}

const statusStyles: Record<ScheduleStatus, string> = {
  Unassigned: 'bg-neutral-500 dark:text-primary',
  Assigned: 'bg-teal-600 dark:text-primary',
  Completed: 'bg-green-600 dark:text-primary',
  Canceled: 'bg-red-500 dark:text-primary',
  InRoute: 'bg-orange-500 dark:text-primary',
  InTransit: 'bg-yellow-500 dark:text-primary',
  ArrivedAtPU: 'bg-blue-500 dark:text-primary',
  ArrivedAtDO: 'bg-violet-500 dark:text-primary',
  NoShow: 'bg-zinc-800 dark:text-primary'
}

const ScheduleStatusBadge: FC<IScheduleStatusBadgeProps> = (
  props: IScheduleStatusBadgeProps
) => {
  return (
    <Badge
      className={cn(
        `${statusStyles[props.value]} w-max text-xs hover:bg-primary dark:hover:text-primary-foreground text-center justify-center place-items-center whitespace-nowrap`,
        props.className
      )}
    >
      {formatScheduleStatus(props.value)}
    </Badge>
  )
}

export default ScheduleStatusBadge
