import driverService from '@/services/drivers.service'
import { useQuery } from '@tanstack/react-query'

export const useLatestGeolocation = () => {
  return useQuery({
    queryKey: ['latestGeolocation'],
    queryFn: async () => await driverService.getLatestGeolocations(),
    refetchInterval: 1000 * 5,
    select: response => response.data
  })
}

