import { useToast } from '@/components/ui/use-toast'
import authService from '@/services/auth.service'
import { ISignupRequest } from '@/types/auth.type'
import { IAPIError } from '@/types/common.type'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useSignup = () => {
  const { toast } = useToast()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (data: ISignupRequest) => {
      return await authService.signup(data)
    },
    onSuccess: () => {
      navigate('/login')

      toast({
        title: 'Success!',
        description:
          'You have signed up succesfully! Now you can log in with your new password.'
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive',
        duration: 5000
      })
    }
  })
}
