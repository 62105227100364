import { darkThemeStyles, lightThemeStyles } from '@/default-map-style'
import { useTheme } from '@/hooks/useTheme'
import { cn, formatName } from '@/lib/utils'
import { Theme } from '@/reducers/settingsSlice'
import { AdvancedMarker, Map } from '@vis.gl/react-google-maps'
import { FC, useContext, useMemo } from 'react'
import LiveMapContext from './LiveMapContext'
import MapDriverIcon from './MapDriverIcon'

interface ILiveMapProps {
  className?: string
}

const allowedMoveDistance = 1

const LiveMap: FC<ILiveMapProps> = (props: ILiveMapProps) => {
  const context = useContext(LiveMapContext)

  const theme = useTheme()
  const allLocations = useMemo(
    () =>
      context.latestGeolocations
        ? context.latestGeolocations.map(mapElement => mapElement.location)
        : [],
    [context.latestGeolocations]
  )

  const minLatitude = useMemo(
    () => Math.min(...allLocations.map(location => location.latitude)),
    [allLocations]
  )

  const maxLatitude = useMemo(
    () => Math.max(...allLocations.map(location => location.latitude)),
    [allLocations]
  )

  const minLongitude = useMemo(
    () => Math.min(...allLocations.map(location => location.longitude)),
    [allLocations]
  )

  const maxLongitude = useMemo(
    () => Math.max(...allLocations.map(location => location.longitude)),
    [allLocations]
  )

  const center = useMemo(
    () => ({
      lat: (minLatitude + maxLatitude) / 2,
      lng: (minLongitude + maxLongitude) / 2
    }),
    [minLatitude, maxLatitude, minLongitude, maxLongitude]
  )

  return (
    <Map
      className={cn('w-full h-full [&>*]:rounded-md', props.className)}
      style={{
        clipPath:
          'polygon(100% 0%, 100% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)'
      }}
      mapId='dea1fd60813b4e75'
      defaultZoom={4}
      defaultCenter={center}
      restriction={{
        latLngBounds: {
          north: maxLatitude + allowedMoveDistance,
          south: minLatitude - allowedMoveDistance,
          west: minLongitude - allowedMoveDistance,
          east: maxLongitude + allowedMoveDistance
        },
        strictBounds: true
      }}
      styles={theme === Theme.Light ? lightThemeStyles : darkThemeStyles}
      mapTypeId='roadmap'
      gestureHandling='greedy'
      disableDefaultUI={true}
      keyboardShortcuts={true}
    >
      {context.latestGeolocations.map(
        mapElement =>
          !context.hiddenDrivers.includes(mapElement.driver.id) && (
            <AdvancedMarker
              key={mapElement.driver.id}
              position={{
                lat: mapElement.location.latitude,
                lng: mapElement.location.longitude
              }}
            >
              <div className='flex flex-col justify-center place-items-center w-20'>
                <MapDriverIcon driver={mapElement.driver} />
                <span className='text-black text-center text-sm'>
                  {formatName(mapElement.driver.user)}
                </span>
              </div>
            </AdvancedMarker>
          )
      )}
    </Map>
  )
}
export default LiveMap

