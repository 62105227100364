import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { useAddressSearch } from '@/hooks/queries/useAddressAutocomplete'
import { useAddressGeocode } from '@/hooks/queries/useAddressGeocode'
import { AddressFormat, cn, formatAddress } from '@/lib/utils'
import { IAddress } from '@/types/address.type'
import { AutocompleteType } from '@/types/geo.type'
import { FC, useEffect, useState } from 'react'
import { TbSearch } from 'react-icons/tb'
import Spinner from '../Spinner'

interface ILocationInputProps {
  title?: string
  value?: IAddress
  required?: boolean
  id?: string
  fieldClassName?: string
  popoverClassName?: string
  type?: AutocompleteType
  format?: AddressFormat
  onChange?: (value: IAddress) => any
}

const LocationInput: FC<ILocationInputProps> = (props: ILocationInputProps) => {
  const [open, setOpen] = useState<boolean>()
  const [input, setInput] = useState<string>('')

  const [selectedAddress, setSelectedAddress] = useState<string>('')

  const autocomplete = useAddressSearch(
    input,
    props.type || AutocompleteType.ALL
  )

  const geocode = useAddressGeocode(selectedAddress)

  useEffect(() => {
    if (geocode.data) props.onChange?.(geocode.data)
  }, [geocode.data])

  return (
    <div className='flex flex-col gap-2'>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            className={cn('h-10 w-64 justify-between', props.fieldClassName)}
            aria-expanded={open}
          >
            <span className='overflow-hidden whitespace-nowrap text-ellipsis'>
              {props.value
                ? formatAddress(props.value, props.format)
                : 'Search for address...'}
            </span>
            <TbSearch className='ml-2 min-w-4 min-h-4' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command shouldFilter={false}>
            <CommandInput
              placeholder='Search for address...'
              id={props.id}
              onValueChange={value => setInput(value)}
            />
            <CommandList>
              <CommandGroup>
                {input && !autocomplete.isLoading && (
                  <CommandEmpty>No results found.</CommandEmpty>
                )}
                {autocomplete.isLoading && (
                  <div className='flex justify-center my-2'>
                    <Spinner />
                  </div>
                )}
                {autocomplete.data?.map(address => {
                  return (
                    <CommandItem
                      key={address}
                      value={JSON.stringify(address)}
                      onSelect={() => {
                        setSelectedAddress(address)
                        setOpen(false)
                      }}
                    >
                      {address}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default LocationInput
