import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { cn } from '@/lib/utils'
import { FC, InputHTMLAttributes } from 'react'

interface IFileInputProps extends InputHTMLAttributes<HTMLInputElement> {}

const FileInput: FC<IFileInputProps> = (props: IFileInputProps) => {
  const { children, ...inputProps } = props

  return (
    <div className={cn('flex flex-col gap-2', props.className)}>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Input
        {...inputProps}
        type='file'
        className='file:text-primary file:rounded-sm'
      />
      {props.children}
    </div>
  )
}

export default FileInput
