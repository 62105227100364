import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { useAvatar } from '@/hooks/queries/useAvatar'
import { IDriver } from '@/types/drivers.type'
import { CSSProperties, FC } from 'react'
import { TbUser } from 'react-icons/tb'

const generateColor = (seed: number): string => {
  const randomHue = (seed * 137) % 360
  const saturation = 40 + (seed % 20)
  const lightness = 30 + (seed % 20)

  const hslToRgb = (h: number, s: number, l: number) => {
    s /= 100
    l /= 100
    const k = (n: number) => (n + h / 30) % 12
    const a = s * Math.min(l, 1 - l)
    const f = (n: number) =>
      l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)))

    return [
      Math.round(255 * f(0)),
      Math.round(255 * f(8)),
      Math.round(255 * f(4))
    ]
  }

  const [r, g, b] = hslToRgb(randomHue, saturation, lightness)
  const rgbToHex = (r: number, g: number, b: number) =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`

  return rgbToHex(r, g, b)
}

interface IMapDriverIconProps {
  driver: IDriver
}

const MapDriverIcon: FC<IMapDriverIconProps> = (props: IMapDriverIconProps) => {
  const avatar = useAvatar(props.driver.userId)

  return (
    <Avatar
      className='h-10 w-10 border border-white shadow-lg'
      style={
        {
          '--bg-color': generateColor(props.driver.id)
        } as CSSProperties
      }
    >
      <AvatarImage src={avatar.data} />
      <AvatarFallback className='bg-[--bg-color]'>
        <TbUser className='h-6 w-6' />
      </AvatarFallback>
    </Avatar>
  )
}
export default MapDriverIcon

