import { cn } from '@/lib/utils'
import * as React from 'react'
import { FC } from 'react'
import { TimePickerInput } from './time-picker-input'

interface ITimePickerProps {
  value?: Date
  className?: string
  onChange: (value?: Date) => void
}

export const TimePicker: FC<ITimePickerProps> = (props: ITimePickerProps) => {
  const minuteRef = React.useRef<HTMLInputElement>(null)
  const hourRef = React.useRef<HTMLInputElement>(null)

  return (
    <div className={cn('flex place-items-center gap-2', props.className)}>
      <TimePickerInput
        autoComplete='off'
        picker='hours'
        date={props.value}
        setDate={props.onChange}
        ref={hourRef}
        onRightFocus={() => minuteRef.current?.focus()}
      />
      :
      <TimePickerInput
        autoComplete='off'
        picker='minutes'
        date={props.value}
        setDate={props.onChange}
        ref={minuteRef}
        onLeftFocus={() => hourRef.current?.focus()}
      />
    </div>
  )
}
