import { yupTransformer } from '@/lib/yup'
import { addressSchema, IAddress } from '@/types/address.type'
import { Mobility } from '@/types/common.type'
import { IRider } from '@/types/riders.type'
import {
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  ObjectSchema,
  string
} from 'yup'
import { IBroker } from './brokers.type'
import { IShift } from './shifts.type'

export enum ScheduleStatus {
  Unassigned = 'Unassigned',
  Assigned = 'Assigned',
  Completed = 'Completed',
  Canceled = 'Canceled',
  InTransit = 'InTransit',
  InRoute = 'InRoute',
  NoShow = 'NoShow',
  ArrivedAtPU = 'ArrivedAtPU',
  ArrivedAtDO = 'ArrivedAtDO'
}

export enum SettableScheduleStatus {
  Completed = 'Completed',
  Canceled = 'Canceled',
  InTransit = 'InTransit',
  InRoute = 'InRoute',
  NoShow = 'NoShow',
  ArrivedAtPU = 'ArrivedAtPU',
  ArrivedAtDO = 'ArrivedAtDO'
}

export interface ISchedule {
  id: number
  status: ScheduleStatus
  from: IAddress
  pickupTime: Date
  pickupArrivalTime?: Date
  scheduledPickupTime?: Date
  to: IAddress
  dropoffTime: Date
  dropoffArrivalTime?: Date
  scheduledDropoffTime?: Date
  guests?: Mobility[]
  mobility: Mobility
  dispatcherNotes?: string
  isRiderSignatureUploaded?: boolean
  isDriverSignatureUploaded?: boolean
  notes?: string
  willCall: boolean
  tripId?: number
  riderId: number
  rider: IRider
  brokerId: number
  broker: IBroker
}

export interface ITrip {
  id?: number
  shiftId?: number
  shift?: IShift
  schedules: ISchedule[]
}

export interface ISchemaSchedule
  extends Omit<
    ISchedule,
    | 'id'
    | 'isRiderSignatureUploaded'
    | 'isDriverSignatureUploaded'
    | 'trip'
    | 'broker'
    | 'rider'
    | 'driver'
  > {}

export const scheduleSchema: ObjectSchema<ISchemaSchedule> = object({
  status: mixed<ScheduleStatus>().required('Status is required.'),
  from: addressSchema.required('From is required.'),
  pickupTime: date().required('Pickup Time is required.'),
  pickupArrivalTime: date().optional(),
  scheduledPickupTime: date().optional(),
  to: addressSchema.required('To is required.'),
  dropoffTime: date().required('Dropoff Time is required.'),
  dropoffArrivalTime: date().optional(),
  scheduledDropoffTime: date().optional(),
  guests: array()
    .of(mixed<Mobility>().required('Guest Mobility is required.'))
    .optional(),
  mobility: mixed<Mobility>().required('Mobility is required.'),
  dispatcherNotes: string().optional(),
  notes: string().optional(),
  willCall: boolean().required('Will Call is required.'),
  tripId: number().optional(),
  riderId: number().required('Rider is required.'),
  brokerId: number().required('Broker is required.')
}).transform(yupTransformer)

export interface ISchemaTrip
  extends Omit<ITrip, 'id' | 'shift' | 'shiftId' | 'schedules'> {
  schedules: ISchemaSchedule[]
}

export const tripSchema: ObjectSchema<ISchemaTrip> = object({
  schedules: array()
    .of(scheduleSchema.default(undefined).required('Schedule is required.'))
    .min(1, 'At least one Schedule is required.')
    .required('Schedules are required.')
})
