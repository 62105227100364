import { nameRegExp } from '@/lib/utils'
import { phoneTest, yupTransformer } from '@/lib/yup'
import { Gender } from '@/types/common.type'
import { date, mixed, number, object, ObjectSchema, string } from 'yup'

export interface IRider {
  id: number
  email?: string
  firstName: string
  middleName?: string
  lastName: string
  weight?: number
  dateOfBirth?: Date
  gender?: Gender
  language?: string
  phone?: string
  medicaidNumber?: string
  socialSecurityNumber?: string
  notes?: string
}

export interface ISchemaRider extends Omit<IRider, 'id'> {}

export const riderSchema: ObjectSchema<ISchemaRider> = object({
  email: string().email('Incorrect email.').optional(),
  firstName: string()
    .matches(nameRegExp, 'Incorrect name.')
    .required('First Name is required.'),
  middleName: string()
    .matches(nameRegExp, {
      excludeEmptyString: true,
      message: 'Incorrect name.'
    })
    .optional(),
  lastName: string()
    .matches(nameRegExp, 'Incorrect name.')
    .required('Last Name is required.'),
  weight: number()
    .min(0, 'Minimum weight - 0.')
    .max(1000, 'Maximum weight - 1000.')
    .optional(),
  dateOfBirth: date().max(new Date(), 'Must be earlier than today.').optional(),
  gender: mixed<Gender>().optional(),
  language: string().optional(),
  phone: string().test(phoneTest()).optional(),
  medicaidNumber: string().optional(),
  socialSecurityNumber: string()
    .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN format: AAA-GG-SSSS')
    .optional(),
  notes: string().optional()
}).transform(yupTransformer)
