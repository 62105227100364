import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import Link from '@/components/ui/Link'
import { Separator } from '@/components/ui/separator'
import {
  AutoschedulerOptimization,
  companySettingsSchema,
  ICompany,
  ICompanySettings
} from '@/types/company.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  TbElevator,
  TbEmergencyBed,
  TbPencil,
  TbWalk,
  TbWheelchair
} from 'react-icons/tb'
import { useUpdateCompanySettings } from '../../hooks/useUpdateCompanySettings'
import AutoschedulerOptimizationField from './AutoschedulerOptimizationField'

export interface IAutoschedulerTabProps {
  company: ICompany
}

const AutoschedulerTab: FC<IAutoschedulerTabProps> = (
  props: IAutoschedulerTabProps
) => {
  const form = useForm<ICompanySettings>({
    resolver: yupResolver(
      companySettingsSchema.omit([
        'importPickupAdjustment',
        'importDropoffAdjustment'
      ])
    ),
    defaultValues: {
      autoschedulerOptimizations: []
    }
  })

  const enabledOptimizations = useMemo(
    () => form.getValues('autoschedulerOptimizations') || [],
    [form.watch('autoschedulerOptimizations')]
  )

  const disabledOptimizations = useMemo(
    () =>
      Object.values(AutoschedulerOptimization).filter(
        optimization =>
          !form.getValues('autoschedulerOptimizations')?.includes(optimization)
      ) || [],
    [form.watch('autoschedulerOptimizations')]
  )

  const { mutate: submit } = useUpdateCompanySettings()

  useEffect(() => form.reset(props.company.settings), [props.company])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(data => submit(data))}>
        <Card>
          <CardHeader>
            <CardTitle>Autoscheduler</CardTitle>
            <CardDescription>
              Choose best autoscheduler for your needs.
            </CardDescription>
            <Separator />
          </CardHeader>
          <CardContent className='flex flex-row gap-8'>
            <Card className='w-1/2'>
              <CardHeader>
                <CardTitle>Times</CardTitle>
                <CardDescription>
                  Global time parameters for autoscheduling system.
                  <br />
                  These times can be adjusted for each{' '}
                  <Link to='/brokers' className='underline-offset-2 underline'>
                    broker
                  </Link>{' '}
                  in it's page.
                  <br />
                  <br />
                  Each field is represented in minutes.
                </CardDescription>
              </CardHeader>
              <CardContent className='flex flex-col gap-2 max-w-[500px]'>
                <div className='flex flex-col sm:flex-row gap-4 w-full'>
                  <FormField
                    control={form.control}
                    name='pickupEarly'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Pickup Early *</FormLabel>
                        <FormControl>
                          <Input className='min-min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='pickupLate'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Pickup Late *</FormLabel>
                        <FormControl>
                          <Input className='min-min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className='flex flex-col sm:flex-row gap-4 w-full'>
                  <FormField
                    control={form.control}
                    name='apptEarly'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Appointment Early *</FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='apptLate'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Appointment Late *</FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className='flex flex-col sm:flex-row gap-4 w-full sm:place-items-center'>
                  <TbWalk className='h-6 w-6 hidden sm:block' />
                  <FormField
                    control={form.control}
                    name='ambulatoryPickupServiceTime'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Ambulatory Pickup Service Time *</FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='ambulatoryApptServiceTime'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Ambulatory Appointment Service Time *
                        </FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className='flex flex-col sm:flex-row gap-4 w-full sm:place-items-center'>
                  <TbWheelchair className='h-6 w-6 hidden sm:block' />
                  <FormField
                    control={form.control}
                    name='wheelchairPickupServiceTime'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Wheelchair Pickup Service Time *</FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='wheelchairApptServiceTime'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Wheelchair Appointment Service Time *
                        </FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className='flex flex-col sm:flex-row gap-4 w-full sm:place-items-center'>
                  <TbElevator className='h-6 w-6 hidden sm:block' />
                  <FormField
                    control={form.control}
                    name='liftPickupServiceTime'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Lift Pickup Service Time *</FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='liftApptServiceTime'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Lift Appointment Service Time *</FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className='flex flex-col sm:flex-row gap-4 w-full sm:place-items-center'>
                  <TbEmergencyBed className='h-6 w-6 hidden sm:block' />
                  <FormField
                    control={form.control}
                    name='stretcherPickupServiceTime'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Stretcher Pickup Service Time *</FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='stretcherApptServiceTime'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Stretcher Appointment Service Time *
                        </FormLabel>
                        <FormControl>
                          <Input className='min-w-full h-8' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
            <Card className='w-1/2'>
              <CardHeader>
                <CardTitle>Optimizaitons Order</CardTitle>
                <CardDescription>
                  Order for autoscheduler optimizations. These changes may
                  affect the result of the autoscheduler.
                </CardDescription>
              </CardHeader>
              <CardContent className='flex flex-col justify-between gap-4'>
                <div className='flex flex-col border gap-4 p-4 rounded-md'>
                  <h2>Enabled</h2>
                  {enabledOptimizations.length ? (
                    enabledOptimizations.map(optimizaiton => (
                      <AutoschedulerOptimizationField
                        form={form}
                        optimization={optimizaiton}
                      />
                    ))
                  ) : (
                    <span className='text-muted-foreground text-sm'>
                      All optimizations are disabled.
                    </span>
                  )}
                </div>
                <div className='flex flex-col gap-4 border rounded-md p-4'>
                  <h2>Disabled</h2>
                  {disabledOptimizations.length ? (
                    disabledOptimizations.map(optimization => (
                      <AutoschedulerOptimizationField
                        disabled
                        form={form}
                        optimization={optimization}
                      />
                    ))
                  ) : (
                    <span className='text-muted-foreground text-sm'>
                      All optimizations are enabled.
                    </span>
                  )}
                </div>
              </CardContent>
            </Card>
          </CardContent>
          <CardFooter>
            <Button className='w-64 h-8' type='submit'>
              <TbPencil className='mr-1' />
              Save changes
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}
export default AutoschedulerTab
