import { useToast } from '@/components/ui/use-toast'
import tripsService from '@/services/trips.service'
import { IAPIError } from '@/types/common.type'
import { useMutation } from '@tanstack/react-query'

export const useAutoscheduleMutation = () => {
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (date: Date) => {
      await tripsService.autoschedule(date)

      return date
    },
    onSuccess: async () => {
      /* Reload page in 1 second. */

      return new Promise(resolve => setTimeout(resolve, 1000)).then(() =>
        window.location.reload()
      )
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive',
        duration: 5000
      })
    }
  })
}
