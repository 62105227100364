import shiftsService from '@/services/shifts.service'
import { ITimeRange } from '@/types/common.type'
import { useQuery } from '@tanstack/react-query'

export const useShifts = (timeFilter: ITimeRange) =>
  useQuery({
    queryKey: ['shifts', timeFilter],
    queryFn: async () => await shiftsService.getByTime(timeFilter),
    select: response => response.data
  })
