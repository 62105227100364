import { Button } from '@/components/ui/button'
import {
  AutoschedulerOptimization,
  ICompanySettings
} from '@/types/company.type'
import { FC, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { TbArrowDown, TbArrowUp, TbMinus, TbPlus } from 'react-icons/tb'

interface IAutoschedulerOptimizationFieldProps {
  form: UseFormReturn<ICompanySettings, any, undefined>
  disabled?: boolean
  optimization: AutoschedulerOptimization
}

const optimizationToTitle: Record<AutoschedulerOptimization, string> = {
  [AutoschedulerOptimization.Distance]: 'Distance',
  [AutoschedulerOptimization.Duration]: 'Duration',
  [AutoschedulerOptimization.VehicleCount]: 'Vehicle Count'
}

const optimizationToDescription: Record<AutoschedulerOptimization, string> = {
  [AutoschedulerOptimization.Distance]:
    'Minimizes the total traveled distance.',
  [AutoschedulerOptimization.Duration]:
    'Minimizes total duration calculated as the sum of all tour durations',
  [AutoschedulerOptimization.VehicleCount]: 'Optimizes the number of tours.'
}

const AutoschedulerOptimizationField: FC<
  IAutoschedulerOptimizationFieldProps
> = (props: IAutoschedulerOptimizationFieldProps) => {
  const enabledOptimizations = useMemo(
    () => props.form.getValues('autoschedulerOptimizations') || [],
    [props.form.watch('autoschedulerOptimizations')]
  )

  const handleSwitchButtonClick = () => {
    if (enabledOptimizations.includes(props.optimization))
      props.form.setValue(
        'autoschedulerOptimizations',
        enabledOptimizations.filter(
          optimization => optimization !== props.optimization
        )
      )
    else
      props.form.setValue('autoschedulerOptimizations', [
        ...enabledOptimizations,
        props.optimization
      ])
  }

  const handleMoveButtonClick = (direction: 'up' | 'down') => {
    const currentIndex = enabledOptimizations.indexOf(props.optimization)
    const nextIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1

    if (nextIndex < 0 || nextIndex >= enabledOptimizations.length) return

    const nextBuffered = enabledOptimizations[nextIndex]

    enabledOptimizations[nextIndex] = props.optimization
    enabledOptimizations[currentIndex] = nextBuffered

    props.form.setValue('autoschedulerOptimizations', enabledOptimizations)
  }

  return (
    <div className='flex flex-row gap-4 justify-between place-items-center'>
      <div className='flex flex-col'>
        <span className='text-md font-semibold'>
          {optimizationToTitle[props.optimization]}
        </span>
        <span className='text-sm text-muted-foreground'>
          {optimizationToDescription[props.optimization]}
        </span>
      </div>
      <div className='flex flex-row gap-2 place-items-center'>
        {!props.disabled && (
          <>
            <Button
              type='button'
              title='Move optimization up in the list.'
              className='h-8 w-8'
              variant='outline'
              size='icon'
              onClick={() => handleMoveButtonClick('up')}
              disabled={enabledOptimizations.length === 1}
            >
              <TbArrowUp />
            </Button>
            <Button
              type='button'
              title='Move optimization down in the list.'
              className='h-8 w-8'
              variant='outline'
              size='icon'
              onClick={() => handleMoveButtonClick('down')}
              disabled={enabledOptimizations.length === 1}
            >
              <TbArrowDown />
            </Button>
          </>
        )}
        <Button
          className='h-8 w-8'
          type='button'
          variant='outline'
          size='icon'
          title={
            props.disabled
              ? 'Enable this optimization.'
              : 'Disable this optimization.'
          }
          onClick={handleSwitchButtonClick}
        >
          {props.disabled ? <TbPlus /> : <TbMinus />}
        </Button>
      </div>
    </div>
  )
}
export default AutoschedulerOptimizationField
