import { mixed, number, object, ObjectSchema, string } from 'yup'
import { ITimeRange, timeRangeSchema } from './common.type'
import { ScheduleStatus } from './trips.type'

export type TripsStatsPeriod = 'day' | 'week'

export interface ITripAmount {
  date: Date
  amount: number
}

export interface ITripStat {
  date: Date | string
  total: number
  completed: number
  canceled: number
}

export enum ReportProcessStatus {
  Started = 'Started',
  Processing = 'Processing',
  Uploading = 'Uploading',
  Completed = 'Completed',
  Error = 'Error'
}

export interface IReport {
  id: number
  name: string
  createdAt: Date
  status: ReportProcessStatus
  companyId: number
}

export interface ICreateReport {
  name: string
}

export interface ICreateTripReport extends ICreateReport {
  brokerFilterId?: number
  riderFilterId?: number
  status?: ScheduleStatus
  range: ITimeRange
}

export const createReportSchema: ObjectSchema<ICreateReport> = object({
  name: string()
    .matches(
      /^[a-zA-Z0-9_().+\- ]+$/,
      'Name can contain only letters, spaces, numbers, _ + - and .'
    )
    .required('Name is required.')
})

export const createTripReportSchema: ObjectSchema<ICreateTripReport> =
  createReportSchema.shape({
    brokerFilterId: number(),
    riderFilterId: number(),
    status: mixed<ScheduleStatus>(),
    range: timeRangeSchema.required('Time range is required.')
  })
