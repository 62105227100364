import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent
} from '@/components/ui/chart'
import { Skeleton } from '@/components/ui/skeleton'
import { format, subWeeks } from 'date-fns'
import { FC, HTMLAttributes } from 'react'
import { Area, AreaChart, CartesianGrid, XAxis } from 'recharts'
import { useTripsStats } from '../hooks/useTripsStats'

export interface IWeekOverviewProps extends HTMLAttributes<HTMLDivElement> {}

const WeekOverview: FC<IWeekOverviewProps> = (props: IWeekOverviewProps) => {
  const statistics = useTripsStats('week')

  return (
    <Card {...props}>
      <CardHeader>
        <CardTitle>Week Overview</CardTitle>
        <CardDescription>
          Trips statistics for last 7 days (
          {format(subWeeks(new Date(), 1), 'MM.dd')} -{' '}
          {format(new Date(), 'MM.dd')}).
        </CardDescription>
      </CardHeader>
      <CardContent className='min-h-80 max-h-80'>
        {statistics.data ? (
          <ChartContainer
            className='w-full max-h-80'
            config={{
              total: {
                label: 'Total',
                theme: {
                  light: '#121212',
                  dark: '#ffffff'
                }
              },
              completed: {
                label: 'Completed',
                theme: {
                  light: '#22671d',
                  dark: '#73ff54'
                }
              },
              canceled: {
                label: 'Canceled',
                theme: {
                  light: '#7f0d0d',
                  dark: '#ff4545'
                }
              }
            }}
          >
            <AreaChart
              accessibilityLayer
              data={statistics.data}
              margin={{
                left: 12,
                right: 12
              }}
            >
              <defs>
                <linearGradient id='fillCompleted' x1='0' y1='0' x2='0' y2='1'>
                  <stop
                    offset='5%'
                    stopColor='var(--color-completed)'
                    stopOpacity={0.8}
                  />
                  <stop
                    offset='95%'
                    stopColor='var(--color-completed)'
                    stopOpacity={0.1}
                  />
                </linearGradient>
                <linearGradient id='fillCanceled' x1='0' y1='0' x2='0' y2='1'>
                  <stop
                    offset='5%'
                    stopColor='var(--color-canceled)'
                    stopOpacity={0.8}
                  />
                  <stop
                    offset='95%'
                    stopColor='var(--color-canceled)'
                    stopOpacity={0.1}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey='date'
                tickLine={false}
                axisLine={false}
                minTickGap={32}
                tickFormatter={value => format(value, 'MMMM dd')}
              />
              <ChartTooltip
                content={
                  <ChartTooltipContent
                    labelFormatter={value =>
                      isNaN(Date.parse(value))
                        ? 'Date'
                        : format(value, 'MMMM dd')
                    }
                  />
                }
              />
              <Area
                dataKey='canceled'
                type='linear'
                fill='url(#fillCanceled)'
                fillOpacity={0.4}
                stroke='var(--color-canceled)'
                stackId='a'
              />
              <Area
                dataKey='completed'
                type='linear'
                fill='url(#fillCompleted)'
                fillOpacity={0.4}
                stroke='var(--color-completed)'
                stackId='a'
              />
            </AreaChart>
          </ChartContainer>
        ) : (
          <div className='flex flex-col gap-4 w-full min-h-40'>
            <Skeleton className='w-full h-60' />
            <div className='w-full flex flex-row justify-between gap-4 h-6'>
              <Skeleton className='w-24' />
              <Skeleton className='w-24' />
              <Skeleton className='w-24' />
              <Skeleton className='w-24' />
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
export default WeekOverview
