import {
  TableCell as PrimitiveTableCell,
  TableRow as PrimitiveTableRow
} from '@/components/ui/table'
import { flexRender, Row } from '@tanstack/react-table'
import { FC, useCallback } from 'react'
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop
} from 'react-dnd'
import { ITableDND } from './Table'

interface ITableRowProps<T> {
  row: Row<T>
  dnd?: ITableDND<T>
  onContextMenu?: React.MouseEventHandler<HTMLTableRowElement>
  onSelectionChange?: (data: T | undefined, row?: Row<T>) => any
}

const TableRow: FC<ITableRowProps<any>> = <T,>(props: ITableRowProps<T>) => {
  const [, dropRef] = useDrop(
    () => ({
      accept: typeof props.dnd?.type,
      drop: (item: T) => {
        props.dnd?.onDrop?.(item, props.row.original)
        return item
      },
      collect: (monitor: DropTargetMonitor<unknown>) => ({
        isOver: monitor.isOver()
      })
    }),
    props.dnd?.deps
  )

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: typeof props.dnd?.type,
      item: props.row.getParentRow()
        ? props.row.getParentRow()?.original
        : props.row.original,
      options: {
        dropEffect: 'copy',

        /*
          We are doing this strange thing becuase of bug in react-dnd 
           https://github.com/react-dnd/react-dnd/issues/3345
        */
        ...Object.assign({}, props.dnd?.deps)
      },
      collect: (monitor: DragSourceMonitor<unknown, unknown>) => ({
        opacity: monitor.isDragging() ? 0.4 : 1
      })
    }),
    props.dnd?.deps
  )

  const dndRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      const { draggable, droppable } = props.dnd || {}

      if (draggable && droppable === 'row') return dragRef(dropRef(node))
      if (droppable === 'row') return dropRef(node)
      if (draggable) return dragRef(node)
    },
    [props.dnd]
  )

  return (
    <PrimitiveTableRow
      data-state={props.row.getIsSelected() && 'selected'}
      ref={dndRef}
      onContextMenu={props.onContextMenu}
      className={`${props.row.subRows.length === 1 && 'hidden'} border-t ${(props.row.subRows.length > 1 || props.row.getParentRow()!?.subRows.length > 1) && 'border-y-0 border-l-2 bg-green-50/50 dark:bg-green-50/5'}`}
      style={{ opacity: opacity }}
      key={`row-${props.row.id}`}
    >
      {props.row.getVisibleCells().map(cell => (
        <PrimitiveTableCell
          onClick={() => {
            props.row.toggleSelected(!props.row.getIsSelected())
            props.onSelectionChange?.(
              props.row.getIsSelected() ? undefined : props.row.original,
              props.row.getIsSelected() ? undefined : props.row
            )
          }}
          key={`cell-${cell.id}`}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </PrimitiveTableCell>
      ))}
    </PrimitiveTableRow>
  )
}
export default TableRow
