import { yupTransformer } from '@/lib/yup'
import { mixed, number, object, ObjectSchema, string } from 'yup'
import { addressSchema, IAddress } from './address.type'
import { Mobility } from './common.type'

export interface IVehicle {
  id: number
  name: string
  make: string
  model: string
  vehicleType: Mobility
  year: number
  ambulatoryCapacity: number
  mobilityCapacity: number
  vinNumber?: string
  garagingSite: IAddress
  licensePlateNumber?: string
}

export interface ISchemaVehicle extends Omit<IVehicle, 'id'> {}

export const vehicleSchema: ObjectSchema<ISchemaVehicle> = object({
  name: string().required('Name is required.'),
  make: string().required('Make is required.'),
  model: string().required('Model is required.'),
  vehicleType: mixed<Mobility>().required(),
  year: number()
    .min(1900, 'Minimal year is 1900.')
    .max(new Date().getFullYear(), 'Must be less than current year.')
    .required('Year is required.'),
  ambulatoryCapacity: number().min(1).required('Capacity is required.'),
  mobilityCapacity: number().required('Capacity is required.'),
  vinNumber: string()
    .matches(/^[A-HJ-NPR-Z0-9]{17}$/, 'VIN format: XXXYYYYYXYYYYYYYXX')
    .optional(),
  garagingSite: addressSchema.required('Garaging site is required.'),
  licensePlateNumber: string().optional()
}).transform(yupTransformer)
