import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel
} from '@/components/ui/context-menu'
import Spinner from '@/components/ui/Spinner'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { IReport, ReportProcessStatus } from '@/types/reports.type'
import { format } from 'date-fns'
import { FC } from 'react'
import { TbDownload, TbEye } from 'react-icons/tb'
import { useDownloadReport } from './hooks/useDownloadReport'
import ReportStatusBadge from './ReportStatusBadge'

export interface IReportsTableProps extends ICustomizableTableProps<IReport> {}

const ReportsTable: FC<IReportsTableProps> = (props: IReportsTableProps) => {
  const downloadReport = useDownloadReport()
  const previewReport = useDownloadReport(true)

  return (
    <Table
      {...props}
      initialState={{
        sorting: [
          {
            desc: true,
            id: 'createdAt'
          }
        ]
      }}
      contextMenu={(report: IReport) => (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem
            disabled={report.status !== ReportProcessStatus.Completed}
          >
            Download
          </ContextMenuItem>
        </ContextMenuContent>
      )}
      columns={[
        {
          id: 'status',
          size: 10,
          header: 'Status',
          accessorKey: 'status',
          cell: props => <ReportStatusBadge value={props.getValue()} />
        },
        {
          id: 'name',
          header: 'Name',
          filterFn: 'includesString',
          accessorFn: data => data.name,
          cell: props => {
            return (
              <div className='flex flex-row gap-2 place-items-center'>
                {props.row.original.status !== ReportProcessStatus.Completed &&
                  props.row.original.status !== ReportProcessStatus.Error && (
                    <Spinner />
                  )}
                <span>{props.getValue()}</span>
              </div>
            )
          }
        },
        {
          id: 'createdAt',
          header: 'Created At',
          accessorKey: 'createdAt',
          cell: props => format(props.getValue(), 'MM.dd.yyyy hh:mm a')
        },
        {
          id: 'download',
          size: 20,
          header: 'Actions',
          enableSorting: false,
          accessorFn: data => data,
          cell: props => (
            <div className='flex flex-row gap-2'>
              <Tooltip>
                <TooltipTrigger className='flex flex-row gap-2'>
                  <Button
                    variant='outline'
                    size='icon'
                    className='h-8 w-8'
                    disabled={
                      props.getValue().status !== ReportProcessStatus.Completed
                    }
                    onClick={() => previewReport.mutate(props.getValue().id)}
                  >
                    {previewReport.isPending &&
                    previewReport.variables === props.getValue().id ? (
                      <Spinner className='h-4 w-4' />
                    ) : (
                      <TbEye />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Preview</TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger className='flex flex-row gap-2'>
                  <Button
                    variant='outline'
                    size='icon'
                    className='h-8 w-8'
                    disabled={
                      props.getValue().status !== ReportProcessStatus.Completed
                    }
                    onClick={() => downloadReport.mutate(props.getValue().id)}
                  >
                    {downloadReport.isPending &&
                    downloadReport.variables === props.getValue().id ? (
                      <Spinner className='h-4 w-4' />
                    ) : (
                      <TbDownload />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Download</TooltipContent>
              </Tooltip>
            </div>
          )
        }
      ]}
    />
  )
}
export default ReportsTable

