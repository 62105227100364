import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { useDriversSearch } from '@/hooks/queries/useDriversSearch'
import { cn, formatName } from '@/lib/utils'
import { IDriver, IDriverSearch } from '@/types/drivers.type'
import { FC, useState } from 'react'
import { TbCheck, TbSearch } from 'react-icons/tb'
import DriverPreview from '../previews/DriverPreview'
import Spinner from '../Spinner'

interface IDriverSearchProps {
  defaultValue?: IDriver
  title?: string
  id?: string
  required?: boolean
  fieldClassName?: string
  popoverClassName?: string
  params?: IDriverSearch
  onChange?: (data: IDriver | undefined) => any
}

const DriverInput: FC<IDriverSearchProps> = (props: IDriverSearchProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')

  const [value, setValue] = useState<IDriver | undefined>(props.defaultValue)
  const search = useDriversSearch(input, props.params)

  const handleSelect = (driver: IDriver | undefined) => {
    props.onChange?.(driver)
    setValue(driver)
    setOpen(false)
  }

  return (
    <div className='flex flex-col gap-2'>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            className={cn('h-8 w-64 justify-between', props.fieldClassName)}
            aria-expanded={open}
          >
            <span className={'overflow-hidden whitespace-nowrap text-ellipsis'}>
              {value ? formatName(value.user) : 'Search for drivers...'}
            </span>
            <TbSearch />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command shouldFilter={false}>
            <CommandInput
              placeholder='Search for drivers...'
              value={input}
              onValueChange={setInput}
            />
            <CommandList>
              <CommandGroup>
                {search.isLoading ? (
                  <div className='flex justify-center my-2'>
                    <Spinner />
                  </div>
                ) : (
                  <CommandEmpty className='flex h-8 place-items-center justify-center'>
                    <span className='text-sm'>No results found.</span>
                  </CommandEmpty>
                )}
                <CommandItem onSelect={() => handleSelect(undefined)}>
                  <span className='ml-6'>Search for drivers...</span>
                </CommandItem>
                {search.data?.map(driver => (
                  <CommandItem
                    key={driver.id}
                    onSelect={() => handleSelect(driver)}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${value?.id === driver.id ? 'opacity-100' : 'opacity-0'}`}
                    />
                    <DriverPreview value={driver} />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default DriverInput
