import tripsService from '@/services/trips.service'
import { useQuery } from '@tanstack/react-query'

export const useTrip = (tripId: number) =>
  useQuery({
    enabled: !isNaN(tripId),
    queryKey: ['vehicle', tripId],
    queryFn: async () => await tripsService.getById(tripId),
    select: response => response.data,
    retry: 1
  })
